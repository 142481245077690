import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { getTransactionDetailsUrl } from '../../Transactions/_Details/urls/getTransactionDetailsUrl';
import { AssignDepositorForm } from '../ui/AssignDepositorForm/AssignDepositorForm';
import { EditWireDetails } from '../../OutboundWire/_Edit/ui/EditWireDetails/EditWireDetails';
import { InboundWireEditForm } from '../_Edit/InboundWireEditForm';
import { SelectDepositAccountForm } from '../ui/SelectDepositAccountForm/SelectDepositAccountForm';
import { getCurrencyOptions } from '@/helpers/formatHelpers';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { PageContent } from '@/components/Page/PageContent/PageContent';
import { PageColumn } from '@/components/Page/PageColumn/PageColumn';
import { WIRE_STATUS_DB } from '@/const/wire';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  changeWireStatus,
  resetPaymentDetails,
  loadInboundWire,
  loadPaymentDetails,
  updateWirePaymentDetails,
  loadDepositAccountPaymentDetails,
} from '../api';
import { loadCurrency } from '../../Transactions/_Details/api';
import { useInterval } from '@/hooks/useInterval';
import {
  isCreatedWireStatus,
  isDepositorInvitedWireStatus,
  isDepositorUnassignedWireStatus,
  isPendingWireStatus,
} from '@/helpers/wireHelpers';
import { PaymentDetails } from '../ui/PaymentDetails/PaymentDetails';
import { LoadPaymentDetailsLoading } from '../../OutboundWire/_Details/ui/LoadPaymentDetailsWidget/LoadPaymentDetailsLoading';
import { EditUserForm } from '@/bundle/shared/components/EditUserForm/EditUserForm';
import { USER_ROLE_MAP } from '@/const/user';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { Button } from '@/components/Button/Button';
import { NOT_ALLOWED_WIRE_STATUSES_CANCEL_INBOUND } from '@/bundle/_OrgAdmin/shared/const/const';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { OrgAdminWireDetails } from '@/bundle/_OrgAdmin/shared/ui/OrgAdminWireDetails/OrgAdminWireDetails';
import { UserDetailsWidget } from '@/bundle/shared/components/UserDetailsWidget/UserDetailsWidget';
import { DetailsEditButton } from '@/bundle/_OrgAdmin/shared/ui/DetailsEditButton/DetailsEditButton';

export const InboundWireDetailsPage = () => {
  const { transactionId, id } = useParams<{ transactionId: string; id: string }>();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isEditInboundWireWidget, setIsEditInboundWireWidget] = useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [isOpenSendModal, setIsOpenSendModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isEditDepositor, setIsEditDepositor] = useState(false);
  const [selectedAccountOption, setSelectedAccountOption] = useState<{ label: string; value: string }>();

  const refetchInboundWire = () => {
    queryClient.invalidateQueries({ queryKey: ['inbound_wire', id] });
  };

  const {
    data: inboundWireData,
    isPending: isPendingInboundWire,
    isFetching: isFetchingInboundWire,
  } = useQuery({
    queryKey: ['inbound_wire', id],
    queryFn: () => loadInboundWire(id),
  });

  const { data: selectedDepositAccountData, isFetching: isFetchingDepositAccountPaymentDetails } = useQuery({
    queryKey: ['load_deposit_account_payment_details', selectedAccountOption],
    queryFn: () => loadDepositAccountPaymentDetails(selectedAccountOption?.value),
    enabled: !!selectedAccountOption,
    placeholderData: keepPreviousData,
  });

  const { data: currency } = useQuery({
    queryKey: ['load_currency'],
    queryFn: () => loadCurrency(),
    enabled: !!isEditInboundWireWidget,
  });

  const { data: paymentDetailsData, isFetching: isFetchingPaymentDetails } = useQuery({
    queryKey: ['load_payment_details', id],
    queryFn: () => loadPaymentDetails(id),
    enabled: !!inboundWireData?.body?.is_payment_details_exist,
  });

  const {
    data: updatedPaymentDetails,
    mutate: mutateUpdatePaymentDetails,
    isPending: isPendingUpdatePaymentDetails,
  } = useMutation({
    mutationKey: ['update_payment_details'],
    mutationFn: (payload) => updateWirePaymentDetails(id, selectedAccountOption.value, payload),
    onSuccess: (updatePaymentDetails) => {
      if (updatePaymentDetails?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.SelectPaymentDetailsInboundWireSuccess);

      setSelectedAccountOption(null);
      queryClient.setQueriesData({ queryKey: ['load_deposit_account_payment_details'] }, null);
      refetchInboundWire();
    },
  });

  const { mutate: mutateCancelWire, isPending: isPendingCancelWire } = useMutation({
    mutationKey: ['cancel_wire'],
    mutationFn: () => {
      return changeWireStatus(id, WIRE_STATUS_DB.CANCELLED);
    },
    onSuccess: (updatedWire) => {
      if (updatedWire?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.CancelInboundWireSuccess);

      queryClient.setQueryData(['inbound_wire', id], updatedWire);
    },
    onSettled() {
      setIsOpenCancelModal(false);
    },
  });

  const { mutate: mutateResetPaymentDetails, isPending: isPendingResetPaymentDetails } = useMutation({
    mutationKey: ['reset_payment_details'],
    mutationFn: () => {
      return resetPaymentDetails(id);
    },
    onSuccess: (resettedPaymentDetails) => {
      if (resettedPaymentDetails?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.ResetPaymentDetailsInboundWireSuccess);

      refetchInboundWire();
      queryClient.setQueryData(['load_payment_details', id], null);
      setIsOpenDeleteModal(false);
    },
  });

  const inboundWire = inboundWireData?.body;
  const selectedDepositAccount = selectedDepositAccountData?.body;
  const paymentDetails = paymentDetailsData?.body;
  const currencyOptions = getCurrencyOptions(currency?.body?.results);
  const wireStatus = inboundWire?.status;

  const isCreatedWire = isCreatedWireStatus(wireStatus);
  const isDepositorUnassignedWire = isDepositorUnassignedWireStatus(wireStatus);
  const isPendingStatus = isPendingWireStatus(wireStatus);

  const shouldSelectDepositAccount = isCreatedWire && !isFetchingInboundWire && !isPendingUpdatePaymentDetails;
  const showPaymentDetailsLoader = isFetchingPaymentDetails && !!inboundWire?.assigned_depositor;
  const assignedDepositor = inboundWire?.assigned_depositor?.user;
  const isAllowedToCancelWire =
    !!wireStatus && !NOT_ALLOWED_WIRE_STATUSES_CANCEL_INBOUND.includes(wireStatus) && !isPendingInboundWire;
  const isDepositorInvited = isDepositorInvitedWireStatus(wireStatus);

  const backToWireList = () => navigate(getTransactionDetailsUrl(transactionId));

  const openEditMode = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.EditGeneralInfoInboundWireRedirect);

    setIsEditInboundWireWidget(true);
  };

  const closeEditMode = () => setIsEditInboundWireWidget(false);

  const updateInboundWireSuccess = () => {
    closeEditMode();
    setIsOpenCancelModal(false);
  };

  const assignedDepositorHandler = () => {
    refetchInboundWire();

    queryClient.invalidateQueries({ queryKey: ['load_payment_details', id] });
    setIsOpenSendModal(false);
    setIsEditDepositor(false);
  };

  const openDeletePaymentDetailsModal = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.ResetPaymentDetailsInboundWireRedirect);

    setIsOpenDeleteModal(true);
  };

  const openEditDepositorInfoWidget = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.EditDepositorInfoRedirect);

    setIsEditDepositor(true);
  };

  const { cancelInterval, isIntervalRunning } = useInterval(() => {
    if (isPendingStatus) {
      refetchInboundWire();
    }
  });

  const selectDepositAccount = (option: { label: string; value: string }) => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.PaymentDetailsSelectRedirect);

    setSelectedAccountOption(option);
  };

  const openCancelWireDialog = () => {
    setIsOpenCancelModal(true);

    amplitudeService.logEvent(AMPLITUDE_EVENTS.CancelInboundWireStartRedirect);
  };

  useEffect(() => {
    const shouldCancelInterval = inboundWire && !isPendingStatus && isIntervalRunning;

    if (shouldCancelInterval) {
      cancelInterval();
    }
  }, [wireStatus, cancelInterval, isIntervalRunning, isPendingStatus, inboundWire]);

  return (
    <ContentLayout
      title='Wire details'
      headerAction={
        isAllowedToCancelWire && (
          <Button variant='secondary' width={160} mobileStretch onClick={openCancelWireDialog}>
            Cancel Wire
          </Button>
        )
      }
      backButton='Back to Transaction'
      onBack={backToWireList}
      apiError={inboundWireData?.error}
    >
      <PageBody>
        <PageContent>
          <PageColumn>
            {isEditInboundWireWidget ? (
              <DetailsWidget header='Edit Wire' hasHighlight>
                <EditWireDetails status={wireStatus} dateUpdate={inboundWire?.date_modified} />
                <InboundWireEditForm
                  inboundWire={inboundWire}
                  currencyOptions={currencyOptions}
                  onClose={closeEditMode}
                  onUpdate={updateInboundWireSuccess}
                />
              </DetailsWidget>
            ) : (
              inboundWire && <OrgAdminWireDetails wire={inboundWire} onEdit={openEditMode} />
            )}
            {assignedDepositor && !isEditDepositor && (
              <UserDetailsWidget
                header='Depositor'
                user={assignedDepositor}
                action={isDepositorInvited && <DetailsEditButton onEdit={openEditDepositorInfoWidget} />}
                hasInvitedNotification={isDepositorInvited}
              />
            )}
          </PageColumn>
          <PageColumn>
            {isDepositorUnassignedWire && (
              <AssignDepositorForm
                wireId={id}
                isOpenConfirmModal={isOpenSendModal}
                onToggleConfirmModal={setIsOpenSendModal}
                onSuccess={assignedDepositorHandler}
              />
            )}
            {isEditDepositor && (
              <EditUserForm
                header='Edit Depositor'
                role={USER_ROLE_MAP.DEPOSITOR}
                user={inboundWire?.assigned_depositor?.user}
                wireId={inboundWire?.id}
                onClose={() => setIsEditDepositor(false)}
                onSuccess={refetchInboundWire}
              />
            )}
            {shouldSelectDepositAccount && (
              <SelectDepositAccountForm
                selectedDepositAccount={selectedDepositAccount}
                onChange={selectDepositAccount}
                isLoading={isFetchingDepositAccountPaymentDetails}
                selectedDepositAccountId={selectedAccountOption?.value}
                error={updatedPaymentDetails?.error}
                isPaymentDetailsUpdating={isPendingUpdatePaymentDetails}
                onCreate={mutateUpdatePaymentDetails}
              />
            )}
            {showPaymentDetailsLoader && (
              <DetailsWidget header='Wire Info'>
                <LoadPaymentDetailsLoading />
              </DetailsWidget>
            )}
            {paymentDetails && (
              <PaymentDetails
                paymentDetails={paymentDetails}
                wire={inboundWire}
                onReset={openDeletePaymentDetailsModal}
              />
            )}
          </PageColumn>
        </PageContent>
      </PageBody>
      <ConfirmModal
        isOpen={isOpenCancelModal}
        header='Cancel Wire'
        isLoading={isPendingCancelWire}
        body='You will not be able to view this wire once you cancel.'
        onClose={() => setIsOpenCancelModal(false)}
        onConfirm={mutateCancelWire}
      />
      <ConfirmModal
        isOpen={isOpenDeleteModal}
        header='Reset Wire Info'
        body='You will need to select a deposit account for this wire once again.'
        onClose={() => {
          setIsOpenDeleteModal(false);
        }}
        onConfirm={mutateResetPaymentDetails}
        isLoading={isPendingResetPaymentDetails}
      />
    </ContentLayout>
  );
};
