import {
  AMOUNT_VALIDATOR,
  REQUIRED_OBJECT_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  STRING_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';
import * as Yup from 'yup';
import { OptionType } from '@/components/form/fields/Select/Select';
import { WireTypesType } from '../../../Transactions/_Details/const/const';
import { WIRE_TYPE_SINGULAR_LABEL } from '@/const/wire';

const INBOUND_WIRE_OPTION = { value: 'inbound', label: WIRE_TYPE_SINGULAR_LABEL.inbound };
const OUTBOUND_WIRE_OPTION = { value: 'outbound', label: WIRE_TYPE_SINGULAR_LABEL.outbound };

export const WIRES_OPTIONS = [INBOUND_WIRE_OPTION, OUTBOUND_WIRE_OPTION];

export const getCreateWireInitialValues = (currencyOptions: OptionType[], selectedWireType: WireTypesType) => {
  const selectedWireOption = WIRES_OPTIONS.find((item) => item.value === selectedWireType);

  return {
    type: selectedWireOption,
    name: '',
    amount: '',
    currency: currencyOptions[0],
    expected_payment_date: '',
    notes: '',
  };
};

export const CREATE_WIRE_VALIDATION_SCHEMA = Yup.object().shape({
  type: REQUIRED_OBJECT_VALIDATOR,
  name: REQUIRED_TEXT_FIELD_VALIDATOR,
  amount: AMOUNT_VALIDATOR,
  currency: REQUIRED_OBJECT_VALIDATOR,
  notes: TEXT_FIELD_VALIDATOR,
});

export const EDIT_OUTBOUND_WIRE_VALIDATION_SCHEMA = Yup.object().shape({
  name: REQUIRED_TEXT_FIELD_VALIDATOR,
  amount: AMOUNT_VALIDATOR,
  currency: REQUIRED_OBJECT_VALIDATOR,
  notes: TEXT_FIELD_VALIDATOR,
  expected_payment_date: STRING_VALIDATOR,
});
