import { useFormik } from 'formik';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Button } from '@/components/Button/Button';
import { FormButtonBlock } from '../../../shared/ui/styles';
import { AssignedWireType } from '@/types/wireTypes';
import { ResponseErrorType } from '@/types/sharedTypes';
import { getChangedFormValues } from '@/helpers/formHelpers/formHelpers';
import { EDIT_OUTBOUND_WIRE_VALIDATION_SCHEMA } from './const/const';
import { formatOutboundWire } from './helpers/helpers';
import { getEditWirePayload } from '@/bundle/_OrgAdmin/shared/helpers/helpers';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { FormikTextArea } from '@/components/form/fields/FormikTextArea/FormikTextArea';
import { ReactComponent as CalendarIcon } from '../../../../../images/CalendarIcon.svg';
import { FormikDatePicker } from '@/components/form/fields/FormikDatePicker/FormikDatePicker';
import { FormikSelect } from '@/components/form/fields/FormikSelect/FormikSelect';
import { Wrapper } from './styles';
import { OptionType } from '@/components/form/fields/Select/Select';

type OutboundWireEditFormType = {
  onUpdateWireDetails: () => void;
  currencyOptions: OptionType[];
  outboundWire: AssignedWireType;
  onEdit: (data) => void;
  isLoading: boolean;
  onReset: () => void;
  apiError: ResponseErrorType;
};

export const OutboundWireEditForm = ({
  onUpdateWireDetails,
  currencyOptions,
  outboundWire,
  onEdit,
  isLoading,
  onReset,
  apiError,
}: OutboundWireEditFormType) => {
  const outboundWireFormatted = formatOutboundWire(outboundWire, currencyOptions);

  const formik = useFormik({
    initialValues: outboundWireFormatted,
    validationSchema: EDIT_OUTBOUND_WIRE_VALIDATION_SCHEMA,
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = getChangedFormValues(values, formik.initialValues);

      if (!payload) {
        onUpdateWireDetails();

        return;
      }

      const outboundWirePayload = getEditWirePayload(payload);

      onEdit(outboundWirePayload);
    },
  });

  const cancelHandler = () => {
    onReset();
    onUpdateWireDetails();
  };

  return (
    <>
      <FormikForm value={formik}>
        <FormikInput name='name' label='Wire Name*' placeholder='Enter Name' apiError={apiError} />
        <FormikInput name='ref_id' label='Wire ID' placeholder='Enter Wire ID' disabled apiError={apiError} />
        <FormikInput name='type' label='Wire Type' disabled apiError={apiError} />
        <Wrapper>
          <FormikInput
            name='date_created'
            label='Wire Creation Date'
            after={<CalendarIcon />}
            disabled
            apiError={apiError}
          />
          <FormikDatePicker
            name='expected_payment_date'
            label='Expected Payment Date'
            placeholder='Select Date'
            hasErrorComponent
            apiError={apiError}
          />
          <FormikInput name='amount' label='Wire Amount' placeholder='Enter Amount' apiError={apiError} />
          <FormikSelect name='currency' label='Currency' options={currencyOptions} apiError={apiError} />
        </Wrapper>
        <FormikTextArea name='notes' label='Notes' placeholder='Enter Notes' apiError={apiError} />
      </FormikForm>
      <FormButtonBlock>
        <Button variant='secondary' mobileStretch onClick={cancelHandler} width={200} size='medium'>
          Cancel
        </Button>
        <Button isLoading={isLoading} mobileStretch onClick={formik.handleSubmit} width={200} size='medium'>
          Save
        </Button>
      </FormButtonBlock>
    </>
  );
};
