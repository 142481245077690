import { OpcoUserStatus } from '@/bundle/_Opco/_OpcoUsersPage/ui/OpcoUserStatus/OpcoUserStatus';
import { UserRole } from '../../../shared/UserRole/UserRole';
import { formatDateTimeUTC, FULL_DATE_TIME_FORMAT } from '@/helpers/dateHelpers/dateHelpers';
import { ReactComponent as SiteAdminIcon } from '../images/SiteAdminIcon.svg';
import { ReactComponent as OrgAdminIcon } from '../images/OrgAdminIcon.svg';
import { ORG_ADMIN_ROLE } from '@/const/user';

export const ORGANIZATION_USER_STATUS = {
  ACTIVE: 'active',
  BLOCKED: 'blocked',
  INVITED: 'invited',
} as const;

export const ORGANIZATION_USER_ROLE_LABEL_MAP = {
  [ORG_ADMIN_ROLE.ORG_ADMIN]: 'Org Admin',
  [ORG_ADMIN_ROLE.SITE_ADMIN]: 'Site Admin',
};

export const ORGANIZATION_USER_ROLE_ICON_MAP = {
  [ORG_ADMIN_ROLE.SITE_ADMIN]: <SiteAdminIcon />,
  [ORG_ADMIN_ROLE.ORG_ADMIN]: <OrgAdminIcon />,
};

export const ORGANIZATIONS_USERS_TABLE_CONFIG = [
  {
    key: 1,
    name: 'user__email,-user__date_created',
    header: 'EMAIL ADDRESS',
    width: '20%',
    hasSort: true,
    hasTitle: true,
    getValue({ email }) {
      return email;
    },
  },
  {
    key: 2,
    name: 'user__first_name,-user__date_created',
    header: 'FIRST NAME',
    width: '20%',
    hasSort: true,
    hasTitle: true,
    getValue({ first_name }) {
      return first_name || '-';
    },
  },
  {
    key: 3,
    name: 'user__last_name,-user__date_created',
    header: 'LAST NAME',
    width: '20%',
    hasSort: true,
    hasTitle: true,
    getValue({ last_name }) {
      return last_name || '-';
    },
  },
  {
    key: 4,
    name: 'role,-user__date_created',
    header: 'ROLE',
    width: '15%',
    hasSort: true,
    getValue({ role }) {
      const formattedRole = ORGANIZATION_USER_ROLE_LABEL_MAP[role] || role;
      const icon = ORGANIZATION_USER_ROLE_ICON_MAP[role];

      return <UserRole role={formattedRole} icon={icon} />;
    },
  },
  {
    key: 5,
    name: 'user_status,-user__date_created',
    header: 'STATUS',
    width: '10%',
    hasSort: true,
    getValue({ status }) {
      return <OpcoUserStatus status={status} />;
    },
  },
  {
    key: 6,
    name: 'user__last_login,-user__date_created',
    header: 'LAST LOGIN',
    width: '15%',
    hasSort: true,
    hasTooltip: true,
    hasTitle: true,
    getValue({ last_login }) {
      return formatDateTimeUTC(last_login, FULL_DATE_TIME_FORMAT);
    },
  },
];
