import {
  AMOUNT_VALIDATOR,
  REQUIRED_EMAIL_VALIDATOR,
  REQUIRED_OBJECT_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  STRING_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';
import * as Yup from 'yup';

export const getCreateChildWireValidationSchema = (isChainProxy: boolean = false) => {
  return Yup.object().shape({
    name: REQUIRED_TEXT_FIELD_VALIDATOR,
    amount: AMOUNT_VALIDATOR,
    email: REQUIRED_EMAIL_VALIDATOR,
    ...(isChainProxy && { email_proxy: REQUIRED_EMAIL_VALIDATOR }),
    notes: TEXT_FIELD_VALIDATOR,
    currency: REQUIRED_OBJECT_VALIDATOR,
    expected_payment_date: STRING_VALIDATOR,
  });
};
