import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { AssignExecutorFormWrapper } from '../../styles';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Button } from '@/components/Button/Button';
import { ConfirmModal, ConfirmModalHint } from '@/components/ConfirmModal/ConfirmModal';
import { FormValues, isValidForm } from '@/helpers/formHelpers/formHelpers';
import { FormButtonBlock, FormMessage, FormTitle } from '../../../../shared/ui/styles';
import { assignDepositor, createDepositor, depositorAssignmentStatus } from '../../api';
import { Maybe } from '@/types/sharedTypes';
import { AutocompleteOptionType } from '@/components/Autocomplete/types';
import { AssignUserAutocomplete } from '@/bundle/shared/components/AssignUserAutocomplete';
import { USER_ROLE_MAP } from '@/const/user';
import { loadUserInfo } from '@/bundle/shared/api';
import { useAssignUser } from '@/bundle/shared/hooks/useAssignUser';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import {
  ASSIGN_AMPLITUDE_EVENTS_REDIRECT_MAP,
  ASSIGN_AMPLITUDE_EVENTS_SUCCESS_MAP,
  ERROR_ASSIGN_AMPLITUDE_EVENTS_REDIRECT_MAP,
} from '@/bundle/_OrgAdmin/pages/InboundWire/const/amplitudeEventsMap';
import { ASSIGN_USER_INITIAL_VALUES, ASSIGN_USER_SCHEMA } from '@/bundle/_OrgAdmin/shared/const/const';
import { FormikPhoneInput } from '@/components/form/fields/FormikPhoneInput/FormikPhoneInput';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { getIsAvailableToAssign } from '@/helpers/userHelpers';

type AssignDepositorFormType = {
  wireId: string;
  isOpenConfirmModal: boolean;
  onToggleConfirmModal: (isOpen: boolean) => void;
  onSuccess: () => void;
};

export const AssignDepositorForm = ({
  wireId,
  isOpenConfirmModal,
  onToggleConfirmModal,
  onSuccess,
}: AssignDepositorFormType) => {
  const [selectedDepositorOption, setSelectedDepositorOption] = useState<Maybe<AutocompleteOptionType>>(null);
  const depositorId = selectedDepositorOption?.value;

  const { data: assignmentStatusData } = useQuery({
    queryKey: ['load_assignment_status', depositorId],
    queryFn: () => depositorAssignmentStatus(depositorId),
    enabled: !!selectedDepositorOption?.isSelectedOption,
  });

  const { data: loadUserData } = useQuery({
    queryKey: ['load_user', depositorId],
    queryFn: () => loadUserInfo(depositorId),
    enabled: !!selectedDepositorOption?.isSelectedOption && !!assignmentStatusData?.body?.is_available_to_assign,
  });

  const {
    data: createDepositorData,
    mutate: createDepositorMutate,
    isPending: isPendingCreateDepositor,
    reset: resetCreateDepositor,
  } = useMutation({
    mutationKey: ['create_depositor'],
    mutationFn: (newDepositorData: FormValues) => {
      return createDepositor(wireId, newDepositorData);
    },
    onSuccess(createDepositorResponse) {
      if (createDepositorResponse?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateNewDepositorSuccess);

      onSuccess();
    },
    onSettled() {
      onToggleConfirmModal(false);
    },
  });

  const {
    data: assignDepositorData,
    mutate: assignDepositorMutate,
    isPending: isPendingAssignDepositor,
    reset: resetAssignDepositor,
  } = useMutation({
    mutationKey: ['assign_depositor'],
    mutationFn: () => {
      const userData = loadUserData?.body;

      return assignDepositor(wireId, userData?.id);
    },
    onSuccess(assignDepositorResponse) {
      if (assignDepositorResponse?.error) return;

      const currentStatus = assignmentStatusData?.body?.code;

      amplitudeService.logEvent(ASSIGN_AMPLITUDE_EVENTS_SUCCESS_MAP[currentStatus]);

      onSuccess();
    },
    onSettled() {
      onToggleConfirmModal(false);
    },
  });

  const isAvailableToAssign = getIsAvailableToAssign(assignmentStatusData?.body?.is_available_to_assign);

  const user = loadUserData?.body;
  const isDisabledForm = !!user || !isAvailableToAssign;

  const formik = useFormik({
    initialValues: ASSIGN_USER_INITIAL_VALUES,
    validationSchema: isDisabledForm ? null : ASSIGN_USER_SCHEMA,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (selectedDepositorOption?.isSelectedOption) {
        assignDepositorMutate();

        return;
      }

      createDepositorMutate(values);
    },
  });

  const closeConfirmModal = () => onToggleConfirmModal(false);

  const onReset = () => {
    resetCreateDepositor();
    resetAssignDepositor();
  };

  useAssignUser(
    loadUserData?.body,
    formik,
    onReset,
    selectedDepositorOption,
    assignmentStatusData?.body?.is_available_to_assign
  );
  const assignmentStatus = assignmentStatusData?.body?.code;
  const createDepositorError = createDepositorData?.error;
  const assignRecipientError = assignDepositorData?.error;
  const apiError = createDepositorError || assignRecipientError;
  const isLoading = isPendingAssignDepositor || isPendingCreateDepositor;

  const openConfirmModal = async () => {
    if (assignmentStatusData?.body?.is_available_to_assign) {
      amplitudeService.logEvent(ASSIGN_AMPLITUDE_EVENTS_REDIRECT_MAP[assignmentStatus]);
    } else {
      amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateDepositorRedirect);
    }

    const isValid = await isValidForm(formik);

    onToggleConfirmModal(isValid);
  };

  useEffect(() => {
    const assignmentStatusBody = assignmentStatusData?.body;

    if (assignmentStatusBody && !assignmentStatusBody?.is_available_to_assign) {
      amplitudeService.logEvent(ERROR_ASSIGN_AMPLITUDE_EVENTS_REDIRECT_MAP[assignmentStatusBody?.code]);
    }
  }, [assignmentStatusData?.body]);

  return (
    <AssignExecutorFormWrapper>
      <FormTitle>Assign Depositor</FormTitle>
      <FormMessage>Please indicate who will be making this deposit.</FormMessage>
      <FormikForm value={formik}>
        <AssignUserAutocomplete
          role={USER_ROLE_MAP.DEPOSITOR}
          assignmentStatus={assignmentStatus}
          selectedOption={selectedDepositorOption}
          onSelect={setSelectedDepositorOption}
          apiError={apiError}
          error={formik?.errors.email}
        />
        <FormikPhoneInput
          name='mobile_phone'
          label='Mobile Phone Number*'
          disabled={isDisabledForm}
          apiError={apiError}
        />
        <FormikInput
          name='first_name'
          label='First Name*'
          placeholder='Enter First Name'
          disabled={isDisabledForm}
          apiError={apiError}
        />
        <FormikInput
          name='middle_name'
          label='Middle Name'
          placeholder='Enter Middle Name'
          disabled={isDisabledForm}
          apiError={apiError}
        />
        <FormikInput
          name='last_name'
          label='Last Name*'
          placeholder='Enter Last Name'
          disabled={isDisabledForm}
          apiError={apiError}
        />
      </FormikForm>
      <FormButtonBlock>
        <Button width={172} size='medium' onClick={openConfirmModal} disabled={!isAvailableToAssign}>
          Assign
        </Button>
      </FormButtonBlock>

      <ConfirmModal
        isOpen={isOpenConfirmModal}
        header='Confirm Wire Information'
        isLoading={isLoading}
        body={
          <>
            <ConfirmModalHint>
              You are certifying the validity of the wire information you have provided.
            </ConfirmModalHint>
            <ConfirmModalHint>
              This information will be committed to the WireVault blockchain and cannot be changed.
            </ConfirmModalHint>
            <ConfirmModalHint>You can cancel this wire until the deposit is sent.</ConfirmModalHint>
          </>
        }
        onClose={closeConfirmModal}
        onConfirm={formik.handleSubmit}
      />
    </AssignExecutorFormWrapper>
  );
};
