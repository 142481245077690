import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { formatInboundWire } from '../helpers/formatInboundWire';
import { FormButtonBlock } from '../../../shared/ui/styles';
import { updateInboundWire } from '../api';
import { getChangedFormValues } from '@/helpers/formHelpers/formHelpers';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { getEditWirePayload } from '@/bundle/_OrgAdmin/shared/helpers/helpers';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { ReactComponent as CalendarIcon } from '@/images/CalendarIcon.svg';
import { Button } from '@/components/Button/Button';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { FormikDatePicker } from '@/components/form/fields/FormikDatePicker/FormikDatePicker';
import { FormikSelect } from '@/components/form/fields/FormikSelect/FormikSelect';
import { FormikTextArea } from '@/components/form/fields/FormikTextArea/FormikTextArea';
import { AssignedWireType } from '@/types/wireTypes';
import { EDIT_INBOUND_WIRE_VALIDATION_SCHEMA } from './const';
import { FieldRow } from '@/components/form/FieldRow/FieldRow';

type InboundWireEditFormType = {
  inboundWire: AssignedWireType;
  currencyOptions: { value: string; label: string }[];
  onClose: () => void;
  onUpdate: () => void;
};

export const InboundWireEditForm = ({
  inboundWire,
  onClose,
  onUpdate,
  currencyOptions = [],
}: InboundWireEditFormType) => {
  const queryClient = useQueryClient();
  const initialValues = formatInboundWire(inboundWire, currencyOptions);

  const { data, mutate, isPending } = useMutation({
    mutationKey: ['update_inbound_wire'],
    mutationFn: (payload: any) => updateInboundWire(inboundWire?.id, payload),
    onSuccess: (updatedInboundWire) => {
      if (updatedInboundWire?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.EditGeneralInfoInboundWireSuccess);

      onUpdate();

      queryClient.setQueryData(['inbound_wire', inboundWire?.id], updatedInboundWire);
    },
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: EDIT_INBOUND_WIRE_VALIDATION_SCHEMA,
    onSubmit: async (values) => {
      const payload = getChangedFormValues(values, formik.initialValues);

      if (!payload) {
        onClose();

        return;
      }

      const inboundWirePayload = getEditWirePayload(payload);

      await mutate(inboundWirePayload);
    },
  });

  const apiError = data?.error;

  return (
    <>
      <FormikForm value={formik}>
        <FormikInput name='name' label='Wire Name*' placeholder='Enter Name' apiError={apiError} />
        <FormikInput name='ref_id' label='Wire ID' placeholder='Enter Wire ID' disabled apiError={apiError} />
        <FormikInput name='type' label='Wire Type' disabled apiError={apiError} />

        <FieldRow columns={[6, 6]}>
          <FormikInput
            name='date_created'
            label='Wire Creation Date'
            disabled
            after={<CalendarIcon />}
            apiError={apiError}
          />
          <FormikDatePicker
            name='expected_payment_date'
            label='Expected Payment Date'
            placeholder='Select Date'
            hasErrorComponent
            apiError={apiError}
          />
        </FieldRow>

        <FieldRow columns={[6, 6]}>
          <FormikInput name='amount' label='Wire Amount' placeholder='Enter Number' apiError={apiError} />
          <FormikSelect name='currency' label='Currency' options={currencyOptions} apiError={apiError} />
        </FieldRow>

        <FormikTextArea name='notes' label='Notes' placeholder='Enter Notes' apiError={apiError} />
      </FormikForm>
      <FormButtonBlock>
        <Button variant='secondary' mobileStretch onClick={onClose} width={160} size='medium'>
          Cancel
        </Button>
        <Button onClick={formik.handleSubmit} mobileStretch isLoading={isPending} width={160} size='medium'>
          Save
        </Button>
      </FormButtonBlock>
    </>
  );
};
