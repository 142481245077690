import { changeBillingPlanStatusApi } from '@/api/v1/opco/billing/changeBillingPlanStatusApi';
import { getBillingPlanDetailsApi } from '@/api/v1/opco/billing/getBillingPlanDetailsApi';
import { BillingPlanStatusType } from '@/const/billing';
import { getAuthTokenHeaders } from '@/helpers/apiHelpers/queryHelper';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const loadBillingPlanDetails = async (id: string) => {
  try {
    const response = await getBillingPlanDetailsApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const changeBillingPlanStatus = async (id: string, status: BillingPlanStatusType, pinToken: string) => {
  const options = getAuthTokenHeaders(pinToken);

  const payload = {
    status,
  };

  try {
    const response = await changeBillingPlanStatusApi(id, payload, options);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
