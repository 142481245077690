import { ChangeEvent, ComponentPropsWithoutRef, FormEvent, ReactNode, useState } from 'react';
import { Input } from '../../../../components/form/fields/Input/Input';
import { InputSizeVariant } from '../../../../components/form/fields/Input/styles';
import { ReactComponent as SearchIcon } from './images/search-icon.svg';
import { ReactComponent as FiltersIcon } from './images/filters-icon.svg';
import { IconFiltersWrapper, SearchInputWrapper } from './styles';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { TEXT_MAX_LENGTH } from '@/helpers/formHelpers/validators';
import { InputClearButton } from '@/components/form/fields/Input/ui/InputClearButton/InputClearButton';
import { Box } from '@/components/Box/Box';
import { PopoverMenu } from '@/components/PopoverMenu/PopoverMenu';

type SearchInputType = {
  variant?: InputSizeVariant;
  filters?: ReactNode;
  onClear: () => void;
  onChange: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
} & ComponentPropsWithoutRef<'input'>;

export const SearchInput = ({ variant = 'small', filters, onChange, onSubmit, onClear, ...props }: SearchInputType) => {
  const [isOpenFiltersModal, setIsOpenFiltersModal] = useState(false);

  const hasClearButton = !!props.value;

  const changeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value;

    // When next value is empty - trigger the onClear event
    if (props.value && !eventValue) {
      onClear();
    }

    onChange(eventValue, event);
  };

  const submitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit();
  };

  return (
    <SearchInputWrapper onSubmit={submitSearch}>
      <Input
        variant={variant}
        onChange={changeValue}
        before={<SearchIcon />}
        maxLength={TEXT_MAX_LENGTH}
        after={
          <Box display='flex' mr='-12px' height='100%'>
            {hasClearButton && <InputClearButton onClear={onClear} tooltip='Clear search' />}
            {filters && (
              <PopoverMenu
                isOpen={isOpenFiltersModal}
                onToggleOpen={setIsOpenFiltersModal}
                anchorSlot={
                  <IconFiltersWrapper
                    data-tooltip-id='search-filters-tooltip'
                    selected={isOpenFiltersModal}
                    onClick={() => setIsOpenFiltersModal(!isOpenFiltersModal)}
                  >
                    <Tooltip id='search-filters-tooltip' type='hint'>
                      Search filters
                    </Tooltip>
                    <FiltersIcon />
                  </IconFiltersWrapper>
                }
              >
                {filters}
              </PopoverMenu>
            )}
          </Box>
        }
        {...props}
      />
    </SearchInputWrapper>
  );
};
