import { Box } from '@/components/Box/Box';
import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { Typography } from '@/components/Typography/Typography';
import { AccountData, BillingInformationDivider, BillingInformationWrapper, CardIconWrapper } from './styles';
import { BillingStatusBadge } from './BillingStatusBadge';
import { COLORS } from '@/styles/colors';
import { PAYMENT_CARDS_ICON_MAP } from '../../PaymentCard/const';
import { PaymentCardNumber } from '../../PaymentCardNumber/PaymentCardNumber';
import { BillingTransaction } from '@/types/paymentTypes';
import { getAddonsFromTransaction, getSubscriptionFromTransaction } from '../helpers';
import { convertCentsToDollars } from '@/helpers/formatHelpers';
import { getFormattedBillingAddress } from '../../BillingAddressCard/helpers';
import { formatDateTimeUTC } from '@/helpers/dateHelpers/dateHelpers';

type BillingTransactionDetailsType = {
  transaction: BillingTransaction;
};

export const BillingTransactionDetails = ({ transaction }: BillingTransactionDetailsType) => {
  if (!transaction) return null;

  const { id, customer_id, amount, billing_address, response_body, settled_at } = transaction;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const subscription = getSubscriptionFromTransaction(transaction);
  const addons = getAddonsFromTransaction(transaction);
  const { address, city, country, zip, state } = getFormattedBillingAddress(billing_address);
  const { last_four, card_type } = response_body?.card || {};

  return (
    <Box>
      <BillingInformationWrapper>
        <DetailsRow name='Date' offset='small'>
          {formatDateTimeUTC(settled_at)}
        </DetailsRow>
        <DetailsRow name='Transaction Id' offset='small'>
          {id}
        </DetailsRow>
        <DetailsRow name='Billing Frequency' offset='small'>
          No Data
        </DetailsRow>
        <DetailsRow name='Customer ID' offset='small'>
          {customer_id}
        </DetailsRow>
        <DetailsRow name='Account Data' offset='small'>
          <AccountData>
            <CardIconWrapper>{PAYMENT_CARDS_ICON_MAP[card_type]}</CardIconWrapper>
            <PaymentCardNumber cardNumber={last_four} />
          </AccountData>
        </DetailsRow>
        <DetailsRow name='Status' offset='small'>
          <BillingStatusBadge status='paid'></BillingStatusBadge>
        </DetailsRow>
        <BillingInformationDivider />
        <DetailsRow
          name={
            <Typography color={COLORS.grey[650]} fontWeight='semibold'>
              Amount
            </Typography>
          }
          offset='small'
        >
          <Typography color={COLORS.black} variant='headline3' fontWeight='semibold'>
            {convertCentsToDollars(amount)}
          </Typography>
        </DetailsRow>
      </BillingInformationWrapper>

      <Box mb='24px'>
        <Typography fontWeight='medium' mb='8px'>
          Billing Information
        </Typography>
        <DetailsRow name='Address' offset='small'>
          {address}
        </DetailsRow>
        <DetailsRow name='City' offset='small'>
          {city}
        </DetailsRow>
        <DetailsRow name='State/Region' offset='small'>
          {state || '-'}
        </DetailsRow>
        <DetailsRow name='Zip Code' offset='small'>
          {zip}
        </DetailsRow>
        <DetailsRow name='Country' offset='small'>
          {country}
        </DetailsRow>
      </Box>
      <Typography fontWeight='medium' mb='8px'>
        Plan Information
      </Typography>
      <DetailsRow name='Plan Price' offset='small'>
        {convertCentsToDollars(subscription?.amount)}
      </DetailsRow>
      {!!addons.length && (
        <DetailsRow name='ID Verification (3FA) Price' offset='small'>
          {convertCentsToDollars(addons[0]?.amount)}
        </DetailsRow>
      )}
    </Box>
  );
};
