import { ApiParams } from '@/api/helpers/urlApiHelpers';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { FilterParamsType } from '@/api/helpers/queryHelpers';
import { getBilledEventsApi } from '@/api/v1/opco/events/getBilledEventsApi';
import { getBillingReportApi } from '@/api/v1/opco/events/getBillingReportApi';
import { prepareBillingSearchFiltersListApiParams } from '@/bundle/_Opco/_BillingPage/_BilledEventsPage/helpers/helpers';

export const loadBilledEvents = async (params: ApiParams) => {
  const apiParams = prepareBillingSearchFiltersListApiParams(params);

  try {
    const response = await getBilledEventsApi(apiParams);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const downloadBillingFile = async (filters: FilterParamsType) => {
  try {
    const response = await getBillingReportApi(filters);

    return prepareResponse(response, ['Content-Disposition']);
  } catch (e) {
    throwResponseError(e);
  }
};
