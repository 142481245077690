import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';

type SwitchToggleLabelType = {
  isYearlyIdVerificationPrice: string;
  addonName: string;
};

export const SwitchToggleLabel = ({ isYearlyIdVerificationPrice, addonName }: SwitchToggleLabelType) => {
  // const convertedVerificationPrice = convertCentsToDollars(isYearlyIdVerificationPrice);

  return (
    <Box display='flex' flexDirection='row' columnGap={2}>
      <Typography variant='bodySmall' color={COLORS.black}>
        {addonName} -
      </Typography>
      <Typography variant='bodySmall' fontWeight='semibold'>
        {isYearlyIdVerificationPrice}
      </Typography>
    </Box>
  );
};
