export const trimString = (text: string, showLetter = 30) => {
  if (text?.length > showLetter) {
    let slicedText = text.slice(0, showLetter);

    slicedText += '...';

    return slicedText;
  }

  return text;
};
