import { flex } from '@/styles/layout';
import { styled } from 'styled-components';

export const InputClearButtonWrapper = styled.div`
  ${flex({ alignItems: 'center' })};
  margin-inline: 2px 12px;
  cursor: pointer;

  &:not(:hover) {
    display: none;
  }
`;

export const inputClearButtonVisibility = () => `
  &:focus-within,
  &:hover:not(:focus-within) {
    ${InputClearButtonWrapper} {
      ${flex()};
    }
  }`;
