export const COLORS = {
  white: '#FFFFFF',
  black: '#151618',
  grey: {
    50: '#F3F4F7',
    150: '#F5F7FA',
    200: '#ECEEF4',
    250: '#FAFBFE',
    300: '#E4E7EF',
    400: '#BEC2CC',
    600: '#9197A5',
    650: '#808695',
    700: '#4B505A',
    850: '#333539',
    900: '#27292D',
    950: '#1F2023',
  },
  mint: {
    100: '#EEFFFA',
    300: '#69E3CE',
  },
  green: {
    200: '#E4FFF5',
    300: '#D8F7E6',
    350: '#0ABF97',
    400: '#019266',
    450: '#018E76',
    500: '#00866F',
  },
  red: {
    100: '#FFE7E9',
    200: '#FFD8DA',
    400: '#E3414C',
    500: '#C6242E',
    600: '#D30101',
  },
  yellow: {
    100: '#FEF0CC',
    200: '#FEF6E0',
    400: '#FFCD49',
    500: '#CD9A18',
  },
  blue: {
    200: '#E9F2FF',
    250: '#E0EAFF',
    500: '#3481FC',
    700: '#06348B',
  },
  purple: {
    100: '#F7E8FF',
    200: '#D0A3ED',
    500: '#8910D9',
  },
  violet: {
    150: '#F3EAFB',
    200: '#E7E9FE',
    500: '#615EFF',
  },
  light_ocean: '#E9FBFF',
  deep_blue: {
    500: '#00668F',
  },
  orange: {
    100: '#FFF3DD',
  },
} as const;

type ExtractStringValues<T> = T extends object
  ? { [K in keyof T]: ExtractStringValues<T[K]> }[keyof T]
  : T extends string
    ? T
    : never;

export type ColorType = ExtractStringValues<typeof COLORS>;
