import {
  REQUIRED_EMAIL_VALIDATOR,
  REQUIRED_PHONE_NUMBER_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';
import * as Yup from 'yup';

export const CREATE_OPCO_INITIAL_VALUES = {
  email: '',
  mobile_phone: '',
  first_name: '',
  middle_name: '',
  last_name: '',
};

export const CREATE_OPCO_VALIDATION_SCHEMA = Yup.object().shape({
  email: REQUIRED_EMAIL_VALIDATOR,
  mobile_phone: REQUIRED_PHONE_NUMBER_VALIDATOR,
  first_name: REQUIRED_TEXT_FIELD_VALIDATOR,
  middle_name: TEXT_FIELD_VALIDATOR,
  last_name: REQUIRED_TEXT_FIELD_VALIDATOR,
});
