import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { USER_ASSIGNMENT_STATUS_CODE_MAP } from '@/const/shared';
import { USER_ROLE_MAP } from '@/const/user';
import { ASSIGN_USER_INITIAL_VALUES, AssignUserFormType } from '@/bundle/_OrgAdmin/shared/const/const';
import { isCancelledWireStatus } from '@/helpers/wireHelpers';
import { AssignedWireType } from '@/types/wireTypes';

export const EVENTS_RECIPIENT_ACTION_TYPE = {
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_SAME_ORGANIZATION]:
    AMPLITUDE_EVENTS.AssignInvitedRecipient,
  [USER_ASSIGNMENT_STATUS_CODE_MAP.CAN_BE_ASSIGNED]: AMPLITUDE_EVENTS.AssignExistingRecipient,
};

export const EVENTS_EXECUTOR_ACTION_TYPE = {
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_SAME_ORGANIZATION]:
    AMPLITUDE_EVENTS.AssignInvitedExecutor,
  [USER_ASSIGNMENT_STATUS_CODE_MAP.CAN_BE_ASSIGNED]: AMPLITUDE_EVENTS.AssignExistingExecutor,
};

export type AssignRecipientFormType = AssignUserFormType & {
  is_proxy: boolean;
  is_pd_provider_kyc_required: boolean;
};

export const ASSIGN_RECIPIENT_INITIAL_VALUES: AssignRecipientFormType = {
  ...ASSIGN_USER_INITIAL_VALUES,
  is_proxy: false,
  is_pd_provider_kyc_required: false,
};

export const ASSIGN_RECIPIENT_PROXY_OPTIONS = [
  {
    value: false,
    label: 'Recipient',
    description: 'This person will provide their own wire info.',
    meta: 'Secure',
  },
  {
    value: true,
    label: 'Proxy',
    description: 'This person can invite the Recipient OR provide wire info on their behalf.',
  },
];

export const EDIT_USER_AMPLITUDE_EVENTS_MAP = {
  [USER_ROLE_MAP.RECIPIENT]: AMPLITUDE_EVENTS.EditRecipientInfoSuccess,
  [USER_ROLE_MAP.EXECUTOR]: AMPLITUDE_EVENTS.EditExecutorInfoSuccess,
  [USER_ROLE_MAP.DEPOSITOR]: AMPLITUDE_EVENTS.EditDepositorInfoSuccess,
};

export const ERROR_ASSIGN_RECIPIENT_AMPLITUDE_EVENTS_MAP = {
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_ANOTHER_ORGANIZATION]:
    AMPLITUDE_EVENTS.AssignRecipientFromOtherOrgFailed,
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_NOT_SAME_ROLE_AND_INVITED]: AMPLITUDE_EVENTS.AssignRecipientFromAnyOrgFailed,
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_SAME_USER]: AMPLITUDE_EVENTS.AssignRecipientYourSelfFailed,
};

export const ERROR_ASSIGN_EXECUTOR_AMPLITUDE_EVENTS_MAP = {
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_ANOTHER_ORGANIZATION]:
    AMPLITUDE_EVENTS.ExecutorFromOtherOrgFailed,
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_NOT_SAME_ROLE_AND_INVITED]: AMPLITUDE_EVENTS.ExecutorFromAnyOrgFailed,
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_SAME_USER]: AMPLITUDE_EVENTS.AssignExecutorYourSelfFailed,
};

export const getHeaderActionMessage = (wire: AssignedWireType) => {
  const isCancelledStatus = isCancelledWireStatus(wire?.status);
  const hasViewPaymentDetailsEvent = wire?.view_payment_details_event_register;
  const isPaymentDetailsExist = wire?.is_payment_details_exist;
  const isParentWire = wire?.parent_wire;
  const isChildWire = wire?.child_wire;
  const isCancelledWithPaymentDetails = hasViewPaymentDetailsEvent && isCancelledStatus && isPaymentDetailsExist;

  if (isCancelledWithPaymentDetails && (isParentWire || isChildWire)) {
    return 'You can create only one replacement wire.';
  }

  return null;
};
