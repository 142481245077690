import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from '../../styles/colors';
import { bodySmall, poppinsMediumFont, poppinsRegularFont } from '../../styles/typography';

export type NavigationLinkVariantType = 'primary' | 'secondary';

export const NavigationLinkWrapper = styled(NavLink)<{ variant: NavigationLinkVariantType; $underline?: boolean }>`
  color: ${COLORS.blue[500]};
  ${bodySmall};
  ${poppinsRegularFont};
  text-decoration: none;

  ${({ variant }) =>
    variant === 'secondary' &&
    `
    color: ${COLORS.green[500]};
    ${poppinsMediumFont}
  `}

  ${({ $underline }) =>
    $underline &&
    `
    text-decoration: underline;
  `}
`;
