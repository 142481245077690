import { ComponentPropsWithoutRef } from 'react';
import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { CustomTextArea } from '@/components/form/fields/TextArea/styles';
import { InputError } from '@/components/form/fields/InputError/InputError';

export type TextAreaType = {
  label?: string;
  apiError?: string;
  error?: string;
  hasErrorComponent?: boolean;
} & ComponentPropsWithoutRef<'textarea'>;

export const TextArea = ({ label, apiError, error, rows = 3, hasErrorComponent = true, ...rest }: TextAreaType) => {
  const hasError = !!error || !!apiError;

  return (
    <Box width='100%' display='flex' flexDirection='column'>
      {label && (
        <Typography variant='bodySmall' color={COLORS.black} mb='4px'>
          {label}
        </Typography>
      )}
      <CustomTextArea hasError={hasError} rows={rows} {...rest} />
      {hasErrorComponent && (
        <Box display='flex'>
          <InputError error={error} apiError={apiError} />
        </Box>
      )}
    </Box>
  );
};
