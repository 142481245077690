import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { FieldRow } from '@/components/form/FieldRow/FieldRow';
import { Input } from '@/components/form/fields/Input/Input';
import { PlanExtensions } from './ui/PlanExtensions';
import { DrawerHeaderBackLine } from '../styles';
import { COLORS } from '@/styles/colors';
import { FormikSelect } from '@/components/form/fields/FormikSelect/FormikSelect';
import { PaymentServicePlanData } from '@/api/v1/opco/billing/getPaymentsPlanApi';
import { useEffect, useState } from 'react';
import { OptionType } from '@/components/form/fields/Select/Select';
import { convertCentsToDollars } from '@/helpers/formatHelpers';
import { useField } from 'formik';
import { BillingPlanType } from '@/api/v1/opco/billing/getBillingPlansApi';
import { sortDisabledOptions } from './helpers';

type SelectBasePlanFormSectionType = {
  billingPlans: BillingPlanType[];
  baseBillingPlans: PaymentServicePlanData[];
};

export const SelectBasePlanFormSection = ({ billingPlans, baseBillingPlans }: SelectBasePlanFormSectionType) => {
  const [field] = useField('basePlan');
  const [selectedPlan, setSelectedPlan] = useState<PaymentServicePlanData>(null);

  const basePlanSelectOptions: OptionType[] = baseBillingPlans.map((basePlan) => ({
    value: basePlan.id,
    label: basePlan.name,
    isDisabled: billingPlans.some((plan) => plan.payment_service_id === basePlan.id),
  }));

  useEffect(() => {
    if (!field.value) {
      return;
    }

    const plan = baseBillingPlans.find((basePlan) => basePlan.id === field.value.value);

    setSelectedPlan(plan);
  }, [field.value, baseBillingPlans]);

  return (
    <Box display='flex' flexDirection='column' mb='12px'>
      <DrawerHeaderBackLine>
        <Typography variant='subTitleSmall' fontWeight='medium'>
          Pepper Pay Plan
        </Typography>
      </DrawerHeaderBackLine>
      <Typography mt='20px' mb='16px' color={COLORS.grey[650]} variant='bodySmall'>
        Discover more details about the plan on the Pepper Pay page.
      </Typography>

      <FormikSelect
        name='basePlan'
        label='Pepper Pay Plan*'
        placeholder='Select Pepper Pay Plan'
        options={sortDisabledOptions(basePlanSelectOptions)}
      />

      {selectedPlan && (
        <Box>
          <Input value={selectedPlan.id} label='Plan ID' disabled />

          <FieldRow columns={[6, 6]}>
            <Input value={convertCentsToDollars(selectedPlan.amount)} label='Amount' disabled />
            <Input value={selectedPlan.billing_frequency} label='Billing Frequency' disabled />
          </FieldRow>

          {!!selectedPlan.add_ons?.length && (
            <Box mb='22px'>
              <Typography color={COLORS.black} fontWeight='medium' mb='4px'>
                Addons
              </Typography>
              <PlanExtensions extensions={selectedPlan.add_ons || []} />
            </Box>
          )}

          {!!selectedPlan.discounts?.length && (
            <Box mb='22px'>
              <Typography color={COLORS.black} fontWeight='medium' mb='4px'>
                Discounts
              </Typography>
              <PlanExtensions extensions={selectedPlan.discounts} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
