import { Box } from '@/components/Box/Box';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { FormikPhoneInput } from '@/components/form/fields/FormikPhoneInput/FormikPhoneInput';
import { RadioGroup } from '@/components/form/fields/RadioGroup/RadioGroup';
import { PaymentType } from '@/types/paymentDetailsTypes';
import { ResponseErrorType } from '@/types/sharedTypes';
import { getPaymentDetailsInputConfig, PROVIDE_PAYMENT_TYPE_OPTIONS } from './const';
import { AddIntermediaryBankForm } from './ui/AddIntermediaryBankForm/AddIntermediaryBankForm';
import { FieldRow } from '@/components/form/FieldRow/FieldRow';

type ProvidePaymentDetailsFormType = {
  apiError: ResponseErrorType;
  selectedPaymentType: PaymentType;
  onSelectPaymentType: (paymentType: PaymentType) => void;
  isOpenIntermediaryBank: boolean;
  onOpenIntermediaryBank: (open: boolean) => void;
};

export const ProvidePaymentDetailsForm = ({
  apiError,
  selectedPaymentType,
  onSelectPaymentType,
  isOpenIntermediaryBank,
  onOpenIntermediaryBank,
}: ProvidePaymentDetailsFormType) => {
  const paymentDetailsInputConfig = getPaymentDetailsInputConfig(selectedPaymentType);

  return (
    <>
      <Box mb='22px' display='flex' flexDirection='column' rowGap={12}>
        <RadioGroup
          label='Payment Type'
          value={selectedPaymentType}
          onChange={(e) => onSelectPaymentType(e.target.value as PaymentType)}
          options={PROVIDE_PAYMENT_TYPE_OPTIONS}
        />
      </Box>
      <FormikInput name='recipient_name' label='Recipient*' placeholder='Enter Recipient Name' apiError={apiError} />
      <FormikInput
        name='recipient_address'
        label='Recipient Address*'
        placeholder='Enter Recipient Address'
        apiError={apiError}
      />
      <FormikInput name='account_name' label='Account Name' placeholder='Enter Account Name' apiError={apiError} />

      <FieldRow columns={[6, 6]}>
        <FormikInput
          name={paymentDetailsInputConfig.name}
          type='password'
          label={paymentDetailsInputConfig.label}
          placeholder={paymentDetailsInputConfig.placeholder}
          apiError={apiError}
          autoComplete='new-password'
        />
        <FormikInput
          name={paymentDetailsInputConfig.confirmName}
          label={paymentDetailsInputConfig.confirmLabel}
          placeholder={paymentDetailsInputConfig.placeholder}
          apiError={apiError}
          autoComplete='off'
          isCopyPasteDisabled
          isConfirmTicks={true}
        />
      </FieldRow>

      <FormikInput name='bank_name' label='Bank Name' placeholder='Enter Bank Name' apiError={apiError} />

      <FieldRow columns={[6, 6]}>
        <FormikInput
          name='bank_account_number'
          type='password'
          label='Account Number*'
          placeholder='Enter Number'
          apiError={apiError}
        />
        <FormikInput
          name='confirm_bank_account_number'
          label='Confirm Account Number*'
          placeholder='Enter Number'
          apiError={apiError}
          autoComplete='off'
          isCopyPasteDisabled
          isConfirmTicks={true}
        />
      </FieldRow>

      <FormikInput name='bank_address' label='Bank Address' placeholder='Enter Bank Address' apiError={apiError} />
      <FormikPhoneInput
        name='bank_phone_number'
        label='Bank Phone Number'
        placeholder='Enter Bank Phone Number With Country Code'
        apiError={apiError}
      />

      <AddIntermediaryBankForm
        isOpen={isOpenIntermediaryBank}
        onOpen={onOpenIntermediaryBank}
        paymentDetailsInputConfig={paymentDetailsInputConfig}
        apiError={apiError}
      />
    </>
  );
};
