import * as Yup from 'yup';
import { REQUIRED_EMAIL_VALIDATOR, REQUIRED_TEXT_FIELD_VALIDATOR } from '@/helpers/formHelpers/validators';
import { USER_ROLE_MAP, UserRoleType } from '@/const/user';
import { getOpcoUsersUrl } from '@/bundle/_Opco/_OpcoUsersPage/urls/getOpcoUsersUrl';
import { getTransactionsUrl } from '@/bundle/_OrgAdmin/pages/Transactions/urls/getTransactionsUrl';
import { getRecipientWiresUrl } from '@/bundle/_Recipient/urls/getRecipientWiresUrl';
import { getExecutorWiresUrl } from '@/bundle/_Executor/urls/getExecutorWiresUrl';
import { getDepositorWiresUrl } from '@/bundle/_Depositor/urls/getDepositorWiresUrl';

type LoginValidationSchemaType = {
  email: string;
  password: string;
};

export const LOGIN_VALIDATION_SCHEMA: Yup.AnySchema<LoginValidationSchemaType> = Yup.object().shape({
  email: REQUIRED_EMAIL_VALIDATOR,
  password: REQUIRED_TEXT_FIELD_VALIDATOR,
});

export const DASHBOARD_REDIRECT_MAP = {
  [USER_ROLE_MAP.OPCO]: getOpcoUsersUrl(),
  [USER_ROLE_MAP.ORGANIZATION]: getTransactionsUrl(),
  [USER_ROLE_MAP.RECIPIENT]: getRecipientWiresUrl(),
  [USER_ROLE_MAP.EXECUTOR]: getExecutorWiresUrl(),
  [USER_ROLE_MAP.DEPOSITOR]: getDepositorWiresUrl(),
};

export const getDashboardRedirectUrl = (currentRole: UserRoleType) => {
  if (!currentRole) return '';

  return DASHBOARD_REDIRECT_MAP[currentRole];
};
