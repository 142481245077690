import { ChangeEvent } from 'react';
import { RadioGroupBlockWrapper, RadioGroupTitle, RadioGroupWrapper } from './styles';
import { RadioField } from '@/components/form/fields/RadioGroup/RadioField';

export type RadioOptionType = {
  value: string;
  label: string;
};

export type RadioGroupType = {
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  options: RadioOptionType[];
  disabled?: boolean;
};

export const RadioGroup = ({ value, label, options, disabled = false, onChange }: RadioGroupType) => {
  return (
    <RadioGroupWrapper>
      <RadioGroupTitle>{label}</RadioGroupTitle>
      <RadioGroupBlockWrapper>
        {options.map((option, index) => {
          const key = `${index}_${option.label}`;

          return (
            <RadioField
              key={key}
              label={option.label}
              onChange={onChange}
              value={option?.value}
              checked={value === option.value}
              disabled={disabled}
            />
          );
        })}
      </RadioGroupBlockWrapper>
    </RadioGroupWrapper>
  );
};
