import { Table, TableBody, TableCell, TableRow } from '@/components/Table/styles';
import { TableHeader } from '@/components/Table/TableHeader/TableHeader';
import { TableHeaderCell } from '@/components/Table/TableHeaderCell/TableHeaderCell';
import { BILLING_TABLE_CONFIG } from './const';
import { useNavigate } from 'react-router-dom';
import { getBillingPlanDetailsUrl } from '../../Details/url/getBillingPlanDetailsUrl';
import { BillingPlanType } from '@/api/v1/opco/billing/getBillingPlansApi';
import { TableEmptyRow } from '@/components/Table/TableEmptyRow/TableEmptyRow';

type BillingPlanTableType = {
  plans: BillingPlanType[];
  isLoading?: boolean;
};

export const BillingPlansTable = ({ plans = [] }: BillingPlanTableType) => {
  const navigate = useNavigate();

  const hasItems = !!plans.length;

  const navigateToPlanDetails = (id: string) => {
    navigate(getBillingPlanDetailsUrl(id));
  };

  return (
    <>
      <Table>
        <TableHeader>
          {BILLING_TABLE_CONFIG.map(({ key, name, width, header, hasSort }) => (
            <TableHeaderCell key={key} name={name} width={width} hasSort={hasSort && hasItems}>
              {header}
            </TableHeaderCell>
          ))}
        </TableHeader>
        <TableBody>
          {hasItems ? (
            plans.map((item) => (
              <TableRow key={item.id} onClick={() => navigateToPlanDetails(item.id)} isClickableRow>
                {BILLING_TABLE_CONFIG.map(({ key, width, getValue, hasTitle }) => {
                  const value = getValue(item);
                  const title = (hasTitle && (value as string)) || '';

                  return (
                    <TableCell key={key} width={width} title={title}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableEmptyRow columns={BILLING_TABLE_CONFIG} />
          )}
        </TableBody>
      </Table>
    </>
  );
};
