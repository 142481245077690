import { useEffect } from 'react';
import { FormMessage } from '@/bundle/_OrgAdmin/shared/ui/styles';
import { Button } from '@/components/Button/Button';
import { Box } from '@/components/Box/Box';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { UPDATE_USER_MOBILE_PHONE_NOTIFICATION } from '@/const/shared';
import { updateRecipientByProxy } from './api';
import { AssignedWireType } from '@/types/wireTypes';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { FormikPhoneInput } from '@/components/form/fields/FormikPhoneInput/FormikPhoneInput';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { ASSIGN_USER_INITIAL_VALUES, ASSIGN_USER_SCHEMA } from '@/bundle/_OrgAdmin/shared/const/const';

type FormValues = {
  mobile_phone: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
};

type ProxyEditRecipientFormType = {
  wire: AssignedWireType;
  onClose: () => void;
  onSave: () => void;
};

export const ProxyEditRecipientForm = ({ wire, onClose, onSave }: ProxyEditRecipientFormType) => {
  const recipient = wire?.assigned_user;

  const { data, mutate, isPending } = useMutation({
    mutationKey: ['update_recipient_by_proxy'],
    mutationFn: (payload: FormValues) => {
      const userId = wire?.assigned_user?.id;

      return updateRecipientByProxy(wire?.id, userId, payload);
    },
    onSuccess: (updateUserResponse) => {
      if (updateUserResponse?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.OutboundWireProxyEditRecipientSuccess);
      showSnackbar(UPDATE_USER_MOBILE_PHONE_NOTIFICATION, { maxWidth: '375px' });

      onClose();
      onSave();
    },
  });

  const formik = useFormik({
    initialValues: ASSIGN_USER_INITIAL_VALUES,
    validationSchema: ASSIGN_USER_SCHEMA,
    onSubmit: async (values) => {
      const { first_name, middle_name, last_name, mobile_phone } = values;
      const payload = {
        mobile_phone,
        first_name,
        middle_name,
        last_name,
      };

      await mutate(payload as FormValues);
    },
  });

  useEffect(() => {
    formik.setValues({
      ...recipient,
      mobile_phone: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipient]);

  const apiError = data?.error;

  return (
    <DetailsWidget header='Edit Recipient' hasHighlight headerOffset={8}>
      <FormMessage>
        Any change to the recipient info below will be applied to the other wires this pending account is assigned to.
      </FormMessage>
      <FormikForm value={formik}>
        <FormikInput name='email' label='Email Address*' disabled />
        <FormikPhoneInput name='mobile_phone' label='Mobile Phone Number*' apiError={apiError} />
        <FormikInput name='first_name' label='First Name*' placeholder='Enter First Name' apiError={apiError} />
        <FormikInput name='middle_name' label='Middle Name' placeholder='Enter Middle Name' apiError={apiError} />
        <FormikInput name='last_name' label='Last Name*' placeholder='Enter Last Name' apiError={apiError} />

        <Box flexDirection='row' width='100%' justifyContent='end' columnGap='12px' mt='12px'>
          <Button variant='secondary' onClick={onClose} width={172} size='medium'>
            Cancel
          </Button>
          <Button type='submit' isLoading={isPending} width={172} size='medium'>
            Save
          </Button>
        </Box>
      </FormikForm>
    </DetailsWidget>
  );
};
