import { ReactNode } from 'react';
import { Loader } from '../Loader/Loader';
import { ButtonWrapper, ButtonContent, ButtonVariantType } from './styles';

type ButtonType = {
  children?: ReactNode;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  onClick?: (event) => void;
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
  width?: number | string;
  size?: 'large' | 'medium';
  variant?: ButtonVariantType;
  isLoading?: boolean;
  mobileStretch?: boolean;
};

export const Button = ({
  children,
  icon,
  iconPosition,
  onClick,
  width,
  type = 'button',
  disabled,
  size = 'large',
  variant = 'primary',
  isLoading = false,
  mobileStretch = false,
}: ButtonType) => {
  if (isLoading) return <Loader size={size} width={width} mobileStretch={mobileStretch} />;

  return (
    <ButtonWrapper
      type={type}
      width={width}
      onClick={type !== 'submit' ? onClick : undefined}
      disabled={disabled}
      size={size}
      variant={variant}
      mobileStretch={mobileStretch}
    >
      <ButtonContent hasIcon={!!icon}>
        {icon && iconPosition === 'left' && <div>{icon}</div>}
        {children}
        {icon && iconPosition === 'right' && <div>{icon}</div>}
      </ButtonContent>
    </ButtonWrapper>
  );
};
