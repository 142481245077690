import { COLORS } from '@/styles/colors';
import { resetList } from '@/styles/common';
import { flex } from '@/styles/layout';
import { bodySmall } from '@/styles/typography';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { MenuSection } from '../styles';

export const SubMenuHeader = styled(MenuSection)`
  padding-right: 12px;
`;

export const SubMenuSection = styled.ul`
  ${flex({ flexDirection: 'column' })};
  ${resetList};
  gap: 4px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 22px;
    background-color: ${COLORS.grey[850]};
    width: 1px;
    top: 17px;
    bottom: 18px;
  }
`;

export const SubMenuMarker = styled.div`
  height: 100%;

  &::before {
    content: '';
    display: block;
    position: relative;
    width: 5px;
    height: 5px;
    background-color: ${COLORS.grey[700]};
    border-radius: 50%;
    z-index: 1;
  }
`;

export const SubMenu = styled.li`
  ${flex({ flexDirection: 'column' })};
`;

export const SubMenuLink = styled(NavLink)<{ $isActive: boolean }>`
  position: relative;
  ${flex({ alignItems: 'center' })};
  text-decoration: none;
  padding: 10px 20px;
  color: ${COLORS.grey[300]};
  border-radius: 8px;
  column-gap: 20px;
  ${bodySmall};
  background-color: ${({ $isActive }) => ($isActive ? COLORS.grey[900] : 'transparent')};

  &:hover {
    background-color: ${COLORS.grey[950]};
  }
`;
