import { COLORS } from '@/styles/colors';
import { flex, flexCenter } from '@/styles/layout';
import styled from 'styled-components';

export const BillingPlanDetailCardWrapper = styled.div`
  position: relative;
  ${flex({ flexDirection: 'column' })}
  max-width: 664px;
  border-radius: 20px;
  border: 1px solid ${COLORS.grey[400]};
  padding: 28px;
`;

export const BillingPlanDetailsInfoWrapper = styled.div`
  width: 100%;
  ${flex({ justifyContent: 'flex-start' })}
  column-gap: 20px;
  border-radius: 10px;
  border: 1px solid ${COLORS.grey[300]};
  background: ${COLORS.grey[150]};
  padding: 16px;

  & > div:not(:last-child) {
    border-right: 1px solid ${COLORS.grey[300]};
  }
`;

export const BillingPlanDetailMenuButtonWrapper = styled.div`
  ${flex()};
  align-self: start;
  column-gap: 12px;
`;

export const BillingPlanDetailsIconWrapper = styled.div`
  width: 42px;
  height: 42px;
  ${flexCenter};
  background: ${COLORS.grey[200]};
  border-radius: 8px;
  flex-shrink: 0;
`;
