import { authApiService } from '../../authApiService';
import { ApiParams } from '../../helpers/urlApiHelpers';
import { ApiResponseType } from '@/types/sharedTypes';

export type CurrencyType = {
  code: string;
  number: string;
  name: string;
  is_active: boolean;
  country: string;
};

export type CurrencyResponseType = {
  count: number;
  next: string;
  previous: string;
  results: CurrencyType[];
};

export const getCurrencyApi = (params?: ApiParams): Promise<ApiResponseType<CurrencyResponseType>> => {
  const uri = '/reference-books/currencies/';

  return authApiService.GET(uri, params);
};
