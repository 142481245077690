import { useNavigate } from 'react-router-dom';
import { NotFoundLayout, NotFoundHeader, NotFoundSubheader, NotFoundIconWrapper } from './styles';
import { ReactComponent as NotFoundIcon } from './images/404.svg';
import { Button } from '@/components/Button/Button';
import { getLoginUrl } from '@/bundle/Auth/LoginFlow/LoginPage/urls/getLoginUrl';

export const NotFoundPage = () => {
  const navigate = useNavigate();

  const redirectToLogin = () => {
    navigate(getLoginUrl());
  };

  return (
    <NotFoundLayout>
      <NotFoundIconWrapper>
        <NotFoundIcon></NotFoundIcon>
      </NotFoundIconWrapper>
      <NotFoundHeader>Page Not Found</NotFoundHeader>
      <NotFoundSubheader>
        We cannot find the page you are looking for!
        <br />
        Please try again.
      </NotFoundSubheader>
      <Button width={200} onClick={redirectToLogin}>
        Return to Login
      </Button>
    </NotFoundLayout>
  );
};
