import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import { THEME } from '@/styles/theme';
import { bodySmall, poppinsMediumFont } from '@/styles/typography';
import styled from 'styled-components';

export const PopoverAnchorContainer = styled.div``;

export const PopoverMenuWrapper = styled.div`
  padding: 8px 0px;
  border-radius: 12px;
  border: 1px solid ${COLORS.grey[850]};
  background: ${COLORS.white};
  box-shadow: 0px 4px 8px 0px rgba(0, 6, 38, 0.2);
  min-width: 220px;
  z-index: ${THEME.modal.zIndex};
`;

export const PopoverMenuItemWrapper = styled.div`
  ${flex({ alignItems: 'center' })};
  column-gap: 12px;
  padding: 10px 16px;
  cursor: pointer;
  ${bodySmall};
  ${poppinsMediumFont};

  &:hover {
    background: ${COLORS.grey[50]};
  }
`;
