import { authApiService } from '@/api/authApiService';
import { DefaultPaymentMethodType } from '@/types/paymentTypes';
import { ApiResponseType, BaseResponseType } from '@/types/sharedTypes';

export const deletePaymentMethodApi = (
  payload: DefaultPaymentMethodType
): Promise<ApiResponseType<BaseResponseType>> => {
  const uri = '/organization/payments/customer-vault/payment-method/';

  return authApiService.DELETE(uri, payload);
};
