import { WireFilterType } from '@/bundle/_OrgAdmin/pages/Transactions/_Details/const/const';
import { SearchEmailType } from '@/bundle/_OrgAdmin/ui/TransactionsSearchFilters/const';

export interface BillingEventsParamsType {
  wire__contract__name?: string;
  organization__id?: string[];
  date_created_after?: string;
  date_created_before?: string;
}

export interface FilterParamsType extends QueryParamsType, BillingEventsParamsType {}

export interface QueryParamsType {
  page?: number | null;
  limit?: number | null;
  ordering?: string | null;
  filters?: FilterParamsType | null;
  search?: string | null;
  email?: string | null;
  name?: string | null;
  types?: SearchEmailType[];
  type?: WireFilterType;
}

export const DEFAULT_LIMIT = 8;

export const DEFAULT_FILTER_PARAMS: FilterParamsType = {
  wire__contract__name: null,
  organization__id: null,
  date_created_after: null,
  date_created_before: null,
};
