import { ReactComponent as SuccessIcon } from '@/images/circleSuccessIcon.svg';
import { ReactComponent as NeutralIcon } from '@/images/circleNeutralIcon.svg';
import { ReactComponent as RejectIcon } from '@/images/circleRejectIcon.svg';
import { CustomValidationRow, CustomValidationWrapper, MessageBlock } from './styles';
import { validatePassword } from '@/bundle/Auth/const/const';

type BulletValidationType = {
  password: string;
  isTouchedPasswordField: boolean;
};

export const BulletValidation = ({ password, isTouchedPasswordField }: BulletValidationType) => {
  const {
    hasMinMaxLength,
    hasOneLowerCaseCharacter,
    hasOneUpperCaseCharacter,
    hasOneDigit,
    hasSpecialCharacter,
    hasNoSpaces,
    hasLatinCharacters,
  } = validatePassword(password);

  const currentIcon = (isValidRule: boolean) => {
    if (isValidRule === true) return <SuccessIcon />;

    if (isTouchedPasswordField && isValidRule === false) return <RejectIcon />;

    return <NeutralIcon />;
  };

  return (
    <CustomValidationWrapper>
      <CustomValidationRow>
        {currentIcon(hasMinMaxLength)}
        <MessageBlock>From 15 to 30 characters</MessageBlock>
      </CustomValidationRow>
      <CustomValidationRow>
        {currentIcon(hasOneLowerCaseCharacter)}
        <MessageBlock>One lowercase character</MessageBlock>
      </CustomValidationRow>
      <CustomValidationRow>
        {currentIcon(hasOneUpperCaseCharacter)}
        <MessageBlock>One uppercase character</MessageBlock>
      </CustomValidationRow>
      <CustomValidationRow>
        {currentIcon(hasOneDigit)}
        <MessageBlock>One digit</MessageBlock>
      </CustomValidationRow>
      <CustomValidationRow>
        {currentIcon(hasSpecialCharacter)}
        <MessageBlock>One special symbol</MessageBlock>
      </CustomValidationRow>
      <CustomValidationRow>
        {currentIcon(hasNoSpaces)}
        <MessageBlock>No spaces</MessageBlock>
      </CustomValidationRow>
      <CustomValidationRow>
        {currentIcon(hasLatinCharacters)}
        <MessageBlock>Latin letters only</MessageBlock>
      </CustomValidationRow>
    </CustomValidationWrapper>
  );
};
