import { ValueOf } from '@/types/sharedTypes';

export const MODE = {
  CREATE: 'create',
  EDIT: 'edit',
};

export const PAYMENT_TYPE_DB = {
  INTERNATIONAL: 'international',
  DOMESTIC: 'domestic',
} as const;

export const DEPOSIT_ACCOUNT_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'in_active',
};

export const REDIRECT_URL_KEY = 'REDIRECT_URL';
export const CURRENT_ROLE_KEY = 'CURRENT_ROLE';

export const PIN_TOKEN_EVENT_SCOPE = {
  CREATE_OPCO_USER: 'create_opco_user',
  BLOCK_OPCO_USER: 'block_opco_user',
  RESET_OPCO_USER: 'reset_opco_user',
  CREATE_ORG_ADMIN: 'create_org_user',
  EDIT_ORG_ADMIN: 'edit_org_user',
  BLOCK_ORG_ADMIN: 'block_org_user',
  UNBLOCK_ORG_ADMIN: 'unblock_org_user',
  ADD_ORG_USER_ROLE: 'add_org_user_role',
  CREATE_BILLING_PLAN: 'create_payment_plan',
  UPDATE_BILLING_PLAN_STATUS: 'update_payment_plan_status',
} as const;

export type PinTokenEventScopeType = ValueOf<typeof PIN_TOKEN_EVENT_SCOPE>;

export const USER_ASSIGNMENT_STATUS_CODE_MAP = {
  IS_NOT_ALLOWED_ROLE: 'is_not_allowed_role',
  IS_SAME_USER: 'is_same_user',
  IS_NOT_SAME_ROLE_AND_INVITED: 'is_not_same_role_and_invited',
  IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_SAME_ORGANIZATION:
    'is_same_role_and_invited_but_created_by_same_organization',
  IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_ANOTHER_ORGANIZATION:
    'is_same_role_and_invited_but_created_by_another_organization',
  CAN_BE_ASSIGNED: 'can_be_assigned',
} as const;

export type UserAssignmentStatusCodeType = ValueOf<typeof USER_ASSIGNMENT_STATUS_CODE_MAP>;

export const UPDATE_USER_MOBILE_PHONE_NOTIFICATION = (
  <span>
    The phone number associated with the invitation has been updated. <br /> The initial email invitation is still
    valid.
  </span>
);

export const UPDATE_USER_ROLE_NOTIFICATION = <span>The role of organization user has been updated.</span>;

export const TOKEN_REFRESHED_EVENT = 'TOKEN_REFRESHED';

// We support only 'card' type for now, in future we will add support for 'ach' type
export const PAYMENT_METHOD_TYPE = 'card';
