import { caption, poppinsSemiBoldFont } from '@/styles/typography';
import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { TableHeaderVariantType } from '@/components/Table/TableHeader/TableHeader';
import { TextAlignType } from '@/components/Table/TableHeaderCell/TableHeaderCell';

export const TableHeaderCellContainer = styled.th<{
  width: string;
  hasSort: boolean;
  variant: TableHeaderVariantType;
  textAlign: TextAlignType;
}>`
  padding: 16px 12px;
  width: ${({ width }) => (width ? width : '100%')};
  ${poppinsSemiBoldFont};
  ${caption};
  letter-spacing: 0.24px;
  vertical-align: top;
  line-height: 20px;
  text-align: ${({ textAlign }) => textAlign};

  &:first-child {
    border-top-left-radius: 8px;

    ${({ variant }) =>
      variant === 'secondary' &&
      `
        border-left: 1px solid ${COLORS.grey[300]}
    `};
  }

  &:last-child {
    border-top-right-radius: 8px;

    ${({ variant }) =>
      variant === 'secondary' &&
      `
        border-right: 1px solid ${COLORS.grey[300]}
    `};
  }

  ${({ hasSort }) =>
    hasSort && {
      cursor: 'pointer',
    }};

  ${({ variant }) =>
    variant === 'secondary' &&
    `
      border-top: 1px solid ${COLORS.grey[300]}
  `};
`;
