import { formatPayloadDate } from '@/helpers/dateHelpers/dateHelpers';
import { formatNotes } from '@/helpers/formatHelpers';

export const getEditWirePayload = (values) => {
  const { expected_payment_date, notes } = values;

  const wirePayload = {
    ...values,
    ...(expected_payment_date && { expected_payment_date: formatPayloadDate(expected_payment_date) }),
    ...(notes && { notes: formatNotes(notes) }),
  };

  // Backend requires empty date send as null
  if (wirePayload.expected_payment_date === '') {
    wirePayload.expected_payment_date = null;
  }

  return wirePayload;
};
