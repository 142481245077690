import { Tooltip } from '@/components/Tooltip/Tooltip';
import { ReactComponent as EditIcon } from '@/images/EditIcon.svg';
import { ReactComponent as DeleteIcon } from '@/images/DeleteIcon.svg';
import { TableIconButtonWrapper } from './styles';
import { useId } from 'react';

type TableIconButtonType = {
  label: string;
  type?: 'edit' | 'delete';
  to?: string;
  preventDefault?: boolean;
  onClick?: () => void;
};

const ICON_MAP = {
  edit: EditIcon,
  delete: DeleteIcon,
};

export const TableIconButton = ({ to = null, type = 'edit', label, preventDefault, onClick }: TableIconButtonType) => {
  const Icon = ICON_MAP[type] || ICON_MAP.edit;
  const tooltipId = `${label}-${useId()}`;

  const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();

    if (preventDefault) {
      event.preventDefault();
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <Tooltip id={tooltipId}>{label}</Tooltip>
      <TableIconButtonWrapper to={to} data-tooltip-id={tooltipId} onClick={handleClick}>
        <Icon />
      </TableIconButtonWrapper>
    </>
  );
};
