import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import { AssignRecipientFormHeader, AssignRecipientFormWrapper } from '../../../styles';
import { FormMessage } from '../../../../../../shared/ui/styles';
import {
  EVENTS_RECIPIENT_ACTION_TYPE,
  ERROR_ASSIGN_RECIPIENT_AMPLITUDE_EVENTS_MAP,
  ASSIGN_RECIPIENT_INITIAL_VALUES,
  ASSIGN_RECIPIENT_PROXY_OPTIONS,
  AssignRecipientFormType,
} from '../../../const/const';
import { assignRecipient, createRecipient, recipientAssignmentStatus } from '../../../api/recipientApi';
import { Box } from '@/components/Box/Box';
import { Maybe } from '@/types/sharedTypes';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { AutocompleteOptionType } from '@/components/Autocomplete/types';
import { AssignUserAutocomplete } from '@/bundle/shared/components/AssignUserAutocomplete';
import { USER_ROLE_MAP } from '@/const/user';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Button } from '@/components/Button/Button';
import { loadUserInfo } from '@/bundle/shared/api';
import { useAssignUser } from '@/bundle/shared/hooks/useAssignUser';
import { ASSIGN_USER_SCHEMA } from '@/bundle/_OrgAdmin/shared/const/const';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { FormikPhoneInput } from '@/components/form/fields/FormikPhoneInput/FormikPhoneInput';
import { FormikSelectableTabs } from '@/components/form/fields/FormikSelectableTabs/FormikSelectableTabs';
import { FormikSwitchToggle } from '@/components/form/fields/FormikSwitchToggle/FormikSwitchToggle';
import { Notification } from '@/components/Notification/Notification';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { getIsAvailableToAssign } from '@/helpers/userHelpers';

type AssignRecipientFormPropsType = {
  allowedIdVerification?: boolean;
  onCreateRecipient: () => void;
  onAssignRecipient: () => void;
};

const getAmplitudeResponseType = (isProxy: boolean) => {
  return isProxy ? 'proxy' : 'recipient';
};

export const AssignRecipientForm = ({
  allowedIdVerification,
  onCreateRecipient,
  onAssignRecipient,
}: AssignRecipientFormPropsType) => {
  const { id } = useParams<{ id: string }>();
  const [selectedRecipientOption, setSelectedRecipientOption] = useState<Maybe<AutocompleteOptionType>>(null);
  const recipientId = selectedRecipientOption?.value;

  const { data: assignmentStatusData } = useQuery({
    queryKey: ['load_assignment_status', recipientId],
    queryFn: () => recipientAssignmentStatus(recipientId),
    enabled: !!selectedRecipientOption?.isSelectedOption,
  });

  const { data: loadUserData } = useQuery({
    queryKey: ['load_user', recipientId],
    queryFn: () => loadUserInfo(recipientId),
    enabled: !!selectedRecipientOption?.isSelectedOption && !!assignmentStatusData?.body?.is_available_to_assign,
  });

  const {
    mutate: createRecipientMutate,
    isPending: isPendingCreateRecipient,
    data: createRecipientData,
    reset: resetCreateRecipient,
  } = useMutation({
    mutationKey: ['create_recipient'],
    mutationFn: (values: AssignRecipientFormType) => {
      const payload = {
        ...values,
        is_pd_provider_kyc_required: !!values.is_pd_provider_kyc_required,
      };

      return createRecipient(id, payload);
    },
    onSuccess: (createdRecipientResponse, variables) => {
      if (createdRecipientResponse?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.AssignNewRecipient, {
        responseType: getAmplitudeResponseType(variables.is_proxy),
      });
      onCreateRecipient();
    },
  });

  const {
    mutate: assignRecipientMutate,
    isPending: isPendingAssignRecipient,
    data: assignRecipientData,
    reset: resetAssignRecipient,
  } = useMutation({
    mutationKey: ['assign_recipient'],
    mutationFn: (values: AssignRecipientFormType) => {
      const userData = loadUserData?.body;

      const payload = {
        user_id: userData?.id,
        is_proxy: values.is_proxy,
        is_pd_provider_kyc_required: !!values.is_pd_provider_kyc_required,
      };

      return assignRecipient(id, payload);
    },
    onSuccess: (assignedRecipientResponse, variables) => {
      if (assignedRecipientResponse?.error) return;

      const eventType = EVENTS_RECIPIENT_ACTION_TYPE[assignmentStatusData?.body?.code];

      if (eventType) {
        amplitudeService.logEvent(eventType, { responseType: getAmplitudeResponseType(variables.is_proxy) });
      }

      onAssignRecipient();
    },
  });

  useEffect(() => {
    const assignmentStatusBody = assignmentStatusData?.body;

    if (assignmentStatusBody && !assignmentStatusBody?.is_available_to_assign) {
      const eventType = ERROR_ASSIGN_RECIPIENT_AMPLITUDE_EVENTS_MAP[assignmentStatusBody?.code];

      if (eventType) {
        amplitudeService.logEvent(ERROR_ASSIGN_RECIPIENT_AMPLITUDE_EVENTS_MAP[assignmentStatusBody?.code]);
      }
    }
  }, [assignmentStatusData?.body]);

  const isAvailableToAssign = getIsAvailableToAssign(assignmentStatusData?.body?.is_available_to_assign);

  const user = loadUserData?.body;

  const isDisabledForm = !!user || (selectedRecipientOption?.isSelectedOption && !isAvailableToAssign);

  const formik = useFormik<AssignRecipientFormType>({
    initialValues: ASSIGN_RECIPIENT_INITIAL_VALUES,
    validationSchema: isDisabledForm ? null : ASSIGN_USER_SCHEMA,
    onSubmit: (values) => {
      if (selectedRecipientOption?.isSelectedOption) {
        assignRecipientMutate(values);

        return;
      }

      createRecipientMutate(values);
    },
  });

  const onResetData = () => {
    resetCreateRecipient();
    resetAssignRecipient();
  };

  useAssignUser(
    loadUserData?.body,
    formik,
    onResetData,
    selectedRecipientOption,
    assignmentStatusData?.body?.is_available_to_assign
  );

  const assignmentStatus = assignmentStatusData?.body?.code;
  const createRecipientError = createRecipientData?.error;
  const assignRecipientError = assignRecipientData?.error;
  const apiError = createRecipientError || assignRecipientError;
  const isLoading = isPendingCreateRecipient || isPendingAssignRecipient;
  const userIsNotAvailableToAssign = selectedRecipientOption?.isSelectedOption && !isAvailableToAssign;
  const formError = getResponseError(apiError);

  // Note: set “id verification” switch toggle when allowedIdVerification updating from user context
  useEffect(() => {
    if (!allowedIdVerification) {
      formik.setFieldValue('is_pd_provider_kyc_required', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedIdVerification]);

  return (
    <AssignRecipientFormWrapper>
      <AssignRecipientFormHeader>Assign Recipient</AssignRecipientFormHeader>
      <FormMessage>Please indicate who will be receiving this wire payment.</FormMessage>
      {formError && (
        <Notification variant='error' mb='24px'>
          {formError}
        </Notification>
      )}
      <FormikForm value={formik}>
        <AssignUserAutocomplete
          role={USER_ROLE_MAP.RECIPIENT}
          selectedOption={selectedRecipientOption}
          assignmentStatus={assignmentStatus}
          onSelect={setSelectedRecipientOption}
          error={formik.errors?.email}
          apiError={apiError}
        />
        <FormikPhoneInput
          name='mobile_phone'
          label='Mobile Phone Number*'
          disabled={isDisabledForm}
          apiError={apiError}
        />
        <FormikInput
          name='first_name'
          label='First Name*'
          placeholder='Enter First Name'
          disabled={isDisabledForm}
          apiError={apiError}
        />
        <FormikInput
          name='middle_name'
          label='Middle Name'
          placeholder='Enter Middle Name'
          disabled={isDisabledForm}
          apiError={apiError}
        />
        <FormikInput
          name='last_name'
          label='Last Name*'
          placeholder='Enter Last Name'
          disabled={isDisabledForm}
          apiError={apiError}
        />

        <Box mb='22px'>
          <FormikSelectableTabs
            label='The wire info will be provided by:'
            name='is_proxy'
            options={ASSIGN_RECIPIENT_PROXY_OPTIONS}
            disabled={userIsNotAvailableToAssign}
          />
        </Box>

        {allowedIdVerification && (
          <FormikSwitchToggle
            name='is_pd_provider_kyc_required'
            label='ID verification required'
            size='small'
            disabled={userIsNotAvailableToAssign}
          />
        )}

        <Box alignItems='center' justifyContent='flex-end' mt='14px'>
          <Button
            width={172}
            size='medium'
            type='submit'
            disabled={!isAvailableToAssign}
            mobileStretch
            isLoading={isLoading}
          >
            Assign
          </Button>
        </Box>
      </FormikForm>
    </AssignRecipientFormWrapper>
  );
};
