import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { COLORS } from '@/styles/colors';
import { flexCenter } from '@/styles/layout';

export const TableIconButtonWrapper = styled(NavLink)`
  ${flexCenter};
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${COLORS.grey[300]};
  background-color: ${COLORS.white};

  &:hover {
    path {
      fill: ${COLORS.green[500]};
    }
  }
`;
