import { SearchUserType } from '@/api/v1/organization/users/getSearchUsersApi';
import { trimString } from './stringHelpers';
import { isNullOrUndefined } from './objectHelpers';
import { OptionType } from '@/components/form/fields/Select/Select';
import { REPLACE_MULTI_LINE_BREAKS } from '@/const/regExpPatterns';
import { CurrencyType } from '@/api/v1/reference-books/getCurrencyApi';

const LOCALE_DB = {
  en: 'en-US',
};

const MINIMUM_FRACTION_DIGIT = {
  en: 2,
};

export const formatBlockchainId = (blockchainId: string): string => {
  if (!blockchainId) return '-';

  return trimString(`0x${blockchainId}`);
};

export const formatAmount = (amount, locale = 'en', currency = 'USD') => {
  if (!amount) return '-';

  const currentLocale = LOCALE_DB[locale];
  const currentMinimumFractionDigits = MINIMUM_FRACTION_DIGIT[locale];

  const formattedAmount = new Intl.NumberFormat(currentLocale, {
    minimumFractionDigits: currentMinimumFractionDigits,
  }).format(amount);

  return `${formattedAmount} ${currency}`;
};

export const formatNotes = (text: string) => {
  return text.replace(REPLACE_MULTI_LINE_BREAKS, '\n\n');
};

export const formatPhone = (phone: string = '') => {
  if (!phone) return '-';

  return `+${phone}`;
};

export const convertCentsToDollars = (cents: number) => {
  if (isNullOrUndefined(cents)) return;

  return (cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

export const convertDollarsToCents = (dollars: string) => {
  const amount = parseFloat(dollars);

  if (isNaN(amount)) {
    return '';
  }

  return Math.round(amount * 100).toString();
};

export const getCurrencyOptions = (currency: CurrencyType[] = []) => {
  return currency.map(({ code }) => {
    return { value: code, label: code };
  });
};

export const getAutocompleteOptions = (users: SearchUserType[] = []) => {
  return users.map(({ id, email }) => {
    return { value: id, label: email };
  });
};

export const getCurrencyOption = (currency: string, currencyOptions: OptionType[]) => {
  if (!currency || !currencyOptions) return;

  return currencyOptions?.find((currencyOption) => currencyOption?.value === currency);
};
