import { EditWireDetails } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Edit/ui/EditWireDetails/EditWireDetails';
import { OutboundWireEditForm } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Edit/OutboundWireEditForm';
import { AssignedWireType } from '@/types/wireTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateOutboundWire } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Edit/api';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { useParams } from 'react-router-dom';
import { ResponseErrorType } from '@/types/sharedTypes';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { OrgAdminWireDetails } from '@/bundle/_OrgAdmin/shared/ui/OrgAdminWireDetails/OrgAdminWireDetails';
import { OptionType } from '@/components/form/fields/Select/Select';

interface OutboundWireGeneralDetailsType {
  wire: AssignedWireType;
  wireApiError: ResponseErrorType;
  isEdit: boolean;
  currencyOptions: OptionType[];
  onUpdateWireDetails: () => void;
  onEditWireDetails: () => void;
}

export const OutboundWireGeneralDetails = ({
  wire,
  isEdit,
  currencyOptions,
  onUpdateWireDetails,
  onEditWireDetails,
  wireApiError,
}: OutboundWireGeneralDetailsType) => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const wireStatus = wire?.status;

  const {
    mutate: updateWireDetails,
    isPending,
    reset: resetUpdateWireDetails,
  } = useMutation({
    mutationKey: ['update_wire_details'],
    mutationFn: (payload) => updateOutboundWire(id, payload),
    onSuccess: (response) => {
      if (response?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.EditGeneralInfoOutboundWireSuccess);

      queryClient.setQueryData(['load_outbound_wire', id], response);
    },
    onSettled: () => {
      onUpdateWireDetails();
    },
  });

  return (
    <>
      {isEdit ? (
        <DetailsWidget header='Edit Wire' hasHighlight>
          <EditWireDetails status={wireStatus} dateUpdate={wire?.date_modified} />
          <OutboundWireEditForm
            onUpdateWireDetails={onUpdateWireDetails}
            currencyOptions={currencyOptions}
            outboundWire={wire}
            apiError={wireApiError}
            isLoading={isPending}
            onReset={resetUpdateWireDetails}
            onEdit={updateWireDetails}
          />
        </DetailsWidget>
      ) : (
        wire && <OrgAdminWireDetails wire={wire} onEdit={onEditWireDetails} />
      )}
    </>
  );
};
