import { authApiService } from '@/api/authApiService';
import { CustomerVaultType, DefaultBillingAddressType } from '@/types/paymentTypes';
import { ApiResponseType } from '@/types/sharedTypes';

export const editBillingAddressApi = async (
  addressId: string,
  payload: DefaultBillingAddressType
): Promise<ApiResponseType<CustomerVaultType>> => {
  const uri = `/organization/payments/customer-vault/address/${addressId}/`;

  return authApiService.PATCH(uri, payload);
};
