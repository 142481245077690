import { useState } from 'react';
import { ReactComponent as EyeInputActive } from './images/EyeInputActive.svg';
import { ReactComponent as EyeInput } from './images/EyeInput.svg';
import { Input, InputType } from '@/components/form/fields/Input/Input';
import { Box } from '@/components/Box/Box';

export type InputPasswordType = InputType & { hasToggleEye?: boolean };

export const InputPassword = ({ hasToggleEye, ...rest }: InputPasswordType) => {
  const [showPassword, setShowPassword] = useState(false);

  const changePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const type = showPassword ? 'text' : 'password';

  return (
    <Input
      type={type}
      after={
        hasToggleEye ? (
          <Box ml='10px' focusable onClick={changePasswordVisibility}>
            {showPassword ? <EyeInputActive /> : <EyeInput />}
          </Box>
        ) : null
      }
      {...rest}
    />
  );
};
