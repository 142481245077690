import { Table, TableBody, TableCell, TableRow } from '@/components/Table/styles';
import { TableType } from '@/components/Table/types/types';
import { BILLED_EVENTS_TABLE_CONFIG } from './const/const';
import { TableHeaderCell } from '@/components/Table/TableHeaderCell/TableHeaderCell';
import { TableEmptyRow } from '@/components/Table/TableEmptyRow/TableEmptyRow';
import { UtcTooltip } from '../../ui/UtcTooltip/UtcTooltip';
import { TableHeader } from '@/components/Table/TableHeader/TableHeader';
import { BilledEventsListType } from './helpers/helpers';

export const BilledEventsTable = ({ items = [] }: TableType<BilledEventsListType>) => {
  const hasItems = !!items.length;

  return (
    <Table>
      <TableHeader>
        {BILLED_EVENTS_TABLE_CONFIG.map(({ key, name, width, header, hasSort, hasTooltip }) => {
          return (
            <TableHeaderCell key={key} name={name} width={width} hasSort={hasSort && hasItems}>
              {hasTooltip && <UtcTooltip message='Date of View is recorded in UTC.' />}
              {header}
            </TableHeaderCell>
          );
        })}
      </TableHeader>
      <TableBody>
        {hasItems ? (
          items.map((item: BilledEventsListType) => {
            return (
              <TableRow key={item.id}>
                {BILLED_EVENTS_TABLE_CONFIG.map(({ key, width, getValue }) => {
                  return (
                    <TableCell key={key} width={width}>
                      {getValue(item)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
        ) : (
          <TableEmptyRow columns={BILLED_EVENTS_TABLE_CONFIG} />
        )}
      </TableBody>
    </Table>
  );
};
