import { ResponseBackendErrorContentType, ResponseErrorContentType, ResponseErrorType } from '@/types/sharedTypes';
import { RequiredAuthError } from '@/bundle/Error/classes/RequiredAuthError';
import { BlockedUserError } from '@/bundle/Error/classes/BlockedUserError';
import camelCase from 'lodash/camelCase';
import { AccessDeniedError } from '@/bundle/Error/classes/AccessDeniedError';
import { ResponseError } from '@/bundle/Error/classes/ResponseError';
import { TEXT_HTML_CONTENT_TYPE } from '@/api/const';

export type FormattedResponseType<T> = {
  body?: T;
  headers?: {
    [key: string]: string;
  };
  error?: ResponseErrorType;
};

export type ResponseType<T> = {
  json?: T;
  response?: Response;
};

const getHeaders = (response: Response, headers: string[] = []) => {
  return headers.reduce((result, header) => {
    const headerValue = response?.headers.get(header);
    const headerName = camelCase(header);

    result[headerName] = headerValue;

    return result;
  }, {});
};

const getErrorDetails = <T>(response: ResponseType<T>) => {
  const responseContentType = response.response.headers?.get('Content-Type');
  const errorDetails = response.json as ResponseBackendErrorContentType;

  // Note: Unexpected error happened on backend if we get "text/html" content type - fallback to default error
  const isTextHTMLContentType = responseContentType === TEXT_HTML_CONTENT_TYPE;
  const errorContent: ResponseErrorContentType = isTextHTMLContentType
    ? new ResponseError().message
    : errorDetails?.error_message || errorDetails?.error_details;
  const errorCode = isTextHTMLContentType ? null : errorDetails?.error_code;
  const errorType = isTextHTMLContentType ? 'UnexpectedError' : errorDetails?.error_type;

  return {
    errorContent,
    errorCode,
    errorType,
  };
};

export const prepareResponse = <T>(response: ResponseType<T>, headers: string[] = []): FormattedResponseType<T> => {
  const headersData = getHeaders(response?.response, headers);

  if (!response?.response.ok) {
    const errorDetails = getErrorDetails(response);

    const errorResponse = {
      body: null,
      error: {
        ...errorDetails,
        statusCode: response.response.status,
      },
      headers: { ...headersData },
    };

    return errorResponse;
  }

  return { body: response?.json, error: null, headers: { ...headersData } };
};

export const throwResponseError = (error: Error) => {
  // Rethrow original errors, since we handle them separately in <ErrorBoundary> component
  if (error instanceof RequiredAuthError) {
    throw error;
  }

  if (error instanceof BlockedUserError) {
    throw error;
  }

  if (error instanceof AccessDeniedError) {
    throw error;
  }

  throw new Error(error.message);
};

export const getResponseError = (error: ResponseErrorType, errorKey?: string): string => {
  if (!error) return;

  if (typeof error.errorContent === 'string') {
    return error.errorContent;
  }

  const errorByKey = error.errorContent?.[errorKey];

  if (Array.isArray(errorByKey)) {
    return errorByKey[0];
  }

  return errorByKey;
};
