import { formatDate } from '@/helpers/dateHelpers/dateHelpers';
import { WireStatus } from '../../../../../../shared/components/WireStatus/WireStatus';
import { WireEditInfoItem, WireEditInfoLabel, WireEditInfoValue, WireEditInfoWrapper } from './styles';
import { WireStatusType } from '@/types/wireTypes';

type EditWireDetailsType = {
  status: WireStatusType;
  dateUpdate: string;
};

export const EditWireDetails = ({ status, dateUpdate }: EditWireDetailsType) => (
  <WireEditInfoWrapper>
    <WireEditInfoItem>
      <WireEditInfoLabel>Status</WireEditInfoLabel>
      <WireStatus status={status} />
    </WireEditInfoItem>
    <WireEditInfoItem>
      <WireEditInfoLabel>Last Update</WireEditInfoLabel>
      <WireEditInfoValue>{formatDate(dateUpdate)}</WireEditInfoValue>
    </WireEditInfoItem>
  </WireEditInfoWrapper>
);
