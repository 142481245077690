import { ReactNode } from 'react';
import { TableHeaderContainer } from './styles';
import { TableRow } from '../styles';

export type TableHeaderVariantType = 'primary' | 'secondary';

type TableHeaderType = {
  variant?: TableHeaderVariantType;
  children: ReactNode;
};

export const TableHeader = ({ variant = 'primary', children }: TableHeaderType) => {
  return (
    <TableHeaderContainer variant={variant}>
      <TableRow>{children}</TableRow>
    </TableHeaderContainer>
  );
};
