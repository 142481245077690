import { authApiService } from '@/api/authApiService';
import { DefaultPaymentMethodType } from '@/types/paymentTypes';
import { ApiResponseType } from '@/types/sharedTypes';

// TODO: add types later
export const setDefaultPaymentMethodApi = (payload: DefaultPaymentMethodType): Promise<ApiResponseType<any>> => {
  const uri = '/organization/payments/customer-vault/payment-method/set-default/';

  return authApiService.POST(uri, payload);
};
