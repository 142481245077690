import { useFormik } from 'formik';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { FormValues, getChangedFormValues } from '@/helpers/formHelpers/formHelpers';
import { useEffect, useState } from 'react';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { useMutation } from '@tanstack/react-query';
import { updateSelfUserDetails } from '@/bundle/pages/ProfilePage/api';
import { PersonalDetailsType } from '@/bundle/shared/components/UserDetails/UserPersonalInfo';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { PERSONAL_DETAILS_INITIAL_VALUES, PERSONAL_DETAILS_VALIDATION_SCHEMA } from '@/const/formConfigs';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { useUser } from '@/context/userContext';

interface EditPersonalDetailsFormType {
  onClose: () => void;
  isOrgAdmin: boolean;
  personalDetails: PersonalDetailsType;
}

export const EditPersonalDetailsForm = ({ onClose, isOrgAdmin, personalDetails }: EditPersonalDetailsFormType) => {
  const { refetchUser } = useUser();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const closeModal = () => setIsOpenModal(false);
  const openModal = () => setIsOpenModal(true);

  const { data, mutate, isPending } = useMutation({
    mutationKey: ['edit_user_general_info'],
    mutationFn: (values: FormValues) => {
      return updateSelfUserDetails(values);
    },
    onSuccess: (updateSelfUserResponse) => {
      if (updateSelfUserResponse?.error) {
        closeModal();

        return;
      }

      amplitudeService.logEvent(AMPLITUDE_EVENTS.ProfileEditPersonalInfoSuccess);

      refetchUser();
      closeModal();
      onClose();
    },
  });

  const formik = useFormik({
    initialValues: PERSONAL_DETAILS_INITIAL_VALUES,
    validationSchema: PERSONAL_DETAILS_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      const payload = getChangedFormValues<FormValues>(values, personalDetails as FormValues);

      if (!payload) {
        onClose();

        return;
      }

      if (isOrgAdmin) {
        mutate(payload);

        return;
      }

      amplitudeService.logEvent(AMPLITUDE_EVENTS.ProfileEditPersonalInfoConfirm);
      openModal();
    },
  });

  const onConfirm = () => {
    const payload = getChangedFormValues<FormValues>(formik.values, personalDetails as FormValues);

    if (!payload) {
      closeModal();
      onClose();

      return;
    }

    mutate(payload);
  };

  useEffect(() => {
    if (!personalDetails) {
      return;
    }

    formik.setValues({
      first_name: personalDetails.first_name,
      middle_name: personalDetails.middle_name,
      last_name: personalDetails.last_name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetails]);

  const apiError = data?.error;

  return (
    <>
      <Box flexDirection='column' rowGap='14px'>
        <FormikForm value={formik}>
          <FormikInput name='first_name' label='First Name*' placeholder='Enter First Name' apiError={apiError} />
          <FormikInput name='middle_name' label='Middle Name' placeholder='Enter Middle Name' apiError={apiError} />
          <FormikInput name='last_name' label='Last Name*' placeholder='Enter Last Name' apiError={apiError} />

          <Box display='flex' columnGap='20px' mt='14px'>
            <Button variant='secondary' onClick={onClose}>
              Cancel
            </Button>
            <Button type='submit'>Save</Button>
          </Box>
        </FormikForm>
      </Box>
      <ConfirmModal
        isOpen={isOpenModal}
        header='Edit Personal Info'
        body='Your information will be updated in all wires assigned to you.'
        onClose={closeModal}
        onConfirm={onConfirm}
        isLoading={isPending}
      />
    </>
  );
};
