import { ValueOf } from '@/types/sharedTypes';

export const USER_ROLE_MAP = {
  OPCO: 'opco_user',
  ORGANIZATION: 'organization_user',
  RECIPIENT: 'recipient_user',
  EXECUTOR: 'executor',
  DEPOSITOR: 'depositor',
} as const;

export type UserRoleType = ValueOf<typeof USER_ROLE_MAP>;

export type ActiveUserRolesType = UserRoleType[];

export type UserRolesStatusType = {
  [key in UserRoleType]?: {
    subrole: string;
    status: 'active' | 'blocked';
  };
};

export const ORG_ADMIN_ROLE = {
  SITE_ADMIN: 'site_admin',
  ORG_ADMIN: 'org_admin',
} as const;

export type OrgAdminRoleType = ValueOf<typeof ORG_ADMIN_ROLE>;

export const USER_ROLE_LABEL_MAP = {
  [USER_ROLE_MAP.OPCO]: 'OpCo',
  [USER_ROLE_MAP.RECIPIENT]: 'Recipient',
  [USER_ROLE_MAP.EXECUTOR]: 'Executor',
  [USER_ROLE_MAP.DEPOSITOR]: 'Depositor',
};
