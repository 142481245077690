import { Checkbox } from '@/components/form/fields/Checkbox/Checkbox';
import { TransactionsFiltersRow } from '../styles';
import { Tooltip } from '@/components/Tooltip/Tooltip';

type TransactionsSearchCheckboxType = {
  checked: boolean;
  label: string;
  isLastChecked: boolean;
  onChange: (checked: boolean) => void;
};

export const TransactionsSearchCheckbox = ({
  checked,
  label,
  isLastChecked,
  onChange,
}: TransactionsSearchCheckboxType) => {
  const tooltipId = `${label}_tooltip`;

  return (
    <TransactionsFiltersRow data-tooltip-id={tooltipId} checked={checked}>
      {isLastChecked && <Tooltip id={tooltipId}>At least one type should be selected</Tooltip>}
      <Checkbox disabled={isLastChecked} checked={checked} onChange={() => onChange(!checked)} label={label} />
    </TransactionsFiltersRow>
  );
};
