import { STATUS_MAP, WIRE_STATUS_TEXT_BY_USER_TYPE } from './const';
import { useUser } from '@/context/userContext';
import { UserRoleType } from '@/const/user';
import { WireStatusType } from '@/types/wireTypes';
import { StatusWrapper } from '@/components/StatusWrapper/StatusWrapper';

type WireStatusPropsType = {
  status: WireStatusType;
};

const getStatusText = (status: WireStatusType, userRole: UserRoleType) => {
  if (!userRole) {
    return '-';
  }

  const roleWireStatus: { [key: string]: string } = WIRE_STATUS_TEXT_BY_USER_TYPE[userRole];

  return roleWireStatus[status];
};

export const WireStatus = ({ status }: WireStatusPropsType) => {
  const { currentRole } = useUser();
  const statusText = getStatusText(status, currentRole);

  const statusVariant = STATUS_MAP[status]?.variant;
  const statusIcon = STATUS_MAP[status]?.statusIcon;

  return (
    <StatusWrapper variant={statusVariant}>
      {statusIcon}
      {statusText}
    </StatusWrapper>
  );
};
