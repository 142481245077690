import { BilledEventsFiltersType } from '../../../hooks/useBilledEventsFilters/useBilledEventsFilters';
import { formatPayloadDate, DEFAULT_DATE_FORMAT } from '@/helpers/dateHelpers/dateHelpers';
import { ValueOf } from '@/types/sharedTypes';

export const CHIP_TYPE_MAP = {
  date: 'date',
  organization: 'organization',
  transaction: 'transaction',
};

export type FiltersChipType = {
  id: string;
  label: string;
  type: ValueOf<typeof CHIP_TYPE_MAP>;
  removable?: boolean;
};

export const getChipsFromFilters = (filters: BilledEventsFiltersType): FiltersChipType[] => {
  const { dateStart, dateEnd, ...restFilterValues } = filters;
  let chipsList = [];

  const dateChip = {
    id: CHIP_TYPE_MAP.date,
    label: `${formatPayloadDate(dateStart, DEFAULT_DATE_FORMAT)} - ${formatPayloadDate(dateEnd, DEFAULT_DATE_FORMAT)}`,
    type: CHIP_TYPE_MAP.date,
    removable: false,
  };

  chipsList = [dateChip];

  if (restFilterValues.organizations) {
    const oranizationsChips = restFilterValues.organizations.map((item) => ({
      id: item.value,
      label: item.label,
      type: CHIP_TYPE_MAP.organization,
      removable: true,
    }));

    chipsList = [...chipsList, ...oranizationsChips];
  }

  if (restFilterValues.transactionName) {
    const transactionNameChip = {
      id: restFilterValues.transactionName,
      label: restFilterValues.transactionName,
      type: CHIP_TYPE_MAP.transaction,
      removable: true,
    };

    chipsList = [...chipsList, transactionNameChip];
  }

  return chipsList;
};
