import { SwitchToggle, SwitchToggleType } from '@/components/form/fields/SwitchToggle/SwitchToggle';
import { useField } from 'formik';
import { ChangeEvent } from 'react';
import { getFieldError } from '@/helpers/formHelpers/formHelpers';
import { ResponseErrorType } from '@/types/sharedTypes';

type FormikSwitchToggleType = Omit<SwitchToggleType, 'apiError' | 'checked' | 'onChange'> & {
  apiError?: ResponseErrorType;
};

export const FormikSwitchToggle = ({ name, apiError, ...rest }: FormikSwitchToggleType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, { setValue }] = useField(name);

  const { error, apiError: apiErrorMessage } = getFieldError(name, meta, apiError);

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;

    setValue(checked);
  };

  return (
    <SwitchToggle
      {...field}
      checked={field.value}
      onChange={handleToggle}
      error={error}
      apiError={apiErrorMessage}
      {...rest}
    />
  );
};
