import { STATUS_VARIANT_HIGHLIGHT } from '@/components/StatusWrapper/const';
import { StatusWrapper } from '@/components/StatusWrapper/StatusWrapper';
import { PAYMENT_STATUS_MAP } from '@/const/payment';
import { ValueOf } from '@/types/sharedTypes';

export const PAYMENT_STATUS_LABEL_MAP = {
  [PAYMENT_STATUS_MAP.PAID]: 'Paid',
} as const;

export const PAYMENT_STATUS_HIGHLIGHT_MAP = {
  [PAYMENT_STATUS_MAP.PAID]: { variant: STATUS_VARIANT_HIGHLIGHT.GREEN },
} as const;

type PaymentStatusType = ValueOf<typeof PAYMENT_STATUS_MAP>;

type PaymentStatusBadgeType = {
  status: PaymentStatusType;
};

export const BillingStatusBadge = ({ status }: PaymentStatusBadgeType) => {
  const statusText = PAYMENT_STATUS_LABEL_MAP[status] || '-';
  const statusVariant = PAYMENT_STATUS_HIGHLIGHT_MAP[status]?.variant;

  return <StatusWrapper variant={statusVariant}>{statusText}</StatusWrapper>;
};
