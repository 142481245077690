import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { ResponseErrorType } from '@/types/sharedTypes';
import { Typography } from '@/components/Typography/Typography';
import { FormikTextArea } from '@/components/form/fields/FormikTextArea/FormikTextArea';
import { Box } from '@/components/Box/Box';
import { COLORS } from '@/styles/colors';
import { FormikSwitchToggle } from '@/components/form/fields/FormikSwitchToggle/FormikSwitchToggle';
import { useFormikContext } from 'formik';
import { BillingPlanFormType } from '../../const';
import { FormikPriceInput } from '@/components/form/fields/FormikPriceInput/FormikPriceInput';
import { ERROR_MESSAGE_HEIGHT } from '@/components/form/fields/InputError/styles';
import { DrawerHeaderBackLine } from '@/bundle/_Opco/_BillingPage/_BillingPlansPage/ui/CreateBillingPlanForm/ui/styles';

type PlanInfoFormSectionType = {
  apiError?: ResponseErrorType;
};

const getFieldOffset = (enabledOptionalField: boolean) => {
  return enabledOptionalField ? '12px' : `${ERROR_MESSAGE_HEIGHT}px`;
};

export const PlanInfoFormSection = ({ apiError }: PlanInfoFormSectionType) => {
  const { values } = useFormikContext<BillingPlanFormType>();

  const { hasUsersLimit, hasWiresLimit, hasOveragePrice } = values || {};

  return (
    <>
      <DrawerHeaderBackLine>
        <Typography variant='subTitleSmall' fontWeight='medium'>
          Plan Info
        </Typography>
      </DrawerHeaderBackLine>
      <Box mt='20px' mb='14px'>
        <FormikInput
          name='name'
          label='WireVault Plan Name*'
          placeholder='Enter WireVault Plan Name'
          apiError={apiError}
        />
        <FormikTextArea name='description' label='Description' placeholder='Enter Description' apiError={apiError} />

        <Box mb={getFieldOffset(hasUsersLimit)}>
          <FormikSwitchToggle
            name='hasUsersLimit'
            size='small'
            label={
              <Typography variant='bodySmall' fontWeight='medium'>
                Users Limit
              </Typography>
            }
            hint={
              <Typography variant='bodySmall' color={COLORS.grey[650]}>
                Enable this feature to set a limit of org users for this plan
              </Typography>
            }
            hasErrorComponent={false}
          />
        </Box>

        {hasUsersLimit && (
          <FormikInput
            inputMode='numeric'
            name='users_limit'
            label='Users Number Limit*'
            placeholder='Enter Users Number Limit'
            apiError={apiError}
          />
        )}

        <Box mb={getFieldOffset(hasWiresLimit)}>
          <FormikSwitchToggle
            name='hasWiresLimit'
            size='small'
            label={
              <Typography variant='bodySmall' fontWeight='medium'>
                Wires Limit
              </Typography>
            }
            hint={
              <Typography variant='bodySmall' color={COLORS.grey[650]}>
                Enable this feature to set a limit of wires for this plan
              </Typography>
            }
            hasErrorComponent={false}
          />
        </Box>

        {hasWiresLimit && (
          <FormikInput
            inputMode='numeric'
            name='wires_limit'
            label='Wires Number Limit*'
            placeholder='Enter Wires Number Limit'
            apiError={apiError}
          />
        )}

        <Box mb={getFieldOffset(hasOveragePrice)}>
          <FormikSwitchToggle
            name='hasOveragePrice'
            size='small'
            label={
              <Typography variant='bodySmall' fontWeight='medium'>
                Overage
              </Typography>
            }
            hint={
              <Typography variant='bodySmall' color={COLORS.grey[650]}>
                Enable this feature to set overage price for wires viewed for this plan
              </Typography>
            }
            hasErrorComponent={false}
          />
        </Box>

        {hasOveragePrice && (
          <FormikPriceInput
            name='overage_price'
            label='Overage Price*'
            placeholder='Enter Overage Price'
            apiError={apiError}
          />
        )}
      </Box>
    </>
  );
};
