import { COLORS } from '@/styles/colors';
import styled from 'styled-components';
import { TableHeaderVariantType } from '@/components/Table/TableHeader/TableHeader';

export const TableHeaderContainer = styled.thead<{ variant: TableHeaderVariantType }>`
  background: ${COLORS.grey[950]};
  width: 100%;
  color: ${COLORS.white};

  ${({ variant }) =>
    variant === 'secondary' &&
    `
    background: ${COLORS.grey[150]};
    color: ${COLORS.grey[650]};
  `};
`;
