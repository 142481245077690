import { useNavigate } from 'react-router-dom';
import { AccessTokenType, AuthResponseType } from '@/types/sharedTypes';
import { getAuthNextStepUrl } from '../const/const';
import { authTokenService } from '../LoginFlow/LoginPage/service/authTokenService';
import { useLogin } from './useLogin';
import { decodeToken, getAccessToken, getActiveUserRoles } from '@/helpers/tokenHelpers';
import { getSelectRoleUrl } from '../LoginFlow/SelectRolePage/urls/getSelectRoleUrl';
import { REDIRECT_URL_KEY } from '@/const/shared';
import { sessionStorageService } from '@/helpers/storageHelpers';

export const useAuthNavigateToStep = () => {
  const navigate = useNavigate();
  const { login } = useLogin();

  const navigateToStep = async (body: AuthResponseType) => {
    // User is not authenticated - parse JWT token and navigate to next step
    if (body?.auth_token) {
      authTokenService.setTokens({ access: body?.auth_token });

      const nextStepUrl = getAuthNextStepUrl(body?.auth_token);

      return navigate(nextStepUrl);
    }

    // User is authenticated -> continue login flow
    if (body?.access) {
      // Check if user has multiple roles and show select role page to continue as a selected role
      const tokenData = decodeToken<AccessTokenType>(body?.access);
      const { token } = getAccessToken(body);

      const activeRoles = getActiveUserRoles(tokenData.roles);
      // TODO v16.0.0 remove old token
      // const activeRoles = tokenData.active_roles;
      const hasActiveMultiRoles = activeRoles.length > 1;

      if (hasActiveMultiRoles) {
        authTokenService.setTokens(token);

        const previousLocation = sessionStorageService.get(REDIRECT_URL_KEY);

        // If user has previous location then probably he redirected from email notification.
        // In such case we don't need to show select role page.
        if (previousLocation) {
          return login(body);
        }

        return navigate(getSelectRoleUrl());
      }

      return login(body);
    }
  };

  return navigateToStep;
};
