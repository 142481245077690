import { FormikDatePicker } from '@/components/form/fields/FormikDatePicker/FormikDatePicker';
import { Box } from '@/components/Box/Box';

type RangeDatePickerType = {
  nameStart: string;
  nameEnd: string;
  dateStart: Date;
  dateEnd: Date;
};

export const RangeDatePicker = ({ nameStart, nameEnd, dateStart, dateEnd }: RangeDatePickerType) => {
  return (
    <Box flexDirection='row' columnGap='10px'>
      <FormikDatePicker
        name={nameStart}
        variant='small'
        isClearable={false}
        selectsStart
        startDate={dateStart}
        endDate={dateEnd}
      />
      <FormikDatePicker
        name={nameEnd}
        variant='small'
        isClearable={false}
        selectsEnd
        startDate={dateStart}
        endDate={dateEnd}
        minDate={dateStart}
      />
    </Box>
  );
};
