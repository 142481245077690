import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { BillingPlanStatus } from '../../../ui/BillingPlanStatus/BillingPlanStatus';
import { ReactComponent as LogoSmallIcon } from '@/images/LogoSmall.svg';
import { BillingPlanType } from '@/api/v1/opco/billing/getBillingPlansApi';
import { BillingPlanDetailsIconWrapper } from '../../styles';

type BillingPlanDetailsHeaderType = {
  plan: BillingPlanType;
};

export const BillingPlanDetailsHeader = ({ plan }: BillingPlanDetailsHeaderType) => {
  if (!plan) return;

  const { name, status } = plan;

  return (
    <Box display='flex' columnGap='16px' alignItems='flex-start' mb='16px'>
      <BillingPlanDetailsIconWrapper>
        <LogoSmallIcon />
      </BillingPlanDetailsIconWrapper>
      <Box>
        <Typography variant='bodySmall' color={COLORS.grey[650]}>
          Plan name
        </Typography>
        <Box display='flex' columnGap='12px' alignItems='flex-start'>
          <Typography variant='subTitle' fontWeight='semibold'>
            {name}
            <Box display='inline-block' ml='12px'>
              <BillingPlanStatus status={status} />
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
