import { ReactNode } from 'react';
import { AppError } from '../Error/AppError';
import { LayoutSpinner, LayoutWrapper } from './styles';
import { useErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

export type BlankLayoutType = {
  isLoading: boolean;
  children?: ReactNode;
};

export const BlankLayout = ({ isLoading, children }: BlankLayoutType) => {
  const { error } = useErrorBoundary();

  if (error) {
    return <AppError errorMessage={error.message} />;
  }

  return isLoading ? (
    <LayoutWrapper>
      <LayoutSpinner />
    </LayoutWrapper>
  ) : (
    <>{children}</>
  );
};
