import { USER_ROLE_LABEL_MAP, USER_ROLE_MAP, UserRolesStatusType, UserRoleType } from '@/const/user';
import { sessionStorageService } from './storageHelpers';
import { CURRENT_ROLE_KEY } from '@/const/shared';
import { SelfUserDetailsType } from '@/api/v1/users/getSelfUserDetailsApi';
import { getActiveUserRoles } from './tokenHelpers';
import { ORGANIZATION_USER_ROLE_LABEL_MAP } from '@/bundle/_Opco/ui/OrganizationUsers/const/const';
import { isNullOrUndefined } from '@/helpers/objectHelpers';

export const getStorageCurrentRole = () => {
  const currentRole: UserRoleType = sessionStorageService.get(CURRENT_ROLE_KEY);

  return currentRole;
};

export const setStorageCurrentRole = (role: UserRoleType) => {
  sessionStorageService.set(CURRENT_ROLE_KEY, role);
};

export const getOrganizationName = (user: SelfUserDetailsType) => {
  if (!user || !user?.organization_user) return '';

  return user?.organization_user?.organization?.name || '';
};

export const getOrganizationRole = (user: SelfUserDetailsType) => {
  if (!user || !user?.organization_user) return;

  return user?.organization_user?.role;
};

export const getUserRoleLabel = (currentRole: UserRoleType, user: SelfUserDetailsType) => {
  if (currentRole === USER_ROLE_MAP.ORGANIZATION) {
    const organizationRole = getOrganizationRole(user);

    return ORGANIZATION_USER_ROLE_LABEL_MAP[organizationRole];
  }

  return USER_ROLE_LABEL_MAP[currentRole];
};

export const hasRole = (roles: UserRolesStatusType, role: UserRoleType) => {
  const activeRoles = getActiveUserRoles(roles);

  return activeRoles.includes(role);
};

// TODO v16.0.0 remove old token
// export const hasRoleOldToken = (user: SelfUserDetailsType, role: UserRoleType) => {
//   if (!user) return false;

//   return user.active_roles?.includes(role);
// };

export const getIsAvailableToAssign = (availableToAssign: boolean) => {
  if (isNullOrUndefined(availableToAssign)) return true;

  return !!availableToAssign;
};
