import { Route } from 'react-router-dom';
import { getBillingPlansPath } from './_BillingPlansPage/urls/getBillingPlansUrls';
import { BillingPlansPage } from './_BillingPlansPage/BillingPlansPage';
import { getBilledEventsPath } from './_BilledEventsPage/urls/getBilledEventsUrl';
import { BilledEventsPage } from './_BilledEventsPage/BilledEventsPage';
import { getBillingPlanDetailsPath } from './_BillingPlansPage/Details/url/getBillingPlanDetailsUrl';
import { BillingPlanDetailsPage } from './_BillingPlansPage/Details/BillingPlanDetailsPage';

export const OpcoBillingRoutes = [
  <Route key={getBillingPlansPath()} path={getBillingPlansPath()} element={<BillingPlansPage />} />,
  <Route key={getBillingPlanDetailsPath()} path={getBillingPlanDetailsPath()} element={<BillingPlanDetailsPage />} />,
  <Route key={getBilledEventsPath()} path={getBilledEventsPath()} element={<BilledEventsPage />} />,
];
