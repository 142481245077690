import { media } from '@/styles/responsive';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;

  ${media.mobile`
    grid-template-columns: repeat(1, 1fr);
  `}
`;
