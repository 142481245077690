import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Button } from '@/components/Button/Button';
import { ButtonsWrapper } from '@/bundle/_Opco/ui/ModifyOrganizationForm/styles';
import { OrganizationUserType } from '@/bundle/_Opco/types/types';
import { useMutation } from '@tanstack/react-query';
import { editOrganizationUserDetails } from '@/bundle/_Opco/ui/OrganizationUsers/Details/api';
import { EditOrganizationUserDetailsPayloadType } from '@/api/v1/opco/organizations/editOrganizationUserApi';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { Notification } from '@/components/Notification/Notification';
import { PERSONAL_DETAILS_INITIAL_VALUES, PERSONAL_DETAILS_VALIDATION_SCHEMA } from '@/const/formConfigs';
import { useEffect } from 'react';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';

interface EditPersonalDetailsFormType {
  user: OrganizationUserType;
  onClose: () => void;
  onSuccess: () => void;
}

export const EditPersonalDetailsForm = ({ user, onClose, onSuccess }: EditPersonalDetailsFormType) => {
  const { organizationId, userId } = useParams<{ organizationId: string; userId: string }>();

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['edit_organization_user_details', organizationId, userId],
    mutationFn: (values: EditOrganizationUserDetailsPayloadType) => {
      return editOrganizationUserDetails(organizationId, userId, values);
    },

    onSuccess: (editOrganizationUserDetailsResponse) => {
      if (editOrganizationUserDetailsResponse.error) return;

      onSuccess();
    },
  });

  const formik = useFormik({
    initialValues: PERSONAL_DETAILS_INITIAL_VALUES,
    validationSchema: PERSONAL_DETAILS_VALIDATION_SCHEMA,
    onSubmit: async (values: EditOrganizationUserDetailsPayloadType) => {
      await mutate(values);
    },
  });

  useEffect(() => {
    if (!user) {
      return;
    }

    formik.setValues({
      first_name: user.first_name,
      middle_name: user.middle_name,
      last_name: user.last_name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const apiError = data?.error;
  const formError = getResponseError(apiError);

  return (
    <>
      {formError && (
        <Notification variant='error' mb='24px'>
          {formError}
        </Notification>
      )}
      <FormikForm value={formik}>
        <FormikInput name='first_name' label='First Name*' placeholder='Enter First Name' apiError={apiError} />
        <FormikInput name='middle_name' label='Middle Name' placeholder='Enter Middle Name' apiError={apiError} />
        <FormikInput name='last_name' label='Last Name*' placeholder='Enter Last Name' apiError={apiError} />
        <ButtonsWrapper>
          <Button type='button' variant='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button type='submit' isLoading={isPending}>
            Save
          </Button>
        </ButtonsWrapper>
      </FormikForm>
    </>
  );
};
