import { StatusWrapper } from '@/components/StatusWrapper/StatusWrapper';
import { STATUS_DEPOSIT_ACCOUNT_MAP, STATUS_DEPOSIT_ACCOUNT_TEXT_DB } from './const';
import { DepositAccountStatusType } from '@/types/wireTypes';

type DepositAccountStatusPropsType = {
  status: DepositAccountStatusType;
};

export const DepositAccountStatus = ({ status }: DepositAccountStatusPropsType) => {
  const statusText = STATUS_DEPOSIT_ACCOUNT_TEXT_DB[status] || '-';
  const statusVariant = STATUS_DEPOSIT_ACCOUNT_MAP[status]?.variant;
  const statusIcon = STATUS_DEPOSIT_ACCOUNT_MAP[status]?.statusIcon;

  return (
    <StatusWrapper variant={statusVariant}>
      {statusIcon}
      {statusText}
    </StatusWrapper>
  );
};
