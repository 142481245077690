import { BILLING_FREQUENCY } from './const';
import { Divider, PricingCardWrapper, TotalBlock } from './styles';
import { ReactComponent as CheckedIcon } from './images/CheckedIcon.svg';
import { ReactComponent as UnCheckedIcon } from './images/UncheckedIcon.svg';
import { COLORS } from '@/styles/colors';
import { SwitchToggleLabel } from './ui/SwitchToggleLabel';
import { Typography } from '@/components/Typography/Typography';
import { Box } from '@/components/Box/Box';
import { SwitchToggle } from '@/components/form/fields/SwitchToggle/SwitchToggle';
import { Button } from '@/components/Button/Button';
import { PaymentPlanCardRow } from './ui/PaymentPlanCardRow';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { getPaymentCheckoutUrl } from '@/bundle/pages/BillingCheckoutPage/urls/getPaymentCheckoutUrl';
import { FormattedPlanType } from '@/bundle/pages/SelectBillingPlanPage/usePrepareBillingPlans';
import noop from 'lodash/noop';

type PaymentPlanCardType = {
  billingPlan: FormattedPlanType;
  planId: string;
};

export const PaymentPlanCard = ({ billingPlan, planId }: PaymentPlanCardType) => {
  const navigate = useNavigate();
  const hasAddon = !!billingPlan.addonId;

  const isYearlyFrequency = billingPlan.frequency === BILLING_FREQUENCY.YEARLY;
  const isSelectedPlan = billingPlan.planId === planId;

  const navigateToPaymentCheckout = () => {
    navigate({
      pathname: getPaymentCheckoutUrl(),
      search: createSearchParams({
        plan_id: billingPlan.planId,
        ...(billingPlan.addonId && { addon_id: billingPlan.addonId }),
      }).toString(),
    });
  };

  return (
    <PricingCardWrapper>
      <Box display='flex' flexDirection='column' rowGap={20}>
        <Box display='flex' flexDirection='column'>
          <Typography fontWeight='semibold' variant='headline4'>
            {billingPlan?.name}
          </Typography>
          <Box display='flex' flexDirection='column' rowGap={8} mt='16px' mb='12px'>
            <PaymentPlanCardRow>
              <strong>Share unlimited</strong> deposit info
            </PaymentPlanCardRow>
            <PaymentPlanCardRow>
              <strong>Collect</strong> or <strong>confirm</strong> wire info:
              <strong> {billingPlan?.wiresLimit}</strong>
              <Typography as='span' variant='bodySmall' color={COLORS.grey[650]}>
                /month
              </Typography>
            </PaymentPlanCardRow>
            <PaymentPlanCardRow>
              <strong>{billingPlan.usersLimit}</strong> users
            </PaymentPlanCardRow>
            <Box display='flex' alignItems='flex-start' columnGap={8} width='100%'>
              <Box>{hasAddon ? <CheckedIcon /> : <UnCheckedIcon />}</Box>
              <SwitchToggle
                label={
                  <SwitchToggleLabel
                    isYearlyIdVerificationPrice={billingPlan.addonAmount}
                    addonName={billingPlan.addonName}
                  />
                }
                onChange={noop}
                checked={hasAddon}
                name=''
                size='small'
                hasErrorComponent={false}
              />
            </Box>
            {billingPlan.overagePrice ? (
              <Typography color={COLORS.grey[650]} variant='caption'>
                Overage rate — <strong>{billingPlan.overagePrice}</strong> per collected or confirmed payment info
              </Typography>
            ) : (
              ''
            )}
          </Box>
          <Box>
            <TotalBlock>
              <Box display='flex' flexDirection='column' rowGap={4} mb='12px'>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography as='span' variant='bodySmall'>
                    Plan Price
                  </Typography>
                  <Typography as='span' variant='bodySmall' fontWeight='semibold'>
                    {billingPlan.amount}
                  </Typography>
                </Box>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography as='span' variant='bodySmall'>
                    {billingPlan.addonName}
                  </Typography>
                  <Typography as='span' variant='bodySmall' fontWeight='semibold'>
                    {billingPlan?.addonAmount ?? '-'}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box display='flex' alignItems='center' justifyContent='space-between' mt='8px'>
                <Box display='flex' flexDirection='column'>
                  <Typography as='span' variant='bodySmall' fontWeight='semibold'>
                    Total per month
                  </Typography>
                  <Typography as='span' variant='caption' color={COLORS.grey[650]} fontWeight='medium'>
                    {isYearlyFrequency ? 'billed yearly' : 'billed monthly'}
                  </Typography>
                </Box>
                <Box>
                  <Typography as='span' variant='headline2' fontWeight='semibold'>
                    {billingPlan.totalAmount}
                  </Typography>
                </Box>
              </Box>
            </TotalBlock>
          </Box>
        </Box>
        <Box mt='auto'>
          <Button onClick={navigateToPaymentCheckout} disabled={isSelectedPlan}>
            {isSelectedPlan ? 'Current' : 'Select'}
          </Button>
        </Box>
      </Box>
    </PricingCardWrapper>
  );
};
