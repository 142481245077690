import { ReactNode } from 'react';
import { StatusContainer } from './styles';
import { StatusVariantType } from './const';

type StatusWrapperType = {
  children: ReactNode;
  variant: StatusVariantType;
};

export const StatusWrapper = ({ children, variant }: StatusWrapperType) => {
  return <StatusContainer variant={variant}>{children}</StatusContainer>;
};
