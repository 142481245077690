import { BlockchainUnavailableHintWrapper } from './styles';
import { Notification } from '@/components/Notification/Notification';

export const LoadPaymentDetailsBlockchainUnavailable = () => {
  return (
    <BlockchainUnavailableHintWrapper>
      <Notification variant='info-secondary'>
        The WireVault blockchain is unavailable. The wire info cannot be confirmed at this time. Please try again later.
      </Notification>
    </BlockchainUnavailableHintWrapper>
  );
};
