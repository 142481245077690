import { ReactNode } from 'react';
import { FieldRowWrapper } from './styles';

type FieldRowType = {
  children: ReactNode;
  columns: number[];
  rowGap?: string;
  mobileStretch?: boolean;
};

export const FieldRow = ({ columns, mobileStretch = true, rowGap, children }: FieldRowType) => {
  return (
    <FieldRowWrapper columns={columns} mobileStretch={mobileStretch} rowGap={rowGap}>
      {children}
    </FieldRowWrapper>
  );
};
