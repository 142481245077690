import { StatusWrapper } from '@/components/StatusWrapper/StatusWrapper';
import { BILLING_PLAN_STATUS_MAP } from './const';
import { BillingPlanStatusType } from '@/const/billing';

type StatusPropsType = {
  status: BillingPlanStatusType;
};

export const BillingPlanStatus = ({ status }: StatusPropsType) => {
  const statusMap = BILLING_PLAN_STATUS_MAP[status];

  return <StatusWrapper variant={statusMap?.variant}>{statusMap?.title}</StatusWrapper>;
};
