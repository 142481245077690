import { Box } from '@/components/Box/Box';
import { UtcTooltip } from '@/bundle/_Opco/ui/UtcTooltip/UtcTooltip';
import { EmailLink } from '@/components/EmailLink/EmailLink';
import { LastResetTime, ResetLabel, TimeSeparator } from './styles';
import { LastResetDetailsType } from '@/bundle/_Opco/types/types';
import { formatDateTimeUTC } from '@/helpers/dateHelpers/dateHelpers';
import { UserDetailsRow } from '@/bundle/_Opco/shared/styles';

type ResetTimeInfoType = {
  info: LastResetDetailsType;
};

export const ResetTimeInfo = ({ info }: ResetTimeInfoType) => {
  const { last_reset, reset_by } = info;
  const formattedDate = formatDateTimeUTC(last_reset);

  return (
    <UserDetailsRow isFlex>
      <Box>
        <Box display='flex' columnGap='6px' mb='4px'>
          <ResetLabel>Last Reset</ResetLabel>
          <UtcTooltip message='Last Reset is recorded in UTC.' />
        </Box>
        <LastResetTime>{formattedDate}</LastResetTime>
      </Box>
      <TimeSeparator />
      <Box>
        <ResetLabel>Reset by</ResetLabel>
        <EmailLink email={reset_by} />
      </Box>
    </UserDetailsRow>
  );
};
