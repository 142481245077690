import { ListResponseType } from '@/types/sharedTypes';
import { authApiService } from '@/api/authApiService';
import { BillingTransaction } from '@/types/paymentTypes';
import { ApiResponseType } from '@/types/sharedTypes';

export const getBillingTransactionsApi = async (): Promise<ApiResponseType<ListResponseType<BillingTransaction>>> => {
  const uri = '/organization/payments/transactions/';

  return authApiService.GET(uri);
};
