import { parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { AssignedWireType } from '@/types/wireTypes';
import { formatPayloadDate } from '@/helpers/dateHelpers/dateHelpers';
import { Box } from '@/components/Box/Box';
import { getCreateChildWireValidationSchema } from './const/const';
import { OptionType } from '@/components/form/fields/Select/Select';
import { isRecipientChainProxy, isRecipientProxy } from '@/helpers/wireHelpers';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { FormikSelect } from '@/components/form/fields/FormikSelect/FormikSelect';
import { FormikDatePicker } from '@/components/form/fields/FormikDatePicker/FormikDatePicker';
import { FormikTextArea } from '@/components/form/fields/FormikTextArea/FormikTextArea';
import { ResponseErrorType } from '@/types/sharedTypes';
import { formatNotes, getCurrencyOption } from '@/helpers/formatHelpers';
import { FieldRow } from '@/components/form/FieldRow/FieldRow';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Button } from '@/components/Button/Button';

type CreateReplacementWireFormType = {
  wire: AssignedWireType;
  currencyOptions: OptionType[];
  isLoading: boolean;
  onCreate: (data) => void;
  onClose: () => void;
  error: ResponseErrorType;
};

const getFormattedWireData = (wire: AssignedWireType, currencyOptions: OptionType[]) => {
  if (!wire) return;

  const currencyOption = getCurrencyOption(wire?.currency, currencyOptions);
  const isProxy = isRecipientProxy(wire);
  const isChainProxy = isRecipientChainProxy(wire);
  const { name, amount, expected_payment_date, assigned_user, notes, assigned_proxy_user } = wire;
  const commonWireData = {
    name,
    amount: amount ?? '',
    currency: currencyOption,
    expected_payment_date: expected_payment_date ? parseISO(expected_payment_date) : '',
    notes,
  };

  if (isChainProxy) {
    return {
      ...commonWireData,
      email_proxy: assigned_proxy_user?.email,
      email: assigned_user?.email,
    };
  }

  return {
    ...commonWireData,
    email: isProxy ? assigned_proxy_user?.email : assigned_user?.email,
  };
};

export const CreateReplacementWireForm = ({
  wire,
  currencyOptions = [],
  isLoading,
  onCreate,
  onClose,
  error,
}: CreateReplacementWireFormType) => {
  const isChainProxy = isRecipientChainProxy(wire);
  const isDocumentVerificationRequired = !!wire?.is_pd_provider_kyc_required;

  const wireData = getFormattedWireData(wire, currencyOptions);

  const formik = useFormik<any>({
    initialValues: wireData,
    enableReinitialize: true,
    validationSchema: getCreateChildWireValidationSchema(isChainProxy),
    onSubmit: (values) => {
      const { name, amount, currency, expected_payment_date, notes } = values;

      const payload = {
        name,
        amount,
        currency: currency.value,
        expected_payment_date: formatPayloadDate(expected_payment_date),
        notes: formatNotes(notes),
      };

      onCreate(payload);
    },
  });

  const closeModal = () => {
    onClose();
  };

  return (
    <FormikForm value={formik}>
      <FormikInput name='name' label='Wire Name*' placeholder='Enter Name' apiError={error} />
      <FieldRow columns={[7, 5]}>
        <FormikInput name='amount' label='Wire Amount' placeholder='Enter Amount' />
        <FormikSelect name='currency' label='Currency' options={currencyOptions} />
      </FieldRow>
      <FieldRow columns={[7, 5]}>
        <FormikDatePicker
          name='expected_payment_date'
          label='Expected Payment Date'
          placeholder='Select Date'
          hasErrorComponent
        />
      </FieldRow>
      {isChainProxy && <FormikInput name='email_proxy' label='Recipient Proxy Email Address' disabled />}
      <FormikInput
        name='email'
        label='Recipient Email Address'
        labelHint={isDocumentVerificationRequired && 'ID VERIFICATION REQUIRED'}
        disabled
      />
      <FormikTextArea name='notes' label='Notes' placeholder='Enter Notes' />

      <Box alignItems='center' justifyContent='space-between' columnGap='20px'>
        <Button variant='secondary' onClick={closeModal}>
          Cancel
        </Button>
        <Button type='submit' isLoading={isLoading}>
          Create
        </Button>
      </Box>
    </FormikForm>
  );
};
