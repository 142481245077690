import { useFormik } from 'formik';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Button } from '@/components/Button/Button';
import { WireCreateFormWrapper } from './styles';
import { WireTypesType } from '../../../../Transactions/_Details/const/const';
import { FormButtonBlockCentered } from '../../../../../shared/ui/styles';
import { ResponseErrorType } from '@/types/sharedTypes';
import { getCreateWireInitialValues, CREATE_WIRE_VALIDATION_SCHEMA, WIRES_OPTIONS } from '../../const/const';
import { formatPayloadDate } from '@/helpers/dateHelpers/dateHelpers';
import { FormikSelect } from '@/components/form/fields/FormikSelect/FormikSelect';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { FormikDatePicker } from '@/components/form/fields/FormikDatePicker/FormikDatePicker';
import { FormikTextArea } from '@/components/form/fields/FormikTextArea/FormikTextArea';
import { OptionType } from '@/components/form/fields/Select/Select';
import { FieldRow } from '@/components/form/FieldRow/FieldRow';
import { formatNotes } from '@/helpers/formatHelpers';

type CreateWireFormType = {
  onCreate: (payload) => void;
  currencyOptions: OptionType[];
  onClose: (reloadWires?: boolean) => void;
  selectedWireType: WireTypesType;
  isLoading: boolean;
  error: ResponseErrorType;
};

export const CreateWireForm = ({
  onCreate,
  onClose,
  currencyOptions = [],
  selectedWireType,
  isLoading,
  error,
}: CreateWireFormType) => {
  const formik = useFormik<any>({
    initialValues: getCreateWireInitialValues(currencyOptions, selectedWireType),
    validationSchema: CREATE_WIRE_VALIDATION_SCHEMA,
    enableReinitialize: true,
    onSubmit: (values) => {
      const { name, amount, currency, expected_payment_date, type, notes } = values;
      const payload = {
        name,
        currency: currency.value,
        amount,
        expected_payment_date: expected_payment_date ? formatPayloadDate(expected_payment_date) : null,
        type: type.value,
        notes: formatNotes(notes),
      };

      onCreate(payload);
    },
  });

  const cancelHandler = () => {
    onClose();
  };

  return (
    <WireCreateFormWrapper>
      <FormikForm value={formik}>
        <FormikSelect
          name='type'
          label='Wire Type*'
          placeholder='Select Wire Type'
          options={WIRES_OPTIONS}
          apiError={error}
        />
        <FormikInput name='name' label='Wire Name*' placeholder='Enter Name' apiError={error} />
        <FieldRow columns={[7, 5]}>
          <FormikInput name='amount' label='Wire Amount' placeholder='Enter Amount' apiError={error} />
          <FormikSelect name='currency' label='Currency' options={currencyOptions} apiError={error} />
        </FieldRow>

        <FieldRow columns={[7, 5]}>
          <FormikDatePicker
            name='expected_payment_date'
            hasErrorComponent
            label='Expected Payment Date'
            placeholder='Select Date'
            apiError={error}
          />
        </FieldRow>
        <FormikTextArea name='notes' label='Notes' placeholder='Enter Notes' apiError={error} />

        <FormButtonBlockCentered>
          <Button variant='secondary' mobileStretch onClick={cancelHandler} width={200}>
            Cancel
          </Button>
          <Button type='submit' mobileStretch isLoading={isLoading} width={200}>
            Save
          </Button>
        </FormButtonBlockCentered>
      </FormikForm>
    </WireCreateFormWrapper>
  );
};
