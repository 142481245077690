import {
  REQUIRED_EMAIL_VALIDATOR,
  CONTACT_US_MESSAGE_VALIDATOR,
  PHONE_NUMBER_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';
import * as Yup from 'yup';

export type ContactUsFormType = {
  sender_email: string;
  mobile_phone: string;
  subject: string;
  message: string;
};

export const CONTACT_US_INITIAL_VALUES: ContactUsFormType = {
  sender_email: '',
  mobile_phone: '',
  subject: '',
  message: '',
};

export const CONTACT_US_VALIDATION_SCHEMA = Yup.object().shape({
  sender_email: REQUIRED_EMAIL_VALIDATOR,
  mobile_phone: PHONE_NUMBER_VALIDATOR,
  subject: REQUIRED_TEXT_FIELD_VALIDATOR,
  message: CONTACT_US_MESSAGE_VALIDATOR,
});
