import styled from 'styled-components';

export const AccordionWrapper = styled.div`
  width: 100%;
`;

export const AccordionHeader = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const AccordionBody = styled.div<{ isOpen: boolean }>`
  width: 100%;
  display: grid;
  transition: 0.2s all ease-in-out;
  grid-template-rows: 0fr;

  ${({ isOpen }) =>
    isOpen &&
    `
      grid-template-rows: 1fr;
  `};
`;

export const AccordionBodyContent = styled.div`
  overflow: hidden;
`;

export const AccordionButtonWrapper = styled.div<{ isOpen: boolean }>`
  flex-shrink: 0;
  margin-right: 3px;
  transition: 0.4s all ease;

  ${({ isOpen }) =>
    isOpen &&
    `
      transform: scale(1, -1);
  `}
`;
