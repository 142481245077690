import { authApiService } from '@/api/authApiService';
import { BillingPlanResponseType } from '@/types/paymentTypes';
import { ApiResponseType, ListResponseType } from '@/types/sharedTypes';

export const getBillingPlanByIdApi = (
  planId: string
): Promise<ApiResponseType<ListResponseType<BillingPlanResponseType>>> => {
  const uri = `/organization/payments/plans/${planId}/`;

  return authApiService.GET(uri);
};
