import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { IconButton } from '../IconButton/IconButton';
import { ReactComponent as MenuDotsIcon } from './images/MenuDotsIcon.svg';
import { ReactNode } from 'react';
import { PopoverAnchorContainer, PopoverMenuWrapper } from './styles';

type PopoverMenuType = {
  isOpen: boolean;
  onToggleOpen: (open: boolean) => void;
  children: ReactNode;
  placement?: Placement;
  anchorSlot?: ReactNode;
};

export const PopoverMenu = ({
  isOpen,
  onToggleOpen,
  children,
  placement = 'bottom-end',
  anchorSlot,
}: PopoverMenuType) => {
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: onToggleOpen,
    placement,
    middleware: [offset(10), flip({ fallbackAxisSideDirection: 'end' }), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  return (
    <>
      <PopoverAnchorContainer ref={refs.setReference} {...getReferenceProps()}>
        {anchorSlot || (
          <IconButton>
            <MenuDotsIcon />
          </IconButton>
        )}
      </PopoverAnchorContainer>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <PopoverMenuWrapper ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            {children}
          </PopoverMenuWrapper>
        </FloatingFocusManager>
      )}
    </>
  );
};
