import { useField } from 'formik';
import { getFieldError } from '@/helpers/formHelpers/formHelpers';
import { ResponseErrorType } from '@/types/sharedTypes';
import { TextArea, TextAreaType } from '@/components/form/fields/TextArea/TextArea';

type FormikTextAreaType = Omit<TextAreaType, 'apiError'> & {
  apiError?: ResponseErrorType;
};

export const FormikTextArea = ({ name, apiError, ...rest }: FormikTextAreaType) => {
  const [field, meta] = useField(name);

  const { error, apiError: apiErrorMessage } = getFieldError(name, meta, apiError);

  return <TextArea {...field} error={error} apiError={apiErrorMessage} {...rest} />;
};
