import { addPaymentMethodApi } from '@/api/v1/organization/billing/addPaymentMethodApi';
import { deletePaymentMethodApi } from '@/api/v1/organization/billing/deletePaymentMethodApi';
import { editBillingAddressApi } from '@/api/v1/organization/billing/editBillingAddressApi';
import { getCustomerVaultApi } from '@/api/v1/organization/billing/getCustomerVaultApi';
import {
  setDefaultBillingAddressApi,
  setDefaultBillingAddressPayloadType,
} from '@/api/v1/organization/billing/setDefaultBillingAddressApi';
import { setDefaultPaymentMethodApi } from '@/api/v1/organization/billing/setDefaultPaymentMethodApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import {
  CreateSubscriptionPayloadType,
  DefaultBillingAddressType,
  DefaultPaymentMethodType,
} from '@/types/paymentTypes';
import { createSubscriptionApi } from '@/api/v1/organization/billing/createSubscriptionApi';
import { createCustomerVaultApi } from '@/api/v1/organization/billing/createCustomerVaultApi';
import { CreateCustomerVaultPayloadType } from '@/api/v1/organization/billing/createCustomerVaultApi';
import { getBillingPlanByIdApi } from '@/api/v1/organization/billing/getBillingPlanByIdApi';
import { getSubscriptionsApi } from '@/api/v1/organization/getSubscribtionsApi';
import { getBillingTransactionsApi } from '@/api/v1/organization/billing/getBillingTransactionsApi';
import { getBillingPlansApi } from '@/api/v1/organization/billing/getBillingPlansApi';

export const loadCustomerVault = async () => {
  try {
    const response = await getCustomerVaultApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const createCustomerVault = async (
  billingAddress: CreateCustomerVaultPayloadType['default_billing_address'],
  token?: string
) => {
  const payload = {
    default_billing_address: billingAddress,
    ...(token && {
      default_payment: {
        token,
      },
    }),
  };

  try {
    const response = await createCustomerVaultApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const addPaymentMethod = async (token: string) => {
  const payload = {
    token,
  };

  try {
    const response = await addPaymentMethodApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const deletePaymentMethod = async (payload: DefaultPaymentMethodType) => {
  try {
    const response = await deletePaymentMethodApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const setDefaultPaymentMethod = async (payload: DefaultPaymentMethodType) => {
  try {
    const response = await setDefaultPaymentMethodApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const editBillingAddress = async (addressId: string, payload: DefaultBillingAddressType) => {
  try {
    const response = await editBillingAddressApi(addressId, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const setDefaultBillingAddress = async (payload: setDefaultBillingAddressPayloadType) => {
  try {
    const response = await setDefaultBillingAddressApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadPaymentPlans = async () => {
  try {
    const response = await getBillingPlansApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const getBillingPlanById = async (planId) => {
  try {
    const response = await getBillingPlanByIdApi(planId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const createSubscription = async (payload: CreateSubscriptionPayloadType) => {
  try {
    const response = await createSubscriptionApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const getBillingTransactions = async () => {
  try {
    const response = await getBillingTransactionsApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const getSubscriptions = async () => {
  try {
    const response = await getSubscriptionsApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
