import { ApiResponseType, RequestOptions } from '@/types/sharedTypes';
import { authApiService } from '@/api/authApiService';

export type CreateBillingPlanType = {
  payment_service_id: string;
  name: string;
  description?: string;
  users_limit: string | null;
  wires_limit: string | null;
  overage_price: string | null;
};

export const createBillingPlanApi = async (
  data: CreateBillingPlanType,
  options?: RequestOptions
): Promise<ApiResponseType<CreateBillingPlanType>> => {
  const uri = `/opco/payments/plans/`;

  return authApiService.POST(uri, data, null, options);
};
