import { ApiResponseType } from '@/types/sharedTypes';
import { JSONError } from '@/bundle/Error/classes/JSONError';
import { urlApiHelper } from './helpers/urlApiHelpers';
import { APPLICATION_JSON_CONTENT_TYPE, DEFAULT_LANGUAGE } from './const';
import { LOGIN_URI } from './v1/users/public/loginApi';

const getContentType = (headers: Headers) => {
  return headers.get('content-type');
};

const buildOptions = async (headers: HeadersInit = {}) => {
  const options: RequestInit = {
    headers: {
      'Content-Type': APPLICATION_JSON_CONTENT_TYPE,
      'Accept-Language': DEFAULT_LANGUAGE,
      ...headers,
    },
  };

  return options;
};

export const apiService = {
  async GET<T>(uri: string, params?: any): Promise<ApiResponseType<T>> {
    const url = urlApiHelper(uri, params);
    const options = await buildOptions();
    const response = await fetch(url, options);

    return apiService.handleResponse(response);
  },

  async POST<T>(uri: string, data: any, params?: any): Promise<ApiResponseType<T>> {
    const url = urlApiHelper(uri, params);
    const options = await buildOptions();
    // Apply credentials to login URI, so we can apply auth cookie
    const shouldIncludeCredentials = uri === LOGIN_URI;
    const response = await fetch(url, {
      method: 'POST',
      headers: options.headers,
      body: JSON.stringify(data),
      ...(shouldIncludeCredentials && { credentials: 'include' }),
    });

    return apiService.handleResponse(response);
  },

  async PATCH<T>(uri: string, data: any, params?: any): Promise<ApiResponseType<T>> {
    const url = urlApiHelper(uri, params);
    const options = await buildOptions();
    const response = await fetch(url, {
      method: 'PATCH',
      headers: options.headers,
      body: JSON.stringify(data),
    });

    return apiService.handleResponse(response);
  },

  async handleResponse(response: Response) {
    const contentType = getContentType(response.headers);

    if (contentType !== APPLICATION_JSON_CONTENT_TYPE) {
      throw new JSONError();
    }

    const json = await response.json();

    return { response, json };
  },
};
