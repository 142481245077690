import { COLORS } from '@/styles/colors';
import { flex, flexCenter } from '@/styles/layout';
import { caption, poppinsMediumFont } from '@/styles/typography';
import { styled } from 'styled-components';
import { ReactComponent as DeleteIcon } from '@/images/DeleteIcon.svg';

export const PaymentCardContainer = styled.div`
  border-radius: 16px;
  border: 1px solid ${COLORS.grey[300]};
  background: ${COLORS.grey[150]};
  padding: 20px 16px;
`;

export const InfoCardWrapper = styled.div`
  flex: 1;
  ${flex({ flexDirection: 'column', alignItems: 'flex-start' })};
`;

export const ButtonsWrapper = styled.div`
  flex-shrink: 0;
`;

export const DefaultStatus = styled.div`
  height: 20px;
  padding: 0 8px;
  border-radius: 6px;
  background-color: ${COLORS.grey[600]};
  color: ${COLORS.white};
  ${flexCenter};
  ${caption};
  ${poppinsMediumFont};
`;

export const IconCardWrapper = styled.div`
  width: 58px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${COLORS.grey[300]};
  background: ${COLORS.grey[150]};
  ${flexCenter};
  align-self: center;
`;

export const DeleteCardIcon = styled(DeleteIcon)`
  path {
    fill: ${COLORS.white};
  }
`;
