import { useFormik } from 'formik';
import {
  BillingPlanFormType,
  CREATE_BILLING_PLAN_INITIAL_VALUES,
  CREATE_BILLING_PLAN_VALIDATION_SCHEMA,
} from './const';
import { PlanInfoFormSection } from './ui/PlanInfoFormSection/PlanInfoFormSection';
import { Button } from '@/components/Button/Button';
import { FieldRow } from '@/components/form/FieldRow/FieldRow';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { useState } from 'react';
import { CheckPinCodeModal } from '@/bundle/shared/components/CheckPinCodeModal/CheckPinCodeModal';
import { PIN_TOKEN_EVENT_SCOPE } from '@/const/shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createBillingPlan, loadBaseBillingPlans } from '../../api';
import { CreateBillingPlanType } from '@/api/v1/opco/billing/createBillingPlanApi';
import { SelectBasePlanFormSection } from '../../../_BillingPlansPage/ui/CreateBillingPlanForm/ui/SelectBasePlanFormSection/SelectBasePlanFormSection';
import { convertDollarsToCents } from '@/helpers/formatHelpers';
import { BillingPlanType } from '@/api/v1/opco/billing/getBillingPlansApi';

type CreateBillingPlanFormType = {
  billingPlans: BillingPlanType[];
  onClose: () => void;
  onSuccess: () => void;
};

export const CreateBillingPlanForm = ({ billingPlans, onClose, onSuccess }: CreateBillingPlanFormType) => {
  const [isPinCodeOpen, setIsPinCodeOpen] = useState(false);

  const formik = useFormik<BillingPlanFormType>({
    initialValues: CREATE_BILLING_PLAN_INITIAL_VALUES,
    validationSchema: CREATE_BILLING_PLAN_VALIDATION_SCHEMA,
    onSubmit: () => {
      setIsPinCodeOpen(true);
    },
  });

  const { data: baseBillingPlansData } = useQuery({
    queryKey: ['load_base_billing_plans'],
    queryFn: () => loadBaseBillingPlans(),
  });

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['create_billing_plan'],
    mutationFn: (pinToken: string) => {
      const {
        name,
        description,
        users_limit,
        hasUsersLimit,
        wires_limit,
        hasWiresLimit,
        overage_price,
        hasOveragePrice,
        basePlan,
      } = formik.values;

      if (!basePlan) {
        return;
      }

      const plan: CreateBillingPlanType = {
        name,
        description: description,
        users_limit: hasUsersLimit ? users_limit : null,
        wires_limit: hasWiresLimit ? wires_limit : null,
        overage_price: hasOveragePrice ? convertDollarsToCents(overage_price) : null,
        payment_service_id: basePlan.value as string,
      };

      return createBillingPlan(plan, pinToken);
    },
    onSuccess(createBillingPlanResponse) {
      if (createBillingPlanResponse?.error) return;

      onSuccess();
      onClose();
    },
  });

  const closePinCode = () => {
    setIsPinCodeOpen(false);
  };

  const checkPinCodeSuccess = (pinToken: string) => {
    mutate(pinToken);
    closePinCode();
  };

  const baseBillingPlans = baseBillingPlansData?.body?.results || [];

  return (
    <>
      <FormikForm value={formik}>
        <PlanInfoFormSection apiError={data?.error} />
        <SelectBasePlanFormSection billingPlans={billingPlans} baseBillingPlans={baseBillingPlans} />

        <FieldRow columns={[6, 6]} rowGap='20px'>
          <Button variant='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button type='submit' isLoading={isPending}>
            Save
          </Button>
        </FieldRow>
      </FormikForm>
      {isPinCodeOpen && (
        <CheckPinCodeModal
          isOpen={isPinCodeOpen}
          eventScope={PIN_TOKEN_EVENT_SCOPE.CREATE_BILLING_PLAN}
          onSuccess={checkPinCodeSuccess}
          onClose={closePinCode}
        />
      )}
    </>
  );
};
