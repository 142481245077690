import { ReactNode } from 'react';
import { PopoverMenuItemWrapper } from './styles';

type PopoverMenuItemType = {
  icon?: ReactNode;
  children: ReactNode;
  onClick?: () => void;
};

export const PopoverMenuItem = ({ icon, children, onClick }: PopoverMenuItemType) => {
  return (
    <PopoverMenuItemWrapper onClick={onClick}>
      {icon}
      {children}
    </PopoverMenuItemWrapper>
  );
};
