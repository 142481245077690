import styled from 'styled-components';
import { flex } from '@/styles/layout';
import { bodySmall, caption, poppinsRegularFont, poppinsSemiBoldFont } from '@/styles/typography';
import { COLORS } from '@/styles/colors';
import { InputWrapper } from '../form/fields/Input/styles';
import { inputClearButtonVisibility } from '../form/fields/Input/ui/InputClearButton/styles';

export const AutocompleteWrapper = styled.div`
  position: relative;
`;

export const LabelBlock = styled.div`
  ${flex({ justifyContent: 'space-between' })};
  margin-bottom: 4px;
`;

export const AutocompleteInputWrapper = styled(InputWrapper)`
  position: relative;
  ${inputClearButtonVisibility()};
`;

export const LoadingIndicator = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 46px;
`;

export const LabelSlotWrapper = styled.span`
  ${poppinsSemiBoldFont};
  ${caption};
  color: ${COLORS.grey[700]};
`;

export const LabelWrapper = styled.div`
  ${flex({ alignItems: 'center', justifyContent: 'space-between' })}
  ${bodySmall};
  column-gap: 10px;
`;

export const Label = styled.div`
  ${bodySmall};
  color: ${COLORS.black};
  ${poppinsRegularFont};
`;
