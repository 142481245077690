import { AuthLayout } from '@/bundle/Auth/ui/AuthLayout/AuthLayout';
import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useResponsiveDevice } from '@/hooks/useResponsiveDevice';
import { HeaderWrapper, SideAction, Wrapper } from './styles';
import { loadPaymentPlans } from '../SettingsPage/api';
import { PaymentPlanCard } from './ui/PaymentPlanCard/PaymentPlanCard';
import { BillingFrequencyToggle } from './ui/BillingFrequencyToggle/BillingFrequencyToggle';
import { BILLING_FREQUENCY, BillingFrequencyType } from './ui/PaymentPlanCard/const';
import { usePrepareBillingPlans } from './usePrepareBillingPlans';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BackButton, BackIcon } from '@/bundle/Layouts/ContentLayout/ContentHeader/styles';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { getSettingsUrl } from '../SettingsPage/urls/getSettingsUrl';
import { Button } from '@/components/Button/Button';
import { useLogout } from '@/bundle/shared/hooks/useLogout';

export const SelectBillingPlanPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isMobile } = useResponsiveDevice();
  const { mutate: logoutMutate } = useLogout();

  const [frequency, setFrequency] = useState<BillingFrequencyType>(BILLING_FREQUENCY.YEARLY);

  const { data, isFetching } = useQuery({
    queryKey: ['load_payment_plans'],
    queryFn: () => loadPaymentPlans(),
  });

  const navigateToSettings = () => {
    navigate(getSettingsUrl());
  };

  const preparedPlans = usePrepareBillingPlans(data?.body?.results);
  const redirectedFrom = searchParams.get('redirected_from');
  const planId = searchParams.get('plan_id');
  const isRedirectedFromApp = redirectedFrom === 'app';
  const isRedirectedFromAuth = redirectedFrom === 'auth';

  return (
    <AuthLayout isLoading={isFetching}>
      <Wrapper>
        <Box width='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center' rowGap={42}>
          <Box width='100%' display='flex' flexDirection='column' alignItems='center' rowGap={24}>
            <Box width='100%' display='flex' flexDirection='column' alignItems='center' rowGap={isMobile ? 0 : 8}>
              <HeaderWrapper>
                <SideAction isVisible={isRedirectedFromApp}>
                  <BackButton data-tooltip-id='back-tooltip' onClick={navigateToSettings}>
                    <BackIcon />
                  </BackButton>
                </SideAction>

                <Typography variant={isMobile ? 'headline4' : 'headline'} fontWeight='semibold' textAlign='center'>
                  Find the plan for you
                </Typography>

                <SideAction isVisible={isRedirectedFromAuth}>
                  <Button size='medium' variant='secondary' width={84} onClick={logoutMutate}>
                    Logout
                  </Button>
                </SideAction>
              </HeaderWrapper>

              <Typography variant='subTitleSmall' fontWeight='medium' color={COLORS.grey[650]}>
                You can change it anytime.
              </Typography>
            </Box>
            <BillingFrequencyToggle onChange={setFrequency} frequency={frequency} />
          </Box>
          <Box
            display='flex'
            alignItems='center'
            flexDirection={isMobile ? 'column' : 'row'}
            columnGap={24}
            rowGap={24}
          >
            <PaymentPlanCard billingFrequency={frequency} plan={preparedPlans?.free} selectedPlanId={planId} />
            <PaymentPlanCard billingFrequency={frequency} plan={preparedPlans?.starter} selectedPlanId={planId} />
            <PaymentPlanCard billingFrequency={frequency} plan={preparedPlans?.pro} selectedPlanId={planId} />
          </Box>
        </Box>
      </Wrapper>
      <Tooltip id='back-tooltip'>Back to Settings</Tooltip>
    </AuthLayout>
  );
};
