import { useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { CREATE_OPCO_INITIAL_VALUES, CREATE_OPCO_VALIDATION_SCHEMA } from './const';
import { ButtonsWrapper, FormWrapper } from './styles';
import { Button } from '@/components/Button/Button';
import { CheckPinCodeModal } from '@/bundle/shared/components/CheckPinCodeModal/CheckPinCodeModal';
import { createOpcoUser } from '../../api';
import { Notification } from '@/components/Notification/Notification';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { CreateOpcoUserType } from '@/api/v1/opco/internal/createOpcoInternalUserApi';
import { PIN_TOKEN_EVENT_SCOPE } from '@/const/shared';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { FormikPhoneInput } from '@/components/form/fields/FormikPhoneInput/FormikPhoneInput';

type CreateOpcoUserFormType = {
  onSuccess: () => void;
  onCancel: () => void;
};

export const CreateOpcoUserForm = ({ onSuccess, onCancel }: CreateOpcoUserFormType) => {
  const [isPinCodeOpen, setIsPinCodeOpen] = useState(false);

  const formik = useFormik({
    initialValues: CREATE_OPCO_INITIAL_VALUES,
    validationSchema: CREATE_OPCO_VALIDATION_SCHEMA,
    onSubmit: () => {
      setIsPinCodeOpen(true);
    },
  });

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['create_opco_user'],
    mutationFn: (pinToken: string) => {
      return createOpcoUser(formik.values as CreateOpcoUserType, pinToken);
    },
    onSuccess(createOpcoUserResponse) {
      if (createOpcoUserResponse?.error) return;

      onSuccess();
    },
  });

  const closePinCode = () => {
    setIsPinCodeOpen(false);
  };

  const checkPinCodeSuccess = (pinToken: string) => {
    mutate(pinToken);
    closePinCode();
  };

  const formError = getResponseError(data?.error);

  return (
    <>
      {formError && (
        <Notification variant='error' mb='24px'>
          {formError}
        </Notification>
      )}
      <FormWrapper>
        <FormikForm value={formik}>
          <FormikInput name='email' label='Email Address*' placeholder='Enter Email Address' apiError={data?.error} />
          <FormikPhoneInput
            name='mobile_phone'
            label='Mobile Phone Number*'
            placeholder='Enter Mobile Phone Number With Country Code'
            apiError={data?.error}
          />
          <FormikInput name='first_name' label='First Name*' placeholder='Enter First Name' apiError={data?.error} />
          <FormikInput name='middle_name' label='Middle Name' placeholder='Enter Middle Name' apiError={data?.error} />
          <FormikInput name='last_name' label='Last Name*' placeholder='Enter Last Name' apiError={data?.error} />
          <ButtonsWrapper>
            <Button type='button' variant='secondary' onClick={onCancel}>
              Cancel
            </Button>
            <Button type='submit' isLoading={isPending}>
              Save
            </Button>
          </ButtonsWrapper>
        </FormikForm>
      </FormWrapper>
      {isPinCodeOpen && (
        <CheckPinCodeModal
          isOpen={isPinCodeOpen}
          eventScope={PIN_TOKEN_EVENT_SCOPE.CREATE_OPCO_USER}
          onSuccess={checkPinCodeSuccess}
          onClose={closePinCode}
        />
      )}
    </>
  );
};
