import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { getBillingPlansUrl } from '../urls/getBillingPlansUrls';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { BillingPlanDetailCardWrapper, BillingPlanDetailMenuButtonWrapper } from './styles';
import { BillingPlanDetailsInfo } from './ui/BillingPlanDetailsInfo/BillingPlanDetailsInfo';
import { BillingPlanDetailsLimits } from './ui/BillingPlanDetailsLimits/BillingPlanDetailsLimits';
import { BillingPlanDetailsAddons } from './ui/BillingPlanDetailsAddons/BillingPlanDetailsAddons';
import { BillingPlanDetailsDiscounts } from './ui/BillingPlanDetailsDiscounts/BillingPlanDetailsDiscounts';
import { BillingBasePlanDetailsInfo } from './ui/BillingBasePlanDetailsInfo/BillingBasePlanDetailsInfo';
import { PopoverMenu } from '@/components/PopoverMenu/PopoverMenu';
import { useState } from 'react';
import { PopoverMenuItem } from '@/components/PopoverMenu/PopoverMenuItem';
import { ReactComponent as MenuEditIcon } from './images/MenuEditIcon.svg';
import { ReactComponent as MenuArchiveIcon } from './images/MenuArchiveIcon.svg';
import { changeBillingPlanStatus, loadBillingPlanDetails } from './api';
import { Button } from '@/components/Button/Button';
import { BILLING_PLAN_STATUS } from '@/const/billing';
import { CheckPinCodeModal } from '@/bundle/shared/components/CheckPinCodeModal/CheckPinCodeModal';
import { PIN_TOKEN_EVENT_SCOPE } from '@/const/shared';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { Box } from '@/components/Box/Box';
import { BillingPlanDetailsHeader } from './ui/BillingPlanDetailsHeader/BillingPlanDetailsHeader';

export const BillingPlanDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isPinCodeOpen, setIsPinCodeOpen] = useState(false);

  const { data: billingPlanData, isPending: isPendingBillingPlan } = useQuery({
    queryKey: ['load_billing_plan_details', id],
    queryFn: () => loadBillingPlanDetails(id),
  });

  const { mutate: mutatePublishBillingPlan, isPending: isPendingPublishBillingPlan } = useMutation({
    mutationKey: ['publish_billing_plan'],
    mutationFn: (pinToken: string) => {
      return changeBillingPlanStatus(id, BILLING_PLAN_STATUS.DRAFT, pinToken);
    },
    onSuccess: (publishBillingPlanResponse) => {
      if (publishBillingPlanResponse?.error) {
        const error = getResponseError(publishBillingPlanResponse?.error);

        showSnackbar(error, { variant: 'error' });

        return;
      }

      queryClient.invalidateQueries({ queryKey: ['load_billing_plan_details', id] });
    },
  });

  const closePinCode = () => {
    setIsPinCodeOpen(false);
  };

  const checkPinCodeSuccess = (pinToken: string) => {
    mutatePublishBillingPlan(pinToken);
    closePinCode();
  };

  const plan = billingPlanData?.body;
  const addons = plan?.payment_service_plan_data?.add_ons || [];
  const discounts = plan?.payment_service_plan_data?.discounts || [];
  const isDraftPlanStatus = plan?.status === BILLING_PLAN_STATUS.DRAFT;

  return (
    <ContentLayout
      title='Plan detail page'
      backButton='Back to Billing Plans'
      onBack={() => navigate(getBillingPlansUrl())}
    >
      <PageBody>
        {plan && (
          <BillingPlanDetailCardWrapper>
            <Box width='100%' display='flex' columnGap='20px' justifyContent='space-between'>
              <BillingPlanDetailsHeader plan={plan} />
              <BillingPlanDetailMenuButtonWrapper>
                {isDraftPlanStatus && !isPendingBillingPlan && (
                  <Button size='medium' isLoading={isPendingPublishBillingPlan} onClick={() => setIsPinCodeOpen(true)}>
                    Publish
                  </Button>
                )}
                <PopoverMenu isOpen={isOpenMenu} onToggleOpen={setIsOpenMenu}>
                  <PopoverMenuItem icon={<MenuEditIcon />}>Edit</PopoverMenuItem>
                  <PopoverMenuItem icon={<MenuArchiveIcon />}>Archive</PopoverMenuItem>
                </PopoverMenu>
              </BillingPlanDetailMenuButtonWrapper>
            </Box>
            <BillingPlanDetailsInfo plan={plan} />
            <BillingPlanDetailsLimits plan={plan} />
            <BillingBasePlanDetailsInfo plan={plan} />
            <BillingPlanDetailsAddons addons={addons} />
            <BillingPlanDetailsDiscounts discounts={discounts} />
          </BillingPlanDetailCardWrapper>
        )}

        {isPinCodeOpen && (
          <CheckPinCodeModal
            isOpen={isPinCodeOpen}
            eventScope={PIN_TOKEN_EVENT_SCOPE.UPDATE_BILLING_PLAN_STATUS}
            onSuccess={checkPinCodeSuccess}
            onClose={closePinCode}
          />
        )}
      </PageBody>
    </ContentLayout>
  );
};
