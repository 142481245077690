import { useEffect } from 'react';
import { AuthLayout } from '@/bundle/Auth/ui/AuthLayout/AuthLayout';
import { SwitchRole } from '@/bundle/pages/SwitchRolePage/ui/SwitchRole';
import { useUser } from '@/context/userContext';
import { useNavigate } from 'react-router-dom';
import { UserRoleType } from '@/const/user';
import { DASHBOARD_REDIRECT_MAP } from '../../Auth/LoginFlow/LoginPage/const/const';
import { UpsellBanner } from '@/bundle/shared/components/UpsellBanner/UpsellBanner';
import { getAccessDeniedUrl } from '../AccessDeniedPage/urls/getAccessDeniedUrl';
import { AMPLITUDE_USER_ROLE_LABEL, amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { useHasOrgAdminRole } from '@/bundle/shared/hooks/useHasOrgAdminRole';
import { authTokenService } from '../../Auth/LoginFlow/LoginPage/service/authTokenService';
import { decodeToken, getActiveUserRoles } from '@/helpers/tokenHelpers';
import { AccessTokenType } from '@/types/sharedTypes';

export const SwitchRolePage = () => {
  const navigate = useNavigate();
  const { hasMultipleUserRoles, setRole, currentRole } = useUser();
  const { hasOrgAdminRole } = useHasOrgAdminRole();
  const tokens = authTokenService.getTokens();

  useEffect(() => {
    if (!hasMultipleUserRoles) {
      return navigate(getAccessDeniedUrl());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMultipleUserRoles]);

  if (!tokens || !tokens?.access) return;

  const { roles } = decodeToken<AccessTokenType>(tokens?.access);
  const activeRoles = getActiveUserRoles(roles);
  // TODO v16.0.0 remove old token
  // const { active_roles } = decodeToken<AccessTokenType>(tokens?.access);
  // const activeRoles = active_roles;

  const switchRole = (selectedRole: UserRoleType) => {
    setRole(selectedRole);

    const redirectUrl = DASHBOARD_REDIRECT_MAP[selectedRole];

    amplitudeService.logEvent(AMPLITUDE_EVENTS.SwitchRoleSelectDashboardSuccess, {
      selectedRole: AMPLITUDE_USER_ROLE_LABEL[selectedRole],
    });

    navigate(redirectUrl);
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const sendUpsellRequest = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.UpsellSwitchRoleAfterLogin, {
      role: AMPLITUDE_USER_ROLE_LABEL[currentRole],
    });
  };

  return (
    <AuthLayout banner={hasOrgAdminRole ? null : <UpsellBanner isClosable={false} onSent={sendUpsellRequest} />}>
      <SwitchRole activeRoles={activeRoles} onBack={navigateBack} onSelect={switchRole} />
    </AuthLayout>
  );
};
