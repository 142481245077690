import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { ProgressBar } from '@/components/ProgressBar/ProgressBar';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { ReactComponent as UpgradeIcon } from '@/images/UpgradeIcon.svg';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { PaymentSettingsCard } from './ui/PaymentSettingsCard/PaymentSettingsCard';
import { useState } from 'react';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { Notification } from '@/components/Notification/Notification';
import { getSelectBillingPlanUrl } from '@/bundle/pages/SelectBillingPlanPage/urls/getSelectBillingPlanUrl';
import { SubscriptionType } from '@/types/paymentTypes';
import { BILLING_FREQUENCY_LABEL_MAP, BILLING_FREQUENCY_MAP } from '@/const/payment';
import { convertCentsToDollars } from '@/helpers/formatHelpers';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';
import { useQuery } from '@tanstack/react-query';
import { getBillingPlanById } from '../../api';
import { getPlanName } from '@/helpers/billingHelpers';
import capitalize from 'lodash/capitalize';

type PaymentPlanDetailsType = {
  subscription: SubscriptionType;
};

export const PaymentPlanDetails = ({ subscription }: PaymentPlanDetailsType) => {
  const [upgradePlanDialogOpen, setUpgradePlanDialogOpen] = useState(false);
  const navigate = useNavigate();

  const navigateToPaymentPlans = () => {
    navigate({
      pathname: getSelectBillingPlanUrl(),
      search: createSearchParams({
        redirected_from: 'app',
        plan_id: subscription?.plan_id,
      }).toString(),
    });
  };

  // TODO v16.0.0: use plan name from subscription data from BE will be ready
  const { data: paymentPlanDetails } = useQuery({
    queryKey: ['get_billing_plan_by_id'],
    queryFn: () => getBillingPlanById(subscription.plan_id),
    enabled: !!subscription.plan_id,
  });

  const planData = paymentPlanDetails?.body?.results?.at(0);
  const planName = getPlanName(planData?.name) || '';

  const billingFrequency = BILLING_FREQUENCY_MAP[subscription.billing_frequency];
  const billingFrequencyLabel = BILLING_FREQUENCY_LABEL_MAP[subscription.billing_frequency];
  const subscriptionAmount = convertCentsToDollars(subscription.amount);
  const nextPaymentSubscriptionDate = formatDate(subscription.next_bill_date);

  return (
    <Box display='flex' columnGap='24px' mb='24px'>
      <PaymentSettingsCard
        variant='dark'
        header={`${capitalize(planName)} Plan`}
        subHeader='You can upgrade plan anytime to get higher limits and more features.'
      >
        <Box display='flex' flexDirection='column' mt='12px' rowGap={24}>
          <Box display='flex' flexDirection='column' rowGap={8}>
            <Box display='flex' alignItems='center' justifyContent='space-between'>
              <Typography color={COLORS.black} variant='bodySmall'>
                Billing Frequency
              </Typography>
              <Typography color={COLORS.black} variant='bodySmall' fontWeight='medium'>
                {billingFrequency}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' justifyContent='space-between'>
              <Typography color={COLORS.black} variant='bodySmall'>
                Total per {billingFrequencyLabel}
              </Typography>
              <Typography color={COLORS.black} variant='bodySmall' fontWeight='medium'>
                {subscriptionAmount}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' justifyContent='space-between'>
              <Typography color={COLORS.black} variant='bodySmall'>
                Next Payment
              </Typography>
              <Typography color={COLORS.black} variant='bodySmall' fontWeight='medium'>
                {nextPaymentSubscriptionDate}
              </Typography>
            </Box>
          </Box>
          <Button onClick={() => setUpgradePlanDialogOpen(true)}>
            <Box mr='10px'>
              <UpgradeIcon />
            </Box>
            Upgrade
          </Button>
        </Box>
      </PaymentSettingsCard>
      <PaymentSettingsCard header='Usage' subHeader='Description text about usage'>
        <Box display='flex' flexDirection='column' height='100%'>
          <Box display='flex' flexDirection='column' rowGap={12} mt='16px'>
            <ProgressBar label='Collect or confirm wire info' count={1} totalCount={3} />
            <ProgressBar label='Users' count={0} totalCount={3} />
          </Box>
          <Box mt='auto'>
            <Button variant='secondary' onClick={navigateToPaymentPlans}>
              View Plans
            </Button>
          </Box>
        </Box>
      </PaymentSettingsCard>

      {/* TODO v16.0.0: remove this dialog when upgrade feature will be clarified   */}
      <ConfirmModal
        isOpen={upgradePlanDialogOpen}
        body={<Notification variant='error'>This feature is not available yet.</Notification>}
        header='IN DEVELOPMENT'
        confirmText='Ok'
        confirmation={null}
        onClose={() => setUpgradePlanDialogOpen(false)}
        onConfirm={() => setUpgradePlanDialogOpen(false)}
      ></ConfirmModal>
    </Box>
  );
};
