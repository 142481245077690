import { ContentLayout } from '../../../Layouts/ContentLayout/ContentLayout';
import { Paginator } from '../../../../components/Paginator/Paginator';
import { PageBody } from '../../../../components/Page/PageBody/PageBody';
import { PageFooter } from '../../../../components/Page/PageFooter/PageFooter';
import { BilledEventsTable } from './BilledEventsTable';
import { getBilledEventsList } from './helpers/helpers';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import { loadBilledEvents, downloadBillingFile } from './api';
import { useEffect } from 'react';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { Button } from '@/components/Button/Button';
import { saveAs } from 'file-saver';
import { loadOrganizations } from '../../api';
import { useGetSearchParams } from '@/hooks/useGetSearchParams';
import { FilterList } from './ui/FilterList/FilterList';
import { useBilledEventsFilters } from './hooks/useBilledEventsFilters/useBilledEventsFilters';

const ORGANIZATION_LIMIT = 1000;

export const BilledEventsPage = () => {
  const searchParams = useGetSearchParams();

  const { data: organizationsData } = useQuery({
    queryKey: ['load_organizations'],
    queryFn: () => loadOrganizations({ limit: ORGANIZATION_LIMIT }),
    placeholderData: keepPreviousData,
  });

  const organizations = organizationsData?.body?.results;

  const { queryFilters, filters, applyFilters, clearFilters } = useBilledEventsFilters(organizations);

  const { data: billedEventsData } = useQuery({
    queryKey: ['load_billed_events', searchParams],
    queryFn: () =>
      loadBilledEvents({
        ...searchParams,
        filters: queryFilters,
      }),
    placeholderData: keepPreviousData,
  });

  const { mutate, isPending } = useMutation({
    mutationKey: ['download_billing_file', queryFilters],
    mutationFn: () => {
      const payload = {
        ...queryFilters,
        ordering: '-date_created',
      };

      return downloadBillingFile(payload);
    },
    onSuccess: (response) => {
      if (response?.error) {
        const error = getResponseError(response?.error);

        showSnackbar(error, { variant: 'error' });

        return;
      }

      const headers = response.headers.contentDisposition;
      const filename = headers.replace('attachment; filename=', '') || 'Billing.xlsx';

      saveAs(response?.body, filename);
    },
  });

  useEffect(() => {
    const billedEventsError = billedEventsData?.error;

    if (!billedEventsError) return;

    const error = getResponseError(billedEventsError) || getResponseError(billedEventsError, 'date_created');

    showSnackbar(error, { variant: 'error', autoClose: false });
  }, [billedEventsData?.error]);

  const billedEvents = billedEventsData?.body?.results;
  const billedEventsCount = billedEventsData?.body?.count;

  const billedEventsList = getBilledEventsList(billedEvents);
  const hasPaginator = billedEventsCount > searchParams?.limit;

  return (
    <ContentLayout
      title='Billing'
      subtitle={
        <>
          Please choose the wires that were viewed by an administrator during the selected time period.
          <br />
          By default wires that were viewed in the previous billing period are displayed. This wires will be included in
          the report.
        </>
      }
      headerAction={
        <Button width={140} onClick={mutate} mobileStretch disabled={isPending || !billedEventsCount}>
          {isPending ? '...' : 'Export'}
        </Button>
      }
    >
      <PageBody>
        {filters && (
          <FilterList
            filters={filters}
            organizations={organizations}
            onApplyFilters={applyFilters}
            onClearFilters={clearFilters}
          />
        )}
        <BilledEventsTable items={billedEventsList} />
      </PageBody>
      {hasPaginator && (
        <PageFooter>
          <Paginator page={searchParams.page} limit={searchParams.limit} total={billedEventsCount} />
        </PageFooter>
      )}
    </ContentLayout>
  );
};
