import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { body, poppinsRegularFont } from '@/styles/typography';
import { customVerticalScroll } from '@/styles/common';

export const CustomTextArea = styled.textarea<{ hasError: boolean }>`
  display: block;
  width: 100%;
  max-height: 200px;
  resize: none;
  padding: 13px 16px;
  border: ${({ hasError }) => (hasError ? `1px solid ${COLORS.red[600]}` : `1px solid ${COLORS.grey[400]}`)};
  border-radius: 10px;
  color: ${COLORS.black};
  background: ${COLORS.grey[250]};
  ${body};
  ${poppinsRegularFont};

  ::placeholder {
    ${body}
    ${poppinsRegularFont}
    color: ${COLORS.grey[600]}
  }

  &:hover {
    border: 1px solid ${COLORS.grey[700]};
    background: ${COLORS.grey[250]};
  }

  &:focus {
    border: 1px solid ${COLORS.green[500]};
    box-shadow: 0 0 2px rgb(0 134 111 / 60%);
    background: ${COLORS.white};
    outline: none;
  }

  &:disabled {
    border: 1px solid ${COLORS.grey[300]};
    background-color: ${COLORS.grey[200]};
  }

  ${customVerticalScroll};

  &::-webkit-scrollbar-track {
    margin: 10px 0;
  }
`;
