import { COLORS } from '@/styles/colors';
import { flex, flexCenterColumn } from '@/styles/layout';
import { body, poppinsSemiBoldFont, subTitle } from '@/styles/typography';
import styled from 'styled-components';

export const AppErrorWrapper = styled.div`
  width: 100%;
  height: 90vh;
  background-color: ${COLORS.white};
  ${flexCenterColumn};
  border-radius: 10px;
`;

export const ErrorTitle = styled.span`
  ${poppinsSemiBoldFont};
  ${subTitle}
  margin-top: 25px;
`;

export const ErrorMessage = styled.span`
  ${body};
  margin-top: 5px;
`;

export const ButtonWrapper = styled.div`
  ${flex({ justifyContent: 'center' })};
  margin-top: 32px;
  margin-bottom: 6px;
`;
