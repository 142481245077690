import { Header, SubHeader } from '@/bundle/_Recipient/_Details/ui/ProxyChoosePaymentDetailsProvider/styles';
import { Box } from '@/components/Box/Box';
import {
  INVITE_RECIPIENT_OPTION,
  PROXY_PROVIDE_WIRE_INFO_OPTIONS,
  ProxyProvidePaymentDetailsOptionType,
} from '@/bundle/_Recipient/_Details/ui/ProxyChoosePaymentDetailsProvider/const';
import { SelectableTabs } from '@/components/form/fields/SelectableTabs/SelectableTabs';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { useState } from 'react';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { Button } from '@/components/Button/Button';

interface ProxyChoosePaymentDetailsProviderType {
  value: ProxyProvidePaymentDetailsOptionType;
  onSelect: (option: ProxyProvidePaymentDetailsOptionType) => void;
  onConfirm: () => void;
}

export const ProxyChoosePaymentDetailsProvider = ({
  value,
  onSelect,
  onConfirm,
}: ProxyChoosePaymentDetailsProviderType) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);

  const confirmChoice = () => {
    closeModal();
    onConfirm();
  };

  const chosePaymentDetailsProviderOption = () => {
    if (value === INVITE_RECIPIENT_OPTION) {
      onConfirm();

      return;
    }

    // Open modal only when recipient proxy provides payment details on behalf for recipient
    openModal();
  };

  return (
    <>
      <DetailsWidget
        hasHighlight
        header={
          <Box flexDirection='column' rowGap='8px' width='100%'>
            <Header>How will you provide wire info?</Header>
            <SubHeader>Please choose how you will provide info for this payment.</SubHeader>
          </Box>
        }
      >
        <Box mt='20px' mb='24px' width='100%'>
          <SelectableTabs options={PROXY_PROVIDE_WIRE_INFO_OPTIONS} onSelect={onSelect} value={value} />
        </Box>
        <Box display='flex' justifyContent='flex-end'>
          <Button onClick={chosePaymentDetailsProviderOption} disabled={!value} width={200} mobileStretch>
            Choose
          </Button>
        </Box>
      </DetailsWidget>
      <ConfirmModal
        isOpen={isOpen}
        header='Confirm your choice'
        body='You are providing wire info that was collected outside of WireVault.'
        onClose={closeModal}
        onConfirm={confirmChoice}
      />
    </>
  );
};
