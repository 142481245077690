import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import { bodySmall, poppinsMediumFont } from '@/styles/typography';
import styled from 'styled-components';

export const PlanExtensionsWrapper = styled.div`
  ${flex({ justifyContent: 'center', flexDirection: 'column' })};
  border-radius: 8px;
  border: 1px solid ${COLORS.grey[300]};
`;

export const PlanExtensionTitle = styled.div`
  width: 80%;
  padding: 14px 12px;
  border-right: 1px solid ${COLORS.grey[300]};
`;

export const PlanExtensionPrice = styled.div`
  width: 20%;
  padding: 14px 12px;
`;

export const PlanExtensionRow = styled.div`
  ${flex({ alignItems: 'center' })};
  border-bottom: 1px solid ${COLORS.grey[300]};
  color: ${COLORS.black};
  ${poppinsMediumFont};
  ${bodySmall};

  &:last-child {
    border-bottom: none;
  }
`;
