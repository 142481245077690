import { ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { ModalBlock, ModalBlockHeader, ModalWrapper, ModalContainer, ModalOverlay } from './styles';
import FocusLock from 'react-focus-lock';

type ModalWindowType = {
  isOpen: boolean;
  headerText?: string;
  children: ReactNode;
  top?: number;
  isStartPositionHeader?: boolean;
};

const ANIMATION_TIMEOUT = 150;

export const ModalWindow = ({ isOpen, headerText, children, top, isStartPositionHeader }: ModalWindowType) => {
  const nodeRef = useRef(null);

  return createPortal(
    <CSSTransition
      in={isOpen}
      nodeRef={nodeRef}
      timeout={{ enter: ANIMATION_TIMEOUT, exit: 0 }}
      classNames='fade'
      unmountOnExit
    >
      <FocusLock>
        <ModalWrapper>
          <ModalOverlay />
          <ModalContainer>
            <ModalBlock top={top} ref={nodeRef}>
              {headerText && <ModalBlockHeader isStartPosition={isStartPositionHeader}>{headerText}</ModalBlockHeader>}
              {children}
            </ModalBlock>
          </ModalContainer>
        </ModalWrapper>
      </FocusLock>
    </CSSTransition>,
    document.getElementById('modal')
  );
};
