import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '@/components/Card/Card';
import { DepositAccountStatus } from './DepositAccountStatus/DepositAccountStatus';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';
import { getDepositAccountDetailsUrl } from '../_Details/urls/getDepositAccountDetailsUrl';
import { DepositAccountPaymentDetailsType } from '@/api/v1/organization/deposit-accounts/getDepositAccountsApi';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';

type DepositAccountsListType = {
  list: DepositAccountPaymentDetailsType[];
};

export const DepositAccountsList = ({ list }: DepositAccountsListType) => {
  const navigate = useNavigate();

  const navigateToDetails = (item) => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.ViewDetailsDepositAccount);

    navigate(getDepositAccountDetailsUrl(item.id));
  };

  return (
    <>
      {list.map((item) => {
        const { name, status, created_by, payment_details, id } = item;
        const createdBy = created_by?.email;
        const bankAccountNumber = payment_details?.bank_account_number;
        const bankName = payment_details?.bank_name;
        const formattedCreatedDate = formatDate(item.date_created);

        return (
          <Card
            key={id}
            title={name}
            header={name}
            meta={<DepositAccountStatus status={status} />}
            onClick={() => navigateToDetails(item)}
          >
            <CardContent name='Created by' ratio={2}>
              {createdBy}
            </CardContent>
            <CardContent name='Date Created'>{formattedCreatedDate}</CardContent>
            <CardContent name='Account Number'>{bankAccountNumber || '-'}</CardContent>
            <CardContent name='Bank Name' ratio={2}>
              {bankName || '-'}
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};
