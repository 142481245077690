import { media } from '@/styles/responsive';
import { styled } from 'styled-components';

export const DividerWrapper = styled.div`
  margin: 0 -28px;

  ${media.mobile`
    margin: 0 -16px;
  `}
`;
