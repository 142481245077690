import { NavLinkProps } from 'react-router-dom';
import { NavigationLinkVariantType, NavigationLinkWrapper } from './styles';

type NavigationLinkType = NavLinkProps & {
  variant?: NavigationLinkVariantType;
  underline?: boolean;
};

export const NavigationLink = ({ children, variant = 'primary', underline, ...props }: NavigationLinkType) => {
  return (
    <NavigationLinkWrapper variant={variant} $underline={underline} {...props}>
      {children}
    </NavigationLinkWrapper>
  );
};
