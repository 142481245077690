import { BillingServicePlanDataAddonOrDiscount } from '@/api/v1/opco/billing/getBillingPlansApi';
import { PlanExtensionsWrapper, PlanExtensionRow, PlanExtensionTitle, PlanExtensionPrice } from './styles';
import { formatAddonOrDiscountAmount } from '@/bundle/_Opco/_BillingPage/_BillingPlansPage/helpers';

type PlanExtensionsType = {
  extensions: BillingServicePlanDataAddonOrDiscount[];
};
export const PlanExtensions = ({ extensions = [] }: PlanExtensionsType) => {
  return (
    <PlanExtensionsWrapper>
      {extensions.map((extension, index) => {
        const amount = formatAddonOrDiscountAmount(extension);

        return (
          <PlanExtensionRow key={index}>
            <PlanExtensionTitle>{extension.name}</PlanExtensionTitle>
            <PlanExtensionPrice>{amount}</PlanExtensionPrice>
          </PlanExtensionRow>
        );
      })}
    </PlanExtensionsWrapper>
  );
};
