import { UserInfoType } from '@/api/v1/organization/users/getUserInfoApi';
import { useEffect } from 'react';
import { formatPhone } from '@/helpers/formatHelpers';
import { FormikProps, FormikValues } from 'formik/dist/types';
import { FormValues } from '@/helpers/formHelpers/formHelpers';
import { AutocompleteOptionType } from '@/components/Autocomplete/types';

export const useAssignUser = (
  userInfo: UserInfoType | null,
  formik: FormikProps<FormikValues>,
  onReset: () => void,
  selectedOption: AutocompleteOptionType,
  isAvailableToAssign: boolean
) => {
  const isSelectedOption = selectedOption?.isSelectedOption;

  const setFormValues = (formValues: Partial<FormValues>) => {
    formik.setValues({
      ...formik.values,
      ...formValues,
    });
  };

  // Reset form when user clears last char in search
  useEffect(() => {
    if (!selectedOption) return;

    if (!formik?.values.email) {
      setFormValues({
        mobile_phone: '',
        first_name: '',
        middle_name: '',
        last_name: '',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.email, selectedOption]);

  // Reset from when selected option removed from the autocomplete
  useEffect(() => {
    if (isSelectedOption) return;

    onReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectedOption]);

  // Fill form when autocomplete user selected from options and user info loaded
  useEffect(() => {
    if (!isSelectedOption || !userInfo) return;

    const { first_name, middle_name, last_name, mobile_phone } = userInfo;

    setFormValues({
      mobile_phone: formatPhone(mobile_phone),
      first_name,
      middle_name,
      last_name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectedOption, userInfo]);

  // Clear form when autocomplete user is not available to assign
  useEffect(() => {
    if (!isAvailableToAssign) {
      formik.setErrors({});

      setFormValues({
        mobile_phone: '',
        first_name: '',
        middle_name: '',
        last_name: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvailableToAssign]);
};
