import styled from 'styled-components';
import { inputClearButtonVisibility } from '../../Input/ui/InputClearButton/styles';

export const SelectMonthWrapper = styled.div`
  width: 60%;
`;

export const SelectYearWrapper = styled.div`
  width: 40%;
`;

export const DatePickerInputWrapper = styled.div`
  position: relative;
  ${inputClearButtonVisibility()};
`;
