import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { AuthStep } from '../../ui/AuthStep/AuthStep';
import { ResetRequest } from '@/bundle/shared/components/ResetRequest/ResetRequest';
import { resetRequestFromEmail } from './api';
import { useState } from 'react';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { getLoginUrl } from '../../LoginFlow/LoginPage/urls/getLoginUrl';
import { ACTIVATION_TOKEN_INVALID_RESPONSE } from '@/api/const';
import { getLinkExpiredUrl } from '../../pages/LinkExpiredPage/urls/getLinkExpiredUrl';

export const ResetRequestFromEmailPage = () => {
  const { userId, token } = useParams();
  const navigate = useNavigate();

  const [confirmationStep, setConfirmationStep] = useState(true);

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['reset_request_from_email'],
    mutationFn: () => {
      return resetRequestFromEmail(userId, token);
    },
    onSuccess(resetRequestResponse) {
      const error = resetRequestResponse?.error;

      if (error) {
        if (error?.errorContent === ACTIVATION_TOKEN_INVALID_RESPONSE) {
          navigate(getLinkExpiredUrl());
        }

        return;
      }

      setConfirmationStep(false);
    },
  });

  const error = getResponseError(data?.error);

  const redirectToLogin = () => {
    navigate(getLoginUrl());
  };

  return (
    <AuthLayout>
      <AuthStep>
        <ResetRequest
          confirmationStep={confirmationStep}
          isLoading={isPending}
          error={error}
          onConfirm={mutate}
          onSuccess={redirectToLogin}
          onCancel={redirectToLogin}
        />
      </AuthStep>
    </AuthLayout>
  );
};
