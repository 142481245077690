import { inputClearButtonVisibility } from '@/components/form/fields/Input/ui/InputClearButton/styles';
import { COLORS } from '@/styles/colors';
import { flexCenter } from '@/styles/layout';
import styled from 'styled-components';

export const SearchInputWrapper = styled.form`
  position: relative;
  width: 100%;
  ${inputClearButtonVisibility()};
`;

export const IconFiltersWrapper = styled.div<{ selected?: boolean }>`
  width: 44px;
  height: 100%;
  ${flexCenter};
  border-left: 1px solid ${COLORS.grey[400]};
  cursor: pointer;
  background: ${COLORS.grey[200]};

  ${({ selected }) =>
    selected &&
    `
      svg {
        color: ${COLORS.black};
      }
  `}

  &:hover {
    background: ${COLORS.grey[300]};
    svg {
      color: ${COLORS.black};
    }
  }
`;
