import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ContentLayout } from '../../Layouts/ContentLayout/ContentLayout';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { PageColumn } from '@/components/Page/PageColumn/PageColumn';
import { PageContent } from '@/components/Page/PageContent/PageContent';
import { WIRE_STATUS_DB } from '@/const/wire';
import { changeDepositorWireStatus, loadDepositorPaymentDetails, loadDepositorWire } from './api';
import { isCancelledWireStatus, isDepositSentWireStatus } from '@/helpers/wireHelpers';
import { Box } from '@/components/Box/Box';
import { WaterMark } from '@/bundle/shared/components/WaterMark/WaterMark';
import { getDepositorWiresUrl } from '../urls/getDepositorWiresUrl';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { WireDetails } from '@/bundle/shared/components/WireDetails';
import { Button } from '@/components/Button/Button';
import { WireStatusType } from '@/types/wireTypes';
import { UserDetailsWidget } from '@/bundle/shared/components/UserDetailsWidget/UserDetailsWidget';
import { PaymentDetailsDataWidget } from '@/bundle/shared/components/PaymentDetailsDataWidget/PaymentDetailsDataWidget';

export const NOT_ALLOWED_TO_EXECUTE_WIRE_STATUSES: Partial<WireStatusType>[] = [
  WIRE_STATUS_DB.CANCELLED,
  WIRE_STATUS_DB.DEPOSIT_SENT,
];

export const DepositorWireDetailsPage = () => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [isOpenSendModal, setIsOpenSendModal] = useState(false);

  const { data: depositorWireData, isPending: isPendingWire } = useQuery({
    queryKey: ['load_depositor_wire', id],
    queryFn: () => loadDepositorWire(id),
  });

  const { data: depositorPaymentDetailsData, isFetching: isFetchingPaymentDetails } = useQuery({
    queryKey: ['load_depositor_payment_details', id],
    queryFn: () => loadDepositorPaymentDetails(id),
  });

  const { mutate, isPending: isPendingSendDeposit } = useMutation({
    mutationKey: ['send_deposit'],
    mutationFn: () => {
      return changeDepositorWireStatus(id, WIRE_STATUS_DB.DEPOSIT_SENT);
    },
    onSuccess(updatedDepositorWire) {
      setIsOpenSendModal(false);

      if (updatedDepositorWire.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.ExecutedWireDepositorSuccess);

      queryClient.setQueryData(['load_depositor_wire', id], updatedDepositorWire);
    },
  });

  const executeWire = () => {
    setIsOpenSendModal(true);
    amplitudeService.logEvent(AMPLITUDE_EVENTS.ExecutedWireDepositorRedirect);
  };

  const onClose = () => {
    setIsOpenSendModal(false);
  };

  const navigateToWires = () => {
    navigate(getDepositorWiresUrl());
  };

  const depositorWire = depositorWireData?.body;
  const wireStatus = depositorWire?.status;
  const depositorPaymentDetails = depositorPaymentDetailsData?.body;
  const assignedUser = depositorWire?.assigned_depositor?.user;
  const isDepositSentWire = isDepositSentWireStatus(wireStatus);
  const isCancelledWire = isCancelledWireStatus(wireStatus);
  const showWaterMark = isDepositSentWire || isCancelledWire;
  const isAllowedToExecuteWire = !NOT_ALLOWED_TO_EXECUTE_WIRE_STATUSES.includes(wireStatus) && !isPendingWire;

  return (
    <ContentLayout
      title='Wire Details'
      headerAction={
        isAllowedToExecuteWire && (
          <Button width={200} mobileStretch onClick={executeWire}>
            Wire Executed
          </Button>
        )
      }
      backButton='Back to Wires'
      onBack={navigateToWires}
    >
      <PageBody>
        <PageContent>
          <PageColumn>
            {depositorWire && (
              <Box position='relative'>
                <WireDetails wire={depositorWire} />
                {showWaterMark && <WaterMark wireStatus={wireStatus} />}
              </Box>
            )}
            {assignedUser && <UserDetailsWidget header='Depositor' user={assignedUser} />}
          </PageColumn>
          <PageColumn>
            <PaymentDetailsDataWidget
              paymentDetails={depositorPaymentDetails}
              isLoading={isFetchingPaymentDetails}
              apiError={depositorWireData?.error}
            />
          </PageColumn>
        </PageContent>
      </PageBody>

      <ConfirmModal
        isOpen={isOpenSendModal}
        header='Send Deposit'
        body='You are indicating that the deposit for this wire has been sent. The recipient of this deposit will be notified.'
        onClose={onClose}
        onConfirm={mutate}
        isLoading={isPendingSendDeposit}
      />
    </ContentLayout>
  );
};
