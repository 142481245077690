import { authApiService } from '@/api/authApiService';
import { BillingPlanStatusType } from '@/const/billing';
import { ApiResponseType, RequestOptions } from '@/types/sharedTypes';

type ChangeBillingPlanStatusPayload = {
  status: BillingPlanStatusType;
};

export const changeBillingPlanStatusApi = async (
  id: string,
  payload: ChangeBillingPlanStatusPayload,
  options?: RequestOptions
): Promise<ApiResponseType<any>> => {
  const uri = `/opco/payments/plans/${id}/status/`;

  return authApiService.POST(uri, payload, null, options);
};
