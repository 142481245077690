import { authApiService } from '@/api/authApiService';
import { CreateSubscriptionPayloadType, SubscriptionType } from '@/types/paymentTypes';
import { ApiResponseType } from '@/types/sharedTypes';

export const createSubscriptionApi = async (
  data: CreateSubscriptionPayloadType
): Promise<ApiResponseType<SubscriptionType>> => {
  const uri = '/organization/payments/subscriptions/';

  return authApiService.POST(uri, data);
};
