import { ChangeEvent } from 'react';
import { useField } from 'formik';
import { RadioGroup, RadioOptionType } from '../RadioGroup/RadioGroup';

interface FormikRadioGroupType {
  label: string;
  name: string;
  options: RadioOptionType[];
  disabled?: boolean;
}

export const FormikRadioGroup = ({ label, name, options, disabled }: FormikRadioGroupType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, _, { setValue }] = useField(name);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <RadioGroup
      {...field}
      label={label}
      value={field.value}
      onChange={handleChange}
      options={options}
      disabled={disabled}
    />
  );
};
