import { ChangeEvent, ReactNode } from 'react';
import {
  SwitchToggleWrapper,
  SwitchToggleInput,
  SwitchToggleFakeInput,
  SwitchToggleInputWrapper,
  SwitchToggleLabel,
  SizeType,
} from './styles';
import { Box } from '@/components/Box/Box';
import { InputError } from '../InputError/InputError';

export type SwitchToggleType = {
  name: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: ReactNode;
  disabled?: boolean;
  size?: SizeType;
  hint?: ReactNode;
  hasErrorComponent?: boolean;
  error?: string;
  apiError?: string;
};

export const SwitchToggle = ({
  name = '',
  checked,
  label,
  disabled,
  onChange,
  size = 'medium',
  hint,
  hasErrorComponent = true,
  error,
  apiError,
}: SwitchToggleType) => {
  return (
    <SwitchToggleWrapper>
      <SwitchToggleLabel>
        {label && <>{label}</>}
        <SwitchToggleInputWrapper size={size}>
          <SwitchToggleInput
            name={name}
            type='checkbox'
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            size={size}
          />
          <SwitchToggleFakeInput disabled={disabled} size={size} />
        </SwitchToggleInputWrapper>
      </SwitchToggleLabel>

      {hint && <Box mt='4px'>{hint}</Box>}

      {hasErrorComponent && <InputError error={error} apiError={apiError} />}
    </SwitchToggleWrapper>
  );
};
