import { ReactNode } from 'react';
import { Form, FormikProps, FormikProvider } from 'formik';

type FormikFormPropsType = {
  value: FormikProps<unknown>;
  children?: ReactNode;
};

export const FormikForm = ({ value, children }: FormikFormPropsType) => {
  return (
    <FormikProvider value={value}>
      <Form>{children}</Form>
    </FormikProvider>
  );
};
