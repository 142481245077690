import { authApiService } from '@/api/authApiService';
import { CustomerVaultType } from '@/types/paymentTypes';
import { ApiResponseType } from '@/types/sharedTypes';

type AddPaymentMethodPayloadType = {
  token: string;
};

export const addPaymentMethodApi = (
  payload: AddPaymentMethodPayloadType
): Promise<ApiResponseType<CustomerVaultType>> => {
  const uri = '/organization/payments/customer-vault/payment-method/';

  return authApiService.POST(uri, payload);
};
