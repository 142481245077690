import { ActiveUserRolesType, UserRolesStatusType } from '@/const/user';
import { AccessTokenType, AuthResponseType } from '@/types/sharedTypes';
import { jwtDecode } from 'jwt-decode';

export const decodeToken = <T>(token: string): T => {
  try {
    const decodedToken = jwtDecode(token);

    return decodedToken as T;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getActiveUserRoles = (roles?: UserRolesStatusType) => {
  if (!roles) {
    return [];
  }

  const userRoles = Object.keys(roles) as ActiveUserRolesType;

  return userRoles.filter((role) => roles[role]?.status === 'active');
};

export const getAccessToken = (body: AuthResponseType) => {
  const { access, refresh } = body;
  const { user_id } = decodeToken<AccessTokenType>(body?.access);
  const token = { access, refresh, user_id };

  return { token };
};
