import { useLocation } from 'react-router-dom';
import { Accordion } from '@/components/Accordion/Accordion';
import { SubMenuLink, SubMenuMarker, SubMenuSection, SubMenu, SubMenuHeader } from './styles';
import { MenuSectionTitle } from '../styles';
import { MenuType } from '@/bundle/Sidebar/hooks/useSidebar';
import { Box } from '@/components/Box/Box';
import { isActiveRoute } from '@/bundle/Sidebar/helpers/helpers';
import { useState } from 'react';

type SubMenuItemType = {
  menuItem: MenuType;
  onNavigate: () => void;
};

export const SubMenuItem = ({ menuItem, onNavigate }: SubMenuItemType) => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(true);
  const { title, icon, subMenu, paths } = menuItem;
  const Icon = icon;
  const isActiveMenuLink = !open && isActiveRoute(pathname, paths);

  return (
    <Accordion
      isOpen={open}
      as='li'
      header={(IconWrapper) => {
        return (
          <SubMenuHeader isActive={isActiveMenuLink} as='div'>
            <Box width='100%' height='100%' display='flex' alignItems='center' columnGap='12px'>
              <Box flexShrink={0}>
                <Icon />
              </Box>
              <MenuSectionTitle>{title}</MenuSectionTitle>
            </Box>
            {IconWrapper}
          </SubMenuHeader>
        );
      }}
      onChange={setOpen}
    >
      <SubMenuSection>
        {subMenu.map(({ title: subTitle, url: subUrl, paths: subPaths }) => {
          const isActiveSubMenuLink = isActiveRoute(pathname, subPaths);

          return (
            <SubMenu key={subTitle}>
              <SubMenuLink to={subUrl} $isActive={isActiveSubMenuLink} onClick={onNavigate}>
                <SubMenuMarker />
                {subTitle}
              </SubMenuLink>
            </SubMenu>
          );
        })}
      </SubMenuSection>
    </Accordion>
  );
};
