import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';
import styled from 'styled-components';

export const Wrapper = styled.div`
  ${media.mobile`
    padding: 24px 16px;  
  `}
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  ${flex({ alignItems: 'center', justifyContent: 'space-between' })};
  flex-wrap: nowrap;
`;

// TODO v16.0.0: find a better solution to solve aligning with optional side divs
export const SideAction = styled.div<{ isVisible?: boolean }>`
  width: 84px;
  opacity: 0;

  ${({ isVisible }) =>
    isVisible &&
    `
    opacity: 1;
  `};
`;
