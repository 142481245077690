import { CSSProperties } from 'react';

type FlexType = {
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  flexDirection?: CSSProperties['flexDirection'];
};

export const flex = ({ justifyContent, alignItems, flexDirection }: FlexType = {}) => `
  display: flex;
  ${flexDirection ? `flex-direction: ${flexDirection}` : ''};
  ${justifyContent ? `justify-content: ${justifyContent}` : ''};
  ${alignItems ? `align-items: ${alignItems}` : ''};
`;

export const flexCenter = flex({
  justifyContent: 'center',
  alignItems: 'center',
});

export const flexCenterColumn = flex({
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

export const getWidth = (width?: number | string) => {
  if (!width) {
    return '100%';
  }

  if (typeof width === 'number') {
    return `${width}px`;
  }

  return width;
};
