import { ButtonBlock, PaymentDetailsFormMessage, PaymentDetailsFormTitle, PaymentDetailsFormWrapper } from './styles';
import { useFormik } from 'formik';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { useEffect } from 'react';

import { Button } from '@/components/Button/Button';
import { CreateNewAccountLink } from '../CreateNewAccountLink/CreateNewAccountLink';
import { Select } from '@/components/form/fields/Select/Select';
import { loadDepositAccountsIds } from '../../api';
import { useQuery } from '@tanstack/react-query';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { ResponseErrorType } from '@/types/sharedTypes';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import {
  getPaymentDetailsInputConfig,
  PROVIDE_PAYMENT_DETAILS_INITIAL_VALUES,
} from '@/bundle/shared/components/ProvidePaymentDetailsForm/const';
import { FormikPhoneInput } from '@/components/form/fields/FormikPhoneInput/FormikPhoneInput';
import { getPaymentDetailsPayloadValues, getPaymentDetailsValidationSchema, getDepositAccountOptions } from './const';
import { FormDivider } from '@/components/form/FormDivider';
import { hasIntermediaryPaymentDetails } from '@/helpers/paymentDetailsHelpers';
import { PAYMENT_TYPE_DB } from '@/const/shared';
import { FieldRow } from '@/components/form/FieldRow/FieldRow';
import { DividerWrapper } from '@/components/form/styles';

type PaymentDetailsFormType = {
  selectedDepositAccount: PaymentDetailsGeneralType;
  onChange: (item: any) => void;
  isLoading: boolean;
  selectedDepositAccountId: string;
  error: ResponseErrorType;
  isPaymentDetailsUpdating: boolean;
  onCreate: (payload) => void;
};

export const SelectDepositAccountForm = ({
  selectedDepositAccount,
  onChange,
  isLoading,
  selectedDepositAccountId,
  error,
  isPaymentDetailsUpdating,
  onCreate,
}: PaymentDetailsFormType) => {
  const hasIntermediaryBank = hasIntermediaryPaymentDetails(selectedDepositAccount);
  const paymentType = selectedDepositAccount?.internal_payment_details_data.payment_type || PAYMENT_TYPE_DB.DOMESTIC;

  const { data: depositAccountIdsData } = useQuery({
    queryKey: ['load_deposit_accounts_ids'],
    queryFn: () => loadDepositAccountsIds(),
  });

  const formik = useFormik({
    initialValues: PROVIDE_PAYMENT_DETAILS_INITIAL_VALUES,
    validationSchema: getPaymentDetailsValidationSchema(paymentType, hasIntermediaryBank),
    onSubmit: async (values) => {
      const payload = getPaymentDetailsPayloadValues(values, paymentType, hasIntermediaryBank);

      await onCreate(payload);
    },
  });

  useEffect(() => {
    if (!selectedDepositAccount) return;

    const { id, date_created, date_modified, payment_type, ...restInitialValues } = {
      ...selectedDepositAccount.blockchain_payment_details_data,
      ...selectedDepositAccount.internal_payment_details_data,
    };

    formik.setValues({
      ...formik.values,
      ...restInitialValues,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepositAccount]);

  const depositAccountsOptions = getDepositAccountOptions(depositAccountIdsData?.body?.results);
  const paymentDetailsInputConfig = getPaymentDetailsInputConfig(paymentType);

  return (
    <PaymentDetailsFormWrapper>
      <PaymentDetailsFormTitle>Wire Info</PaymentDetailsFormTitle>
      <PaymentDetailsFormMessage>Please select and confirm wire info for the wire.</PaymentDetailsFormMessage>
      <Select
        label='Deposit Account*'
        placeholder='Select Deposit Account'
        options={depositAccountsOptions}
        onChange={onChange}
        postfixLabel={<CreateNewAccountLink />}
        isLoading={isLoading}
        disable={isLoading}
        emptyOptionPlaceholder='Please create a deposit account.'
      />
      <FormikForm value={formik}>
        {selectedDepositAccountId && (
          <div>
            <FormikInput name='recipient_name' label='Recipient*' placeholder='Enter Recipient Name' apiError={error} />
            <FormikInput
              name='recipient_address'
              label='Recipient Address*'
              placeholder='Enter Recipient Address'
              apiError={error}
            />
            <FormikInput name='account_name' label='Account Name' placeholder='Enter Account Name' apiError={error} />

            <FieldRow columns={[6, 6]}>
              <FormikInput
                name={paymentDetailsInputConfig.name}
                label={paymentDetailsInputConfig.label}
                placeholder={paymentDetailsInputConfig.placeholder}
                apiError={error}
                disabled
              />
            </FieldRow>

            <FormikInput name='bank_name' label='Bank Name' placeholder='Enter Bank Name' apiError={error} />

            <FieldRow columns={[6, 6]}>
              <FormikInput
                name='bank_account_number'
                label='Account Number*'
                placeholder='Enter Number'
                apiError={error}
                disabled
              />
            </FieldRow>

            <FormikInput name='bank_address' label='Bank Address' placeholder='Enter Bank Address' apiError={error} />
            <FormikPhoneInput
              name='bank_phone_number'
              label='Bank Phone Number'
              placeholder='Enter Bank Phone Number With Country Code'
              apiError={error}
            />

            {hasIntermediaryBank && (
              <>
                <DividerWrapper>
                  <FormDivider>Intermediary Bank</FormDivider>
                </DividerWrapper>

                <FieldRow columns={[6, 6]}>
                  <FormikInput
                    name={paymentDetailsInputConfig.intermediaryName}
                    label={paymentDetailsInputConfig.label}
                    placeholder={paymentDetailsInputConfig.placeholder}
                    apiError={error}
                    disabled
                  />
                </FieldRow>

                <FormikInput
                  name='intermediary_bank_name'
                  label='Bank Name'
                  placeholder='Enter Bank Name'
                  apiError={error}
                />

                <FieldRow columns={[6, 6]}>
                  <FormikInput
                    name='intermediary_bank_account_number'
                    label='Account Number*'
                    placeholder='Enter Number'
                    apiError={error}
                    disabled
                  />
                </FieldRow>

                <FormikInput
                  name='intermediary_bank_address'
                  label='Bank Address'
                  placeholder='Enter Bank Address'
                  apiError={error}
                />
                <FormikPhoneInput
                  name='intermediary_bank_phone_number'
                  label='Bank Phone Number'
                  placeholder='Enter Bank Phone Number With Country Code'
                  apiError={error}
                />
              </>
            )}
            <ButtonBlock>
              <Button width={160} size='medium' type='submit' isLoading={isPaymentDetailsUpdating}>
                Submit
              </Button>
            </ButtonBlock>
          </div>
        )}
      </FormikForm>
    </PaymentDetailsFormWrapper>
  );
};
