import queryString, { ParseOptions } from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

export interface QueryParamsType {
  page?: number | null;
  limit?: number | null;
  ordering?: string;
}

export const SEARCH_PARAMS_ARRAY_FORMAT_OPTIONS: ParseOptions = {
  arrayFormat: 'bracket-separator',
  arrayFormatSeparator: ',',
};

export const createSearchParams = (values: Record<string, any>) => {
  return queryString.stringify(values, {
    skipEmptyString: true,
    skipNull: true,
    ...SEARCH_PARAMS_ARRAY_FORMAT_OPTIONS,
  });
};

export const useReplaceSearchParams = <T>() => {
  const navigate = useNavigate();
  const { search: currentSearch, pathname, state } = useLocation();

  const replaceSearchParams = (newSearchParams: QueryParamsType & T) => {
    if (!newSearchParams) return;

    try {
      const currentSearchParams = queryString.parse(currentSearch, {
        ...SEARCH_PARAMS_ARRAY_FORMAT_OPTIONS,
      });

      const search = createSearchParams({ ...currentSearchParams, ...newSearchParams });

      navigate({ pathname, search }, { state });
    } catch (error) {
      console.error('Stringify query params failed with', error?.message);
    }
  };

  return {
    replaceSearchParams,
  };
};
