import { useField } from 'formik';
import { Input, InputType } from '../Input/Input';
import { ResponseErrorType } from '@/types/sharedTypes';
import { ChangeEvent, ReactNode } from 'react';
import { getFieldError } from '@/helpers/formHelpers/formHelpers';
import { PriceIconWrapper } from './styles';
import { Typography } from '@/components/Typography/Typography';
import { PRICE_DIGITS } from '@/const/regExpPatterns';

type FormikPriceInputType = Omit<InputType, 'apiError'> & {
  apiError?: ResponseErrorType;
  priceIcon?: ReactNode;
};

export const FormikPriceInput = ({ name, apiError, priceIcon, ...props }: FormikPriceInputType) => {
  const [field, meta, { setValue }] = useField(name);

  const { apiError: apiErrorMessage, error } = getFieldError(name, meta, apiError);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!PRICE_DIGITS.test(event.target.value)) {
      return;
    }

    setValue(event.target.value);
  };

  return (
    <Input
      {...field}
      inputMode='numeric'
      onChange={onChange}
      apiError={apiErrorMessage}
      error={error}
      before={
        <PriceIconWrapper>
          <Typography fontWeight='medium'>{priceIcon || '$'}</Typography>
        </PriceIconWrapper>
      }
      {...props}
    />
  );
};
