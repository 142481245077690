import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { ReactNode, useEffect, useState } from 'react';
import { updatePaymentDetails } from '../../api';
import { AMPLITUDE_EVENTS, AmplitudeEventsType } from '@/services/amplitudeService/amplitudeEvents';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { PAYMENT_TYPE_DB } from '@/const/shared';
import { isDomesticPaymentType, isInternationalPaymentType } from '@/helpers/paymentDetailsHelpers';
import { ConfirmModal, ConfirmModalHint } from '@/components/ConfirmModal/ConfirmModal';
import { PaymentType } from '@/types/paymentDetailsTypes';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { PaymentDetailsSubtitle } from './styles';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Button } from '@/components/Button/Button';
import { EmailLink } from '@/components/EmailLink/EmailLink';
import { FormValues, isValidForm } from '@/helpers/formHelpers/formHelpers';
import { AssignedWireType } from '@/types/wireTypes';
import { TEXT_MAX_LENGTH } from '@/helpers/formHelpers/validators';
import { isRecipientChainProxy, isRecipientProxy } from '@/helpers/wireHelpers';
import { getAmplitudePaymentDetailsProvider } from '@/services/amplitudeService/amplitudeHelpers';
import { Box } from '@/components/Box/Box';
import {
  getPaymentDetailsSchema,
  PROVIDE_PAYMENT_DETAILS_INITIAL_VALUES,
  ProvidePaymentFormType,
  INTERMEDIARY_PROVIDE_PAYMENT_DETAILS_INITIAL_VALUES,
  getPaymentDetailsPayloadValues,
  INTERMEDIARY_PROVIDE_PAYMENT_DETAILS_INITIAL_TOUCHED,
} from '@/bundle/shared/components/ProvidePaymentDetailsForm/const';
import { ProvidePaymentDetailsForm } from '@/bundle/shared/components/ProvidePaymentDetailsForm/ProvidePaymentDetailsForm';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { Notification } from '@/components/Notification/Notification';

type RecipientProvidePaymentDetailsFormType = {
  wire: AssignedWireType;
  onProvidePaymentDetails: () => void;
  autofillNames?: boolean;
  headlineSlot?: ReactNode;
  actionSlot?: ReactNode;
};

export const RecipientProvidePaymentDetailsForm = ({
  wire,
  onProvidePaymentDetails,
  headlineSlot,
  autofillNames = false,
  actionSlot,
}: RecipientProvidePaymentDetailsFormType) => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenIntermediaryBank, setIsOpenIntermediaryBank] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType>(PAYMENT_TYPE_DB.DOMESTIC);

  const isDomesticPayment = isDomesticPaymentType(selectedPaymentType);
  const isInternationalPayment = isInternationalPaymentType(selectedPaymentType);
  const isProxy = isRecipientProxy(wire);
  const isChainProxy = isRecipientChainProxy(wire);

  const logAmplitudeEventSuccess = (paymentDetails) => {
    let currentEvent: AmplitudeEventsType;

    if (isDomesticPayment) {
      currentEvent = paymentDetails?.intermediary_bank_aba_number
        ? AMPLITUDE_EVENTS.DomesticPaymentIntermediarySuccess
        : AMPLITUDE_EVENTS.DomesticPaymentSuccess;
    }

    if (isInternationalPayment) {
      currentEvent = paymentDetails?.intermediary_bank_swift_code
        ? AMPLITUDE_EVENTS.InternationalPaymentIntermediarySuccess
        : AMPLITUDE_EVENTS.InternationalPaymentSuccess;
    }

    const provider = getAmplitudePaymentDetailsProvider(isChainProxy, isProxy);

    return amplitudeService.logEvent(currentEvent, { provider });
  };

  const {
    mutate: mutatePaymentDetails,
    data: updatedPaymentDetailsData,
    isPending: isPendingPaymentDetails,
  } = useMutation({
    mutationKey: ['provide_payment_details'],
    mutationFn: (values: FormValues) => {
      return updatePaymentDetails(id, values, isDomesticPayment);
    },
    onSuccess(updatedPaymentDetails) {
      if (updatedPaymentDetails.error) return;

      setIsOpenModal(false);
      logAmplitudeEventSuccess(updatedPaymentDetails?.body);
      queryClient.invalidateQueries({ queryKey: ['load_recipient_wire', id] });
      onProvidePaymentDetails();
    },
  });

  const formik = useFormik<ProvidePaymentFormType>({
    initialValues: PROVIDE_PAYMENT_DETAILS_INITIAL_VALUES,
    validationSchema: getPaymentDetailsSchema(selectedPaymentType, isOpenIntermediaryBank),
    onSubmit: async (values) => {
      const payload = getPaymentDetailsPayloadValues(values, selectedPaymentType, isOpenIntermediaryBank);

      await mutatePaymentDetails(payload);
    },
  });

  const logAmplitudeEventRedirect = () => {
    const { values } = formik;

    let currentEvent: AmplitudeEventsType;

    if (isDomesticPayment) {
      currentEvent = values.intermediary_bank_aba_number
        ? AMPLITUDE_EVENTS.DomesticPaymentIntermediaryRedirect
        : AMPLITUDE_EVENTS.DomesticPaymentRedirect;
    }

    if (isInternationalPayment) {
      currentEvent = values.intermediary_bank_swift_code
        ? AMPLITUDE_EVENTS.InternationalPaymentIntermediaryRedirect
        : AMPLITUDE_EVENTS.InternationalPaymentRedirect;
    }

    const provider = getAmplitudePaymentDetailsProvider(isChainProxy, isProxy);

    return amplitudeService.logEvent(currentEvent, { provider });
  };

  const openConfirmPaymentDetailsModal = async () => {
    const isValid = await isValidForm(formik);

    if (!isValid) return;

    logAmplitudeEventRedirect();
    setIsOpenModal(true);
  };

  const selectPaymentType = (paymentType: PaymentType) => {
    const { account_name, recipient_name } = formik.values;

    formik.resetForm({
      values: {
        ...PROVIDE_PAYMENT_DETAILS_INITIAL_VALUES,
        account_name,
        recipient_name,
      },
    });

    setSelectedPaymentType(paymentType);
  };

  useEffect(() => {
    if (!autofillNames) return;

    // Autofill recipient and account name and strip to max length
    const { first_name, middle_name, last_name } = wire?.assigned_user || {};
    const fullName = `${first_name || ''} ${middle_name || ''} ${last_name || ''}`.substring(0, TEXT_MAX_LENGTH);

    const trimmedFullName = fullName.trim();

    formik.setValues({
      ...formik.values,
      recipient_name: trimmedFullName,
      account_name: trimmedFullName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autofillNames, wire?.assigned_user]);

  const toggleIntermediaryForm = (value: boolean) => {
    setIsOpenIntermediaryBank(value);

    formik.setValues({
      ...formik.values,
      ...INTERMEDIARY_PROVIDE_PAYMENT_DETAILS_INITIAL_VALUES,
    });

    formik.setTouched({
      ...formik.touched,
      ...INTERMEDIARY_PROVIDE_PAYMENT_DETAILS_INITIAL_TOUCHED,
    });
  };

  const apiError = updatedPaymentDetailsData?.error;
  const formError = getResponseError(apiError);

  return (
    <>
      <DetailsWidget header='Wire Info' hasHighlight headlineSlot={headlineSlot} headerOffset={8} action={actionSlot}>
        <PaymentDetailsSubtitle>Please provide your wire info.</PaymentDetailsSubtitle>
        <FormikForm value={formik}>
          <ProvidePaymentDetailsForm
            apiError={apiError}
            selectedPaymentType={selectedPaymentType}
            onSelectPaymentType={selectPaymentType}
            isOpenIntermediaryBank={isOpenIntermediaryBank}
            onOpenIntermediaryBank={toggleIntermediaryForm}
          />
        </FormikForm>

        <Box display='flex' alignItems='center' justifyContent='end' columnGap='20px' mt='12px' mb='12px'>
          <Button width={160} size='medium' onClick={openConfirmPaymentDetailsModal} mobileStretch>
            Submit
          </Button>
        </Box>
      </DetailsWidget>

      <ConfirmModal
        isOpen={isOpenModal}
        header='Confirm Wire Info'
        isLoading={isPendingPaymentDetails}
        body={
          <>
            {formError && (
              <Notification variant='error' mb='24px'>
                {formError}
              </Notification>
            )}
            <ConfirmModalHint>You are certifying the validity of the wire info you have provided.</ConfirmModalHint>
            <ConfirmModalHint>
              This information will be committed to the WireVault blockchain and cannot be changed.
            </ConfirmModalHint>
            <ConfirmModalHint>
              If you need to make a change to your wire info, please contact{' '}
              <EmailLink email={wire?.created_by?.email} variant='big' /> to request a cancellation before the wire is
              sent.
            </ConfirmModalHint>
          </>
        }
        onClose={() => setIsOpenModal(false)}
        onConfirm={formik.handleSubmit}
      />
    </>
  );
};
