import styled, { css } from 'styled-components';
import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';

export const FakeCheckbox = styled.span<{ disabled?: boolean }>`
  display: block;
  position: relative;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  border: 1px solid ${COLORS.grey[400]};
  cursor: pointer;
  transition: 0.1s all ease-in;

  &:active {
    box-shadow: 0 0 0 3px #00866f80;
  }

  &::before {
    display: none;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      opacity: 0.3;
    `}
`;

export const Input = styled.input<{ color?: string; indeterminate?: boolean }>`
  display: none;

  &:checked + ${FakeCheckbox} {
    background-color: #00866f;
    border: 1px solid #005e4d;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 5px;
      border-right: 2px solid #fff;
      border-top: 2px solid #fff;
      inset: 0 0 2px;
      margin: auto;
      transform: rotate(130deg);

      ${({ indeterminate }) =>
        indeterminate &&
        css`
          transform: rotate(0);
          border-right: none;
          inset: 0 0 -2px;
        `}
    }
  }
`;

export const CheckboxWrapper = styled.label`
  ${flex({ alignItems: 'center' })}
  column-gap: 12px;
  cursor: pointer;

  &:hover ${Input}:not([disabled], :checked) + ${FakeCheckbox} {
    background-color: ${COLORS.grey[200]};

    &::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 5px;
      border-right: 2px solid ${COLORS.grey[400]};
      border-top: 2px solid ${COLORS.grey[400]};
      right: 0;
      bottom: 2px;
      top: 0;
      left: 0;
      margin: auto;
      transform: rotate(130deg);
    }
  }
`;
