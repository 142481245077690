import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableRow } from '@/components/Table/styles';
import { OPCO_ORGANIZATIONS_TABLE_CONFIG } from '../const/const';
import { TableEmptyRow } from '@/components/Table/TableEmptyRow/TableEmptyRow';
import { TableHeaderCell } from '@/components/Table/TableHeaderCell/TableHeaderCell';
import { TableType } from '@/components/Table/types/types';
import { OrganizationListItemType } from '../types/types';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { getOpcoOrganizationUsersUrl } from '@/bundle/_Opco/ui/OrganizationUsers/urls/getOpcoOrganizationUsersUrl';
import { TableHeader } from '@/components/Table/TableHeader/TableHeader';

export const OpcoOrganizationsTable = ({ items = [], onCellAction }: TableType<OrganizationListItemType>) => {
  const navigate = useNavigate();
  const hasItems = !!items.length;

  const navigateToUsers = (id: string) => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.OrganizationUsersListRedirect);

    navigate(getOpcoOrganizationUsersUrl(id));
  };

  return (
    <Table>
      <TableHeader>
        {OPCO_ORGANIZATIONS_TABLE_CONFIG.map(({ key, name, width, hasSort, header }) => {
          return (
            <TableHeaderCell key={key} name={name} width={width} hasSort={hasItems && hasSort}>
              {header}
            </TableHeaderCell>
          );
        })}
      </TableHeader>
      <TableBody>
        {hasItems ? (
          items.map((item: OrganizationListItemType) => {
            return (
              <TableRow key={item.id} onClick={() => navigateToUsers(item.id)} isClickableRow>
                {OPCO_ORGANIZATIONS_TABLE_CONFIG.map(({ key, width, getValue, hasTitle }) => {
                  const value = getValue(item, onCellAction);
                  const title = (hasTitle && (value as string)) || '';

                  return (
                    <TableCell key={key} width={width} title={title}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
        ) : (
          <TableEmptyRow columns={OPCO_ORGANIZATIONS_TABLE_CONFIG} />
        )}
      </TableBody>
    </Table>
  );
};
