import styled, { CSSProperties } from 'styled-components';
import { boxStyles } from './boxHelpers';
import { HTMLAttributes } from 'react';

export type SpacingPropsType = {
  mt?: string;
  mb?: string;
  mr?: string;
  ml?: string;
  m?: string | string[];
};

const FOCUSABLE_PROPS = {
  cursor: 'pointer',
  tabIndex: 0,
  role: 'button',
};

export type BoxWrapperPropsType = HTMLAttributes<HTMLDivElement> &
  SpacingPropsType & {
    display?: CSSProperties['display'];
    justifyContent?: CSSProperties['justifyContent'];
    alignItems?: CSSProperties['alignItems'];
    flexDirection?: CSSProperties['flexDirection'];
    flexWrap?: CSSProperties['flexWrap'];
    flexGrow?: CSSProperties['flexGrow'];
    flexShrink?: CSSProperties['flexShrink'];
    rowGap?: CSSProperties['rowGap'];
    columnGap?: CSSProperties['columnGap'];
    width?: CSSProperties['width'];
    position?: CSSProperties['position'];
    height?: CSSProperties['height'];
    cursor?: CSSProperties['cursor'];
    textAlign?: CSSProperties['textAlign'];
    zIndex?: CSSProperties['zIndex'];
    focusable?: boolean;
  };

export const BoxWrapper = styled('div').attrs(
  (props: BoxWrapperPropsType) =>
    props.focusable && {
      ...FOCUSABLE_PROPS,
    }
)<BoxWrapperPropsType>(boxStyles);
