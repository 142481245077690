import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import verificationProgressPath from './images/VerificationProgress.png';
import { Box } from '@/components/Box/Box';
import { ProgressImage, ProgressMessage } from './styles';
import { BoldText } from '../DocumentVerificationStart/ui/styles';
import { formatPhone } from '@/helpers/formatHelpers';
import { formatSecondsToMinutes } from '@/helpers/dateHelpers/dateHelpers';
import { Button } from '@/components/Button/Button';

type DocumentVerificationProgressType = {
  userPhone: string;
  seconds: number;
  isLoading: boolean;
  onStartTrustStamp: () => void;
};

export const DocumentVerificationProgress = ({
  userPhone,
  seconds,
  isLoading,
  onStartTrustStamp,
}: DocumentVerificationProgressType) => {
  const formattedUserPhone = formatPhone(userPhone);

  const isDisabled = seconds !== 0;
  const buttonLabel = isDisabled
    ? `Resend verification link in ${formatSecondsToMinutes(seconds)}`
    : 'Resend verification link';

  return (
    <DetailsWidget header='ID Verification in Progress' headerOffset={2}>
      <Box display='flex' flexDirection='column'>
        <ProgressMessage>
          Please complete your ID verification in order to provide your wire info. You should have received a text
          message to your mobile phone number <BoldText>{formattedUserPhone}</BoldText>.
        </ProgressMessage>
        <ProgressImage src={verificationProgressPath} alt='verification in progress' />
        <Button onClick={onStartTrustStamp} isLoading={isLoading} disabled={isDisabled}>
          {buttonLabel}
        </Button>
      </Box>
    </DetailsWidget>
  );
};
