import { FormWrapper, Row, HighlightRow, HeaderAction, HighlightRowTitle } from './styles';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { FormikPhoneInput } from '@/components/form/fields/FormikPhoneInput/FormikPhoneInput';
import { ResponseErrorType } from '@/types/sharedTypes';
import { PaymentDetailsInputConfigType } from '../../const';
import { FieldRow } from '@/components/form/FieldRow/FieldRow';

type AddIntermediaryBankFormType = {
  isOpen: boolean;
  onOpen: (open: boolean) => void;
  apiError: ResponseErrorType;
  paymentDetailsInputConfig: PaymentDetailsInputConfigType;
};

export const AddIntermediaryBankForm = ({
  isOpen,
  onOpen,
  paymentDetailsInputConfig,
  apiError,
}: AddIntermediaryBankFormType) => {
  return (
    <FormWrapper>
      {isOpen ? (
        <>
          <HighlightRow>
            <HighlightRowTitle>Intermediary Bank</HighlightRowTitle>
            <HeaderAction onClick={() => onOpen(false)}>Remove</HeaderAction>
          </HighlightRow>

          <FieldRow columns={[6, 6]}>
            <FormikInput
              name={paymentDetailsInputConfig.intermediaryName}
              type='password'
              label={paymentDetailsInputConfig.label}
              placeholder={paymentDetailsInputConfig.placeholder}
              apiError={apiError}
              autoComplete='new-password'
            />
            <FormikInput
              name={paymentDetailsInputConfig.confirmIntermediaryName}
              label={paymentDetailsInputConfig.confirmLabel}
              placeholder={paymentDetailsInputConfig.placeholder}
              apiError={apiError}
              autoComplete='off'
              isCopyPasteDisabled
              isConfirmTicks={true}
            />
          </FieldRow>

          <FormikInput
            name='intermediary_bank_name'
            label='Bank Name'
            placeholder='Enter Bank Name'
            apiError={apiError}
          />

          <FieldRow columns={[6, 6]}>
            <FormikInput
              name='intermediary_bank_account_number'
              type='password'
              label='Account Number*'
              placeholder='Enter Number'
              apiError={apiError}
            />
            <FormikInput
              name='confirm_intermediary_bank_account_number'
              label='Confirm Account Number*'
              placeholder='Enter Number'
              apiError={apiError}
              autoComplete='off'
              isCopyPasteDisabled
              isConfirmTicks={true}
            />
          </FieldRow>

          <FormikInput
            name='intermediary_bank_address'
            label='Bank Address'
            placeholder='Enter Bank Address'
            apiError={apiError}
          />
          <FormikPhoneInput
            name='intermediary_bank_phone_number'
            label='Bank Phone Number'
            placeholder='Enter Bank Phone Number With Country Code'
            apiError={apiError}
          />
        </>
      ) : (
        <Row>
          <HeaderAction onClick={() => onOpen(true)}>+ Add Intermediary Bank</HeaderAction>
        </Row>
      )}
    </FormWrapper>
  );
};
