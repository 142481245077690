import { Box } from '@/components/Box/Box';
import { Table, TableBody, TableCell, TableRow } from '@/components/Table/styles';
import { TableHeader } from '@/components/Table/TableHeader/TableHeader';
import { TableHeaderCell } from '@/components/Table/TableHeaderCell/TableHeaderCell';
import { TableEmptyRow } from '@/components/Table/TableEmptyRow/TableEmptyRow';
import { Typography } from '@/components/Typography/Typography';
import { BillingServicePlanDataAddonOrDiscount } from '@/api/v1/opco/billing/getBillingPlansApi';
import { BILLING_PLAN_ADDONS_CONFIG } from './const';

type BillingPlanDetailsAddonsType = {
  addons: BillingServicePlanDataAddonOrDiscount[];
};

export const BillingPlanDetailsAddons = ({ addons = [] }: BillingPlanDetailsAddonsType) => {
  const hasItems = !!addons.length;

  return (
    <Box mb='24px'>
      <Typography variant='subTitleSmall' fontWeight='semibold' mb='8px'>
        Add-ons
      </Typography>
      <Table>
        <TableHeader variant='secondary'>
          {BILLING_PLAN_ADDONS_CONFIG.map(({ key, name, width, header, hasSort, textAlign }) => (
            <TableHeaderCell
              key={key}
              name={name}
              width={width}
              hasSort={hasSort && hasItems}
              textAlign={textAlign}
              variant='secondary'
            >
              {header}
            </TableHeaderCell>
          ))}
        </TableHeader>
        <TableBody>
          {hasItems ? (
            addons.map((item, index) => (
              <TableRow key={index}>
                {BILLING_PLAN_ADDONS_CONFIG.map(({ key, width, getValue, textAlign }) => (
                  <TableCell key={key} width={width} textAlign={textAlign}>
                    {getValue(item)}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableEmptyRow columns={BILLING_PLAN_ADDONS_CONFIG} />
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
