import { prepareBaseListApiParams } from '@/api/helpers/urlApiHelpers';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { OrganizationModifyType } from '../types/types';
import { createOrganizationApi } from '@/api/v1/opco/organizations/createOrganizationApi';
import { getOrganizationsApi } from '@/api/v1/opco/organizations/getOrganizationsApi';
import { getOrganizationApi } from '@/api/v1/opco/organizations/getOrganizationApi';
import { updateOrganizationApi } from '@/api/v1/opco/organizations/updateOrganizationApi';
import { getOrganizationPricingTierApi } from '@/api/v1/opco/organizations/getOrganizationPricingTier';

export const loadOrganizations = async (params) => {
  const apiParams = prepareBaseListApiParams(params);

  try {
    const response = await getOrganizationsApi(apiParams);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadOrganization = async (id: string) => {
  try {
    const response = await getOrganizationApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const createOrganization = async (payload: OrganizationModifyType) => {
  try {
    const response = await createOrganizationApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const updateOrganization = async (id: string, payload: OrganizationModifyType) => {
  try {
    const response = await updateOrganizationApi(id, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadPricingTierList = async () => {
  try {
    const response = await getOrganizationPricingTierApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadPricingPlans = async () => {
  try {
    // TODO v16.0.0: fix endpoint
    return {};
    // const response = await getPaymentsPlanApi();
    // return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
