import { useFormik } from 'formik';
import { createTransactionValidationSchema } from '../../const/createTransactionValidationSchema';
import { Box } from '@/components/Box/Box';
import { ResponseErrorType } from '@/types/sharedTypes';
import { FormikDatePicker } from '@/components/form/fields/FormikDatePicker/FormikDatePicker';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { Button } from '@/components/Button/Button';
import { useResponsiveDevice } from '@/hooks/useResponsiveDevice';
import { FormEvent } from 'react';

type CreateTransactionFormType = {
  onCreate: (values) => void;
  apiError: ResponseErrorType;
  onClose: () => void;
  isLoading: boolean;
};

export const CreateTransactionForm = ({ onCreate, apiError, onClose, isLoading }: CreateTransactionFormType) => {
  const { isMobile } = useResponsiveDevice();
  const formik = useFormik({
    initialValues: {
      name: '',
      expected_close_date: '',
    },
    validationSchema: createTransactionValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      onCreate(values);
    },
  });

  const saveHandler = (e: FormEvent<HTMLFormElement>) => {
    formik.handleSubmit(e);
  };

  return (
    <Box width='100%'>
      <FormikForm value={formik}>
        <FormikInput
          label='Transaction Name*'
          placeholder='Enter Transaction Name'
          name='name'
          autoFocus
          apiError={apiError}
        />
        <Box width={210}>
          <FormikDatePicker name='expected_close_date' label='Expected Closing Date' placeholder='Select Date' />
        </Box>
        <Box
          columnGap='20px'
          rowGap='20px'
          display='flex'
          mt='36px'
          flexDirection={isMobile ? 'column-reverse' : 'row'}
        >
          <Button variant='secondary' mobileStretch onClick={onClose} width={200} type='button'>
            Cancel
          </Button>
          <Button onClick={saveHandler} mobileStretch type='submit' width={200} isLoading={isLoading}>
            Save
          </Button>
        </Box>
      </FormikForm>
    </Box>
  );
};
