import { REQUIRED_TEXT_FIELD_VALIDATOR, TEXT_FIELD_VALIDATOR } from '@/helpers/formHelpers/validators';
import * as Yup from 'yup';

export type PersonalDetailsFormType = {
  first_name: string;
  middle_name: string;
  last_name: string;
};

export const PERSONAL_DETAILS_INITIAL_VALUES: PersonalDetailsFormType = {
  first_name: '',
  middle_name: '',
  last_name: '',
};

export const PERSONAL_DETAILS_VALIDATION_SCHEMA = Yup.object().shape({
  first_name: REQUIRED_TEXT_FIELD_VALIDATOR,
  middle_name: TEXT_FIELD_VALIDATOR,
  last_name: REQUIRED_TEXT_FIELD_VALIDATOR,
});
