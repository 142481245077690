// =========== [Pepper Pay tokenizer documentation] ===========
// https://sandbox.pepperpaygateway.com/docs/tokenizer/

// @ts-nocheck
import { useRef, useState } from 'react';
import { CHECKOUT_FORM_SETTINGS } from '../const/const';
import { PAYMENT_CARD_VALIDATION_MAP } from '@/bundle/pages/SettingsPage/const/const';
import uniq from 'lodash/uniq';

import { envConfig } from '@/config/envConfig';

export const PEPPER_PAY_CONTAINER = 'pepper-pay-checkout';

export const useLoadTokenizer = (onSuccess: (response) => void) => {
  const [loading, setLoading] = useState(true);
  const [tokenizer, setTokenizer] = useState<any>();
  const tokenizerRef = useRef(null);
  const [validationError, setValidationError] = useState<string[]>(null);
  const [apiError, setApiError] = useState<string>(null);

  const initializeTokenizer = () => {
    try {
      const tokenizerConfigurator = new Tokenizer({
        url: '', // Optional - Only needed if domain is different than the one your on, example: localhost
        apikey: envConfig.PEPPER_PAY_API_KEY,
        container: `#${PEPPER_PAY_CONTAINER}`,
        // Callback after iframe is initiated an onload
        onLoad: () => {
          setLoading(false);
        },
        submission: (response) => {
          // Figure out what response you got back
          switch (response.status) {
            case 'success': {
              onSuccess?.(response);

              break;
            }

            case 'error': {
              // Encountered an error while performing submission
              setApiError(response.msg);
              setValidationError(null);
              break;
            }

            case 'validation':
              // Encountered form validation specific errors
              setApiError(null);
              if (Array.isArray(response.invalid)) {
                // Note: Remove duplicate errors since validation could return non-unique ones.
                const errors = uniq(response.invalid.map((item) => PAYMENT_CARD_VALIDATION_MAP[item]));

                setValidationError(errors);
              }

              break;
          }
        },
        settings: CHECKOUT_FORM_SETTINGS,
      });

      setTokenizer(tokenizerConfigurator);
      tokenizerRef.current = tokenizerConfigurator;
    } catch (e) {
      console.error('Checkout form failed to render', e?.message);
    }
  };

  const loadTokenizer = () => {
    const script = document.createElement('script');

    // TODO v16.0.0: move link to ENV configs and add new for production
    script.src = 'https://sandbox.pepperpaygateway.com/tokenizer/tokenizer.js';
    script.async = true;
    script.onload = function () {
      initializeTokenizer();
    };

    document.body.appendChild(script);
  };

  return {
    loadTokenizer,
    tokenizer,
    loading,
    apiError,
    validationError,
  };
};
