import { useEffect, useState } from 'react';
import { ContentLayout } from '../Layouts/ContentLayout/ContentLayout';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { Paginator } from '@/components/Paginator/Paginator';
import { NoResults } from '@/components/NoResults/NoResults';
import { ReactComponent as NoTransactionsIcon } from '../../images/NoTransactionsIcon.svg';
import { CreateTransactionForm } from './pages/Transactions/ui/CreateTransactionForm/CreateTransactionForm';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { PageFooter } from '@/components/Page/PageFooter/PageFooter';
import { TransactionsList } from './pages/Transactions/ui/TransactionsList';
import { ModalWindow } from '@/components/ModalWindow/ModalWindow';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createTransaction, loadTransactions } from './pages/Transactions/api';
import { formatPayloadDate } from '@/helpers/dateHelpers/dateHelpers';
import { ReactComponent as PlusIcon } from '@/images/PlusIcon.svg';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { Button } from '@/components/Button/Button';
import { SearchInput } from '@/bundle/shared/components/SearchInput/SearchInput';
import { Box } from '@/components/Box/Box';
import {
  SearchFilterRadioOptionType,
  TransactionsSearchFilters,
} from './ui/TransactionsSearchFilters/TransactionsSearchFilters';
import { useFilterSearch } from './hooks/useFilterSearch';
import { NoSearchResults } from '../shared/components/NoSearchResults';
import { SEARCH_FILTER_RADIO_OPTION_TYPE, SearchFiltersType } from './ui/TransactionsSearchFilters/const';

type ValuesType = {
  expected_close_date: string;
  name: string;
};

export const TransactionsPage = () => {
  const queryClient = useQueryClient();
  const { search, setSearch, submitSearch, clearSearch, searchFilters, changeSearchFilters, searchParams } =
    useFilterSearch();

  const [isOpen, setIsOpen] = useState(false);
  const [searchPlaceholder, setSearchPlaceholder] = useState('');

  const {
    mutate,
    data: createdTransaction,
    isPending: isPendingCreateTransaction,
    reset,
  } = useMutation({
    mutationKey: ['create_transaction'],
    mutationFn: ({ name, expected_close_date }: ValuesType) => {
      const formattedDate = formatPayloadDate(expected_close_date);
      const payload = { name, expected_close_date: formattedDate };

      return createTransaction(payload);
    },
    onSuccess(data) {
      if (data.error) return;

      setIsOpen(false);

      amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateTransactionSuccess);
      queryClient.removeQueries({ queryKey: ['load_transactions', searchParams] });
    },
  });

  const { data: transactions, isPending: isPendingTransactions } = useQuery({
    queryKey: ['load_transactions', searchParams],
    queryFn: () => loadTransactions(searchParams),
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (searchFilters.byName) {
      return setSearchPlaceholder('Search by transaction name');
    }

    if (searchFilters?.searchEmailTypes) {
      return setSearchPlaceholder('Search by email address');
    }
  }, [searchFilters]);

  const onClose = () => {
    setIsOpen(false);
    reset();
  };

  const openCreateTransactionForm = () => {
    setIsOpen(true);

    amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateTransactionRedirect);
  };

  const handleSubmitSearch = () => {
    if (!!search.length && searchParams.name !== search) {
      amplitudeService.logEvent(AMPLITUDE_EVENTS.TransactionsSearchSubmit);
    }

    submitSearch();
  };

  const handleChangeSearchFilters = (filters: SearchFiltersType, type: SearchFilterRadioOptionType) => {
    changeSearchFilters(filters);

    if (type === SEARCH_FILTER_RADIO_OPTION_TYPE.NAME) {
      amplitudeService.logEvent(AMPLITUDE_EVENTS.TransactionsSearchSelectNameFilter);
    }

    if (type === SEARCH_FILTER_RADIO_OPTION_TYPE.EMAIL) {
      amplitudeService.logEvent(AMPLITUDE_EVENTS.TransactionsSearchSelectEmailFilter);
    }
  };

  const transactionBody = transactions?.body;
  const hasTransactions = !!transactionBody?.results?.length;
  const hasPaginator = transactionBody?.count > searchParams?.limit;
  const hasSearchName = !!searchParams.name;
  const transactionsList = transactionBody?.results;
  const hasFiltersSearch = (hasTransactions && !isPendingTransactions) || hasSearchName;
  const noResults = !hasTransactions && !isPendingTransactions && !hasSearchName;
  const noSearchResults = !hasTransactions && hasSearchName;

  return (
    <ContentLayout
      title='Transactions'
      headerAction={
        <Button width={220} mobileStretch icon={<PlusIcon />} iconPosition='left' onClick={openCreateTransactionForm}>
          Create Transaction
        </Button>
      }
      offsetSize='small'
    >
      <PageBody>
        {hasFiltersSearch && (
          <Box width='100%' display='flex'>
            <Box width='344px'>
              <SearchInput
                placeholder={searchPlaceholder}
                value={search}
                filters={
                  <TransactionsSearchFilters
                    searchFilters={searchFilters}
                    onChangeSearchFilters={handleChangeSearchFilters}
                  />
                }
                onChange={(value) => setSearch(value)}
                onClear={clearSearch}
                onSubmit={handleSubmitSearch}
              />
            </Box>
          </Box>
        )}
        {hasTransactions && <TransactionsList list={transactionsList} />}
        {noResults && <NoResults icon={<NoTransactionsIcon />} message='Please create your first transaction.' />}
        {noSearchResults && <NoSearchResults>No transactions found</NoSearchResults>}
      </PageBody>
      {hasPaginator && (
        <PageFooter>
          <Paginator page={searchParams.page} limit={searchParams.limit} total={transactionBody?.count} />
        </PageFooter>
      )}
      <ModalWindow isOpen={isOpen} headerText='Create Transaction' isStartPositionHeader>
        <CreateTransactionForm
          onCreate={mutate}
          apiError={createdTransaction?.error}
          onClose={onClose}
          isLoading={isPendingCreateTransaction}
        />
      </ModalWindow>
    </ContentLayout>
  );
};
