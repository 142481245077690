import styled from 'styled-components';
import { media } from '@/styles/responsive';

export const FieldRowWrapper = styled.div<{ columns: number[]; mobileStretch?: boolean; rowGap?: string }>`
  display: grid;
  column-gap: 20px;
  row-gap: ${({ rowGap }) => rowGap};
  grid-template-columns: ${({ columns }) => columns.map((column) => column + 'fr').join(' ')};

  ${({ mobileStretch }) =>
    mobileStretch &&
    media.mobile`
      grid-template-columns: 1fr;
  `};
`;
