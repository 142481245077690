import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getSubscriptions, loadCustomerVault } from './api';
import { BillingAddressCard } from './ui/BillingAddressCard/BillingAddressCard';
import { Box } from '@/components/Box/Box';
import { PaymentPlanDetails } from '@/bundle/pages/SettingsPage/ui/BillingPlanDetails/PaymentPlanDetails';
import { PaymentCardsList } from './ui/PaymentCardsList/PaymentCardsList';
import { InactiveSubscription } from './ui/InactiveSubscription/InactiveSubscription';
import { BillingTransactionHistoryTable } from '@/bundle/pages/SettingsPage/ui/BillingTransactionHistoryTable/BillingTransactionHistoryTable';

export const SettingsPage = () => {
  const queryClient = useQueryClient();

  const { data: customerVaultData, isPending: isPendingCustomerVault } = useQuery({
    queryKey: ['load_customer_vault_app'],
    queryFn: () => loadCustomerVault(),
  });

  const { data: getSubscriptionsData, isPending: isPendingGetSubscriptions } = useQuery({
    queryKey: ['get_subscriptions_app'],
    queryFn: () => getSubscriptions(),
  });

  const subscription = getSubscriptionsData?.body?.results?.at(0);
  const customerVault = customerVaultData?.body;
  const customerData = customerVault?.data?.customer;
  const paymentCards = customerData?.payments.cards || [];
  const defaultPaymentCardId = customerData?.defaults.payment_method_id;
  const defaultBillingAddressId = customerData?.defaults.billing_address_id;
  const billingAddress = customerData?.addresses.find((item) => item.id === defaultBillingAddressId);

  const refetchCustomerVault = () => {
    queryClient.invalidateQueries({ queryKey: ['load_customer_vault_app'] });
  };

  if (isPendingGetSubscriptions || isPendingCustomerVault) {
    return null;
  }

  if (getSubscriptionsData?.body?.results?.length === 0 || !customerVault) {
    return <InactiveSubscription />;
  }

  return (
    <ContentLayout title='Settings'>
      <PageBody>
        <PaymentPlanDetails subscription={subscription} />
        <Box mb='56px' display='flex' columnGap='24px' alignItems='flex-start'>
          <Box width='458px'>
            <PaymentCardsList
              cards={paymentCards}
              defaultPaymentCardId={defaultPaymentCardId}
              onRefetchCustomerVault={refetchCustomerVault}
            />
          </Box>
          <Box width='458px' zIndex={1}>
            <BillingAddressCard billingAddress={billingAddress} onRefetchCustomerVault={refetchCustomerVault} />
          </Box>
        </Box>

        <BillingTransactionHistoryTable />
      </PageBody>
    </ContentLayout>
  );
};
