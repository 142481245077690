import { Circle1, Circle2, Circle3, LoaderCircles, LoaderWrapper } from './styles';

type LoaderType = {
  size?: 'large' | 'medium' | 'small';
  width?: number | string;
  background?: string;
  inline?: boolean;
  mobileStretch?: boolean;
};

export const Loader = ({ size = 'large', width, background, inline, mobileStretch }: LoaderType) => {
  return (
    <LoaderWrapper size={size} width={width} background={background} inline={inline} mobileStretch={mobileStretch}>
      <LoaderCircles>
        <Circle1 />
        <Circle2 />
        <Circle3 />
      </LoaderCircles>
    </LoaderWrapper>
  );
};
