import * as Yup from 'yup';
import {
  INTEGER_NUMBER_FIELD_VALIDATOR,
  REQUIRED_AMOUNT_VALIDATOR,
  REQUIRED_INTEGER_NUMBER_FIELD_VALIDATOR,
  REQUIRED_OBJECT_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';
import { OptionType } from '@/components/form/fields/Select/Select';

export type BillingPlanFormType = {
  name: string;
  description?: string;
  wires_limit: string;
  users_limit: string;
  overage_price: string;
  hasWiresLimit: boolean;
  hasUsersLimit: boolean;
  hasOveragePrice: boolean;
  basePlan: OptionType | null;
};

export const CREATE_BILLING_PLAN_INITIAL_VALUES = {
  name: '',
  description: '',
  wires_limit: '',
  users_limit: '',
  overage_price: '',
  hasUsersLimit: true,
  hasWiresLimit: true,
  hasOveragePrice: false,
  basePlan: null,
};

export const CREATE_BILLING_PLAN_VALIDATION_SCHEMA = Yup.object().shape({
  name: REQUIRED_TEXT_FIELD_VALIDATOR,
  wires_limit: INTEGER_NUMBER_FIELD_VALIDATOR.when('hasWiresLimit', {
    is: true,
    then: REQUIRED_INTEGER_NUMBER_FIELD_VALIDATOR,
  }),
  users_limit: INTEGER_NUMBER_FIELD_VALIDATOR.when('hasUsersLimit', {
    is: true,
    then: REQUIRED_INTEGER_NUMBER_FIELD_VALIDATOR,
  }),
  overage_price: TEXT_FIELD_VALIDATOR.when('hasOveragePrice', {
    is: true,
    then: REQUIRED_AMOUNT_VALIDATOR,
  }),
  description: TEXT_FIELD_VALIDATOR,
  basePlan: REQUIRED_OBJECT_VALIDATOR,
});
