import { isEmptyObject } from '../objectHelpers';
import { FieldMetaProps } from 'formik';
import { ResponseErrorType } from '@/types/sharedTypes';
import isEqual from 'lodash/isEqual';

export type FormValues = {
  [key: string]: unknown;
};

export const getFormInitialValues = (formValues: FormValues, initialValue: any = '') => {
  return Object.keys(formValues).reduce((result, key) => {
    result[key] = initialValue;

    return result;
  }, {});
};

export const isValidForm = async (formik: any) => {
  formik.setTouched(getFormInitialValues(formik.values, true));

  // Formik validating errors asynchronously, so wait until it's finished
  const errorState = await formik.validateForm();

  return isEmptyObject(errorState);
};

export const getFieldError = (name: string, meta: FieldMetaProps<unknown>, apiError: ResponseErrorType) => {
  const hasError = !!(meta.touched && meta.error);
  const apiErrorMessage = apiError?.errorContent[name] as string;
  const error = hasError ? meta.error : null;

  return {
    error,
    apiError: apiErrorMessage,
    hasAnyError: !!error || !!apiErrorMessage,
  };
};

export const getChangedFormValues = <T>(formValues: FormValues, initialValues: FormValues): T | null => {
  const changedValues = Object.entries(formValues).reduce((result, [key, value]) => {
    const hasChanged = !isEqual(initialValues[key], value);

    if (hasChanged) {
      result[key] = value;
    }

    return result;
  }, {});

  return isEmptyObject(changedValues) ? null : (changedValues as T);
};
