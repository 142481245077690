import { MouseEvent } from 'react';
import { CheckboxWrapper, FakeCheckbox, Input } from './styles';

export type CheckboxClickEventType = MouseEvent<HTMLInputElement>;

type CheckboxProps = {
  checked?: boolean;
  onChange?: () => void;
  label?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  id?: string;
  onClick?: (event: CheckboxClickEventType) => void;
};

export const Checkbox = ({ id, checked, onChange, onClick, disabled, label, indeterminate = false }: CheckboxProps) => {
  return (
    <CheckboxWrapper>
      <Input
        id={id}
        type='checkbox'
        checked={checked || indeterminate}
        onChange={onChange}
        disabled={disabled}
        onClick={onClick}
        indeterminate={indeterminate}
      />
      <FakeCheckbox disabled={disabled} />
      {label}
    </CheckboxWrapper>
  );
};
