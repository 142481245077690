import { PersonalDetailsType } from '@/bundle/shared/components/UserDetails/UserPersonalInfo';
import { REQUIRED_TEXT_FIELD_VALIDATOR, TEXT_FIELD_VALIDATOR } from '@/helpers/formHelpers/validators';
import * as Yup from 'yup';

export const getEditOpcoInitialValues = (personalDetails: PersonalDetailsType) => {
  return {
    first_name: personalDetails?.first_name,
    middle_name: personalDetails?.middle_name,
    last_name: personalDetails?.last_name,
  };
};

export const EDIT_OPCO_VALIDATION_SCHEMA = Yup.object().shape({
  first_name: REQUIRED_TEXT_FIELD_VALIDATOR,
  middle_name: TEXT_FIELD_VALIDATOR,
  last_name: REQUIRED_TEXT_FIELD_VALIDATOR,
});
