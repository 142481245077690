import { Table, TableBody, TableCell, TableRow } from '@/components/Table/styles';
import { TableEmptyRow } from '@/components/Table/TableEmptyRow/TableEmptyRow';
import { TableHeaderCell } from '@/components/Table/TableHeaderCell/TableHeaderCell';
import { TableType } from '@/components/Table/types/types';
import { OPCO_USERS_TABLE_CONFIG } from '../const/opcoUsersTableConfig';
import { OpcoUserType } from '../../types/types';
import { useNavigate } from 'react-router-dom';
import { getOpcoUserDetailsUrl } from '../Details/url/getOpcoUserDetailsUrl';
import { UtcTooltip } from '@/bundle/_Opco/ui/UtcTooltip/UtcTooltip';
import { TableHeader } from '@/components/Table/TableHeader/TableHeader';

export const OpcoUsersTable = ({ items = [] }: TableType<OpcoUserType>) => {
  const hasItems = !!items.length;
  const navigate = useNavigate();

  const navigateToUserDetails = (id: string) => {
    navigate(getOpcoUserDetailsUrl(id));
  };

  return (
    <Table>
      <TableHeader>
        {OPCO_USERS_TABLE_CONFIG.map(({ key, name, width, hasSort, header, hasTooltip }) => {
          return (
            <TableHeaderCell key={key} name={name} width={width} hasSort={hasItems && hasSort}>
              {hasTooltip && <UtcTooltip message='Last login is recorded in UTC' />}
              {header}
            </TableHeaderCell>
          );
        })}
      </TableHeader>
      <TableBody>
        {hasItems ? (
          items.map((item: OpcoUserType) => {
            return (
              <TableRow key={item.id} onClick={() => navigateToUserDetails(item.user?.id)} isClickableRow>
                {OPCO_USERS_TABLE_CONFIG.map(({ key, width, getValue, hasTitle }) => {
                  const value = getValue(item);
                  const title = (hasTitle && (value as string)) || '';

                  return (
                    <TableCell key={key} width={width} title={title}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
        ) : (
          <TableEmptyRow columns={OPCO_USERS_TABLE_CONFIG} />
        )}
      </TableBody>
    </Table>
  );
};
