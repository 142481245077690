import { CSSProperties, ReactNode } from 'react';
import { useTableSorting } from '../hooks/useTableSorting';
import { TableHeaderCellContainer } from './styles';
import { TableSorting } from '../TableSorting/TableSorting';
import { Box } from '@/components/Box/Box';
import { useGetSearchParams } from '@/hooks/useGetSearchParams';
import { TableHeaderVariantType } from '@/components/Table/TableHeader/TableHeader';

export type TextAlignType = CSSProperties['textAlign'];

type TableHeaderCellType = {
  name: string;
  variant?: TableHeaderVariantType;
  hasSort?: boolean;
  width?: string;
  textAlign?: TextAlignType;
  children?: ReactNode;
};

export const TableHeaderCell = ({
  width,
  name,
  hasSort,
  textAlign = 'left',
  variant = 'primary',
  children,
}: TableHeaderCellType) => {
  const { tableSorting } = useTableSorting();
  const { ordering } = useGetSearchParams();

  const isSortAsc = hasSort && ordering === name;
  const isSortDesc = hasSort && ordering === `-${name}`;
  const isSort = hasSort && !(isSortAsc || isSortDesc);

  const sorting = () => {
    if (!hasSort) return;

    return tableSorting(name, isSort, isSortAsc, isSortDesc);
  };

  return (
    <TableHeaderCellContainer hasSort={hasSort} width={width} onClick={sorting} variant={variant} textAlign={textAlign}>
      <Box display='inline-flex'>
        {children}
        {hasSort && <TableSorting isSortAsc={isSortAsc} isSortDesc={isSortDesc} isSort={isSort} />}
      </Box>
    </TableHeaderCellContainer>
  );
};
