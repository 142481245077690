import capitalize from 'lodash/capitalize';
import { TableConfigItemsListType } from '@/components/Table/types/types';
import { BillingPlanStatus } from '../BillingPlanStatus/BillingPlanStatus';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';
import { BillingPlanType } from '@/api/v1/opco/billing/getBillingPlansApi';
import { convertCentsToDollars } from '@/helpers/formatHelpers';
import { formatLimits } from '../../helpers';

export const BILLING_TABLE_CONFIG: TableConfigItemsListType<BillingPlanType> = [
  {
    key: 1,
    name: 'name,-date_created',
    header: 'WIREVAULT PLAN NAME',
    width: '20%',
    hasTitle: true,
    getValue(item) {
      return item.name;
    },
  },
  {
    key: 2,
    name: 'name,-date_created',
    header: 'PEPPER PAY PLAN NAME',
    width: '20%',
    hasTitle: true,
    getValue(item) {
      return item.payment_service_plan_data?.name;
    },
  },
  {
    key: 3,
    name: 'date_created',
    header: 'DATE CREATED',
    width: '10%',
    getValue(item) {
      return formatDate(item.date_created);
    },
  },
  {
    key: 4,
    name: 'price',
    header: 'AMOUNT',
    width: '10%',
    getValue(item) {
      return convertCentsToDollars(item.payment_service_plan_data?.amount);
    },
  },
  {
    key: 5,
    name: 'frequency,',
    header: 'BILLING CYCLE',
    width: '10%',
    getValue(item) {
      return capitalize(item.payment_service_plan_data?.billing_frequency);
    },
  },
  {
    key: 6,
    name: 'wires_limit,',
    header: 'WIRES LIMIT',
    width: '10%',
    getValue(item) {
      return formatLimits(item.wires_limit);
    },
  },
  {
    key: 7,
    name: 'user_limit,',
    header: 'USER LIMIT',
    width: '10%',
    getValue(item) {
      return formatLimits(item.users_limit);
    },
  },
  {
    key: 8,
    name: 'status,',
    header: 'STATUS',
    width: '10%',
    getValue(item) {
      return <BillingPlanStatus status={item.status} />;
    },
  },
];
