import { useField } from 'formik';
import { SelectableTabs, SelectableTabsOptionType } from '@/components/form/fields/SelectableTabs/SelectableTabs';

interface FormikSelectableTabsType {
  name: string;
  options: SelectableTabsOptionType[];
  label?: string;
  disabled?: boolean;
}

export const FormikSelectableTabs = ({ label, name, options, disabled }: FormikSelectableTabsType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, _, { setValue }] = useField(name);

  const handleSelect = (value: string) => {
    setValue(value);
  };

  return (
    <SelectableTabs
      {...field}
      label={label}
      value={field.value}
      onSelect={handleSelect}
      options={options}
      disabled={disabled}
    />
  );
};
