import { COLORS } from '@/styles/colors';
import { resetList } from '@/styles/common';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';
import { body, bodySmall, poppinsRegularFont } from '@/styles/typography';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const MenuWrapper = styled.div`
  padding: 28px 20px;

  ${media.mobile`
    padding: 28px 16px;
  `}
`;

export const MenuBody = styled.nav``;

export const MenuList = styled.ul`
  ${resetList};
  margin-bottom: 16px;
  color: ${COLORS.grey[300]};
`;

export const MenuSection = styled.li<{ isActive: boolean }>`
  ${flex({ flexDirection: 'row', alignItems: 'center' })};
  height: 40px;
  column-gap: 12px;
  cursor: pointer;
  padding-left: 10px;
  background-color: ${({ isActive }) => (isActive ? COLORS.grey[900] : 'transparent')};
  border-radius: 8px;
  ${poppinsRegularFont};
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${COLORS.grey[950]};
  }
`;

export const MenuSectionIcon = styled.div`
  ${flex({ justifyContent: 'center' })};
`;

export const MenuSectionTitle = styled.div`
  ${bodySmall};
  color: ${COLORS.grey[300]};
  width: 100%;

  ${media.tablet`
    ${body};
  `}
`;

export const MenuSectionLink = styled(NavLink)`
  width: 100%;
  height: 100%;
  ${flex({ alignItems: 'center' })};
  column-gap: 12px;
  text-decoration: none;
`;
