import { BillingPlanResponseType } from '@/types/paymentTypes';
import { BILLING_PLAN_STATUS } from '@/const/billing';
import { formatLimits } from '@/bundle/_Opco/_BillingPage/_BillingPlansPage/helpers';
import { BILLING_FREQUENCY } from '@/bundle/pages/SelectBillingPlanPage/ui/PaymentPlanCard/const';
import { convertCentsToDollars } from '@/helpers/formatHelpers';

export type FormattedPlanType = {
  name: string;
  amount: string;
  usersLimit: string;
  wiresLimit: string;
  addonName: string;
  addonAmount: string;
  frequency: string;
  addonId: string;
  planId: string;
  totalAmount: string;
  overagePrice: string | null;
};

const getActivePlans = (billingPlans: BillingPlanResponseType[]) => {
  return billingPlans?.filter((billingPlan) => billingPlan?.status === BILLING_PLAN_STATUS.ACTIVE);
};

const getFormattedPlans = (filteredPlans: BillingPlanResponseType[]): FormattedPlanType[] => {
  return filteredPlans?.map((filteredPlan: BillingPlanResponseType) => {
    return {
      name: filteredPlan.name,
      amount: convertCentsToDollars(filteredPlan.payment_service_plan_data.add_ons[0].amount),
      usersLimit: formatLimits(filteredPlan.users_limit),
      wiresLimit: formatLimits(filteredPlan.wires_limit),
      addonName: filteredPlan?.payment_service_plan_data?.add_ons[0].name,
      addonAmount: convertCentsToDollars(filteredPlan?.payment_service_plan_data?.add_ons[0].amount),
      frequency:
        filteredPlan?.payment_service_plan_data.billing_frequency === 'annual'
          ? BILLING_FREQUENCY.YEARLY
          : BILLING_FREQUENCY.MONTHLY,
      addonId: filteredPlan?.payment_service_plan_data?.add_ons[0].id,
      planId: filteredPlan?.payment_service_id,
      totalAmount: filteredPlan?.payment_service_plan_data?.add_ons[0].amount
        ? convertCentsToDollars(
            filteredPlan?.payment_service_plan_data?.add_ons[0].amount + filteredPlan?.payment_service_plan_data?.amount
          )
        : convertCentsToDollars(filteredPlan?.payment_service_plan_data?.amount),
      overagePrice: filteredPlan?.overage_price ? convertCentsToDollars(filteredPlan?.overage_price) : null,
    };
  });
};

export const usePrepareBillingPlans = (billingPlans: BillingPlanResponseType[]) => {
  if (!billingPlans) return;

  const activePlans = getActivePlans(billingPlans);

  return getFormattedPlans(activePlans);
};
