import { useLocation } from 'react-router-dom';
import { useSidebar } from '../../hooks/useSidebar';
import {
  MenuBody,
  MenuList,
  MenuSection,
  MenuSectionIcon,
  MenuSectionLink,
  MenuSectionTitle,
  MenuWrapper,
} from './styles';
import { isActiveRoute } from '../../helpers/helpers';
import { SubMenuItem } from './ui/SubMenuItem';

type SidebarMenuType = {
  onNavigate: () => void;
};

export const SidebarMenu = ({ onNavigate }: SidebarMenuType) => {
  const sidebar = useSidebar();
  const { pathname } = useLocation();

  return (
    <MenuWrapper>
      <MenuBody>
        <MenuList>
          {sidebar.menu.map((menuItem) => {
            const { title, url, icon, paths, subMenu } = menuItem;
            const isActiveMenuLink = isActiveRoute(pathname, paths);
            const hasSubMenu = !!subMenu?.length;
            const Icon = icon;

            if (hasSubMenu) {
              return <SubMenuItem key={title} menuItem={menuItem} onNavigate={onNavigate} />;
            }

            return (
              <MenuSection key={title} isActive={isActiveMenuLink}>
                <MenuSectionLink to={url} onClick={onNavigate}>
                  <MenuSectionIcon>
                    <Icon />
                  </MenuSectionIcon>
                  <MenuSectionTitle>{title}</MenuSectionTitle>
                </MenuSectionLink>
              </MenuSection>
            );
          })}
        </MenuList>
      </MenuBody>
    </MenuWrapper>
  );
};
