import { WIRE_STATUS_DB } from '@/const/wire';
import { WireStatusType } from '@/types/wireTypes';
import {
  REQUIRED_EMAIL_VALIDATOR,
  REQUIRED_PHONE_NUMBER_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';
import * as Yup from 'yup';

const NOT_ALLOWED_WIRE_STATUSES_CANCEL_COMMON = [
  WIRE_STATUS_DB.CANCELLED,
  WIRE_STATUS_DB.PENDING,
  WIRE_STATUS_DB.RETRY,
  WIRE_STATUS_DB.FAILED,
];

export const NOT_ALLOWED_WIRE_STATUSES_CANCEL_OUTBOUND: Partial<WireStatusType>[] = [
  ...NOT_ALLOWED_WIRE_STATUSES_CANCEL_COMMON,
  WIRE_STATUS_DB.EXECUTED,
];

export const NOT_ALLOWED_WIRE_STATUSES_CANCEL_INBOUND: Partial<WireStatusType>[] = [
  ...NOT_ALLOWED_WIRE_STATUSES_CANCEL_COMMON,
  WIRE_STATUS_DB.DEPOSIT_SENT,
];

export const NOT_ALLOWED_WIRE_STATUSES_EXECUTE: Partial<WireStatusType>[] = [
  ...NOT_ALLOWED_WIRE_STATUSES_CANCEL_COMMON,
  WIRE_STATUS_DB.RECIPIENT_ASSIGNED,
  WIRE_STATUS_DB.RECIPIENT_INVITED,
  WIRE_STATUS_DB.EXECUTOR_INVITED,
  WIRE_STATUS_DB.EXECUTOR_ASSIGNED,
  WIRE_STATUS_DB.EXECUTOR_INVITED,
  WIRE_STATUS_DB.CREATED,
  WIRE_STATUS_DB.EXECUTED,
];

export type AssignUserFormType = {
  email: string;
  mobile_phone: string;
  first_name: string;
  middle_name: string;
  last_name: string;
};

export const ASSIGN_USER_INITIAL_VALUES = {
  email: '',
  mobile_phone: '',
  first_name: '',
  middle_name: '',
  last_name: '',
};

export const ASSIGN_USER_SCHEMA = Yup.object().shape({
  email: REQUIRED_EMAIL_VALIDATOR,
  mobile_phone: REQUIRED_PHONE_NUMBER_VALIDATOR,
  first_name: REQUIRED_TEXT_FIELD_VALIDATOR,
  middle_name: TEXT_FIELD_VALIDATOR,
  last_name: REQUIRED_TEXT_FIELD_VALIDATOR,
});
