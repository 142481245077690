import { COLORS } from '@/styles/colors';
import { THEME } from '@/styles/theme';
import { bodySmall, headline4, poppinsRegularFont, poppinsSemiBoldFont } from '@/styles/typography';
import styled from 'styled-components';
import { customVerticalScroll, overlayBackground } from '@/styles/common';
import { flexCenter } from '@/styles/layout';
import { media } from '@/styles/responsive';

export const DrawerOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  ${overlayBackground};
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s ease,
    visibility 0.2s ease;
  z-index: ${THEME.drawer.overlayZIndex};

  ${({ isOpen }) =>
    isOpen &&
    `
      opacity: 1;
      visibility: visible;
    `};
`;

export const DrawerWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background-color: ${COLORS.white};
  padding: 20px 0;
  height: 100%;
  width: 550px;
  border-radius: 20px 0 0 20px;
  box-shadow:
    0 20px 20px 0 rgb(0 0 0 / 8%),
    0 0 2px 0 rgb(0 0 0 / 12%);
  z-index: ${THEME.drawer.zIndex};

  ${media.mobile`
    width: 100%;
    border-radius: 0;
  `}

  &.fade-enter {
    opacity: 0;
    transform: translateX(100%);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition:
      opacity 0.2s ease-in-out,
      transform 0.25s cubic-bezier(0, 0, 0.2, 1);
  }

  &.fade-exit {
    opacity: 0;
    transform: translateX(100%);
    transition:
      opacity 0.3s ease-in-out,
      transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
`;

export const DrawerHeader = styled.h4`
  margin: 0;
  ${poppinsSemiBoldFont};
  ${headline4};
`;

export const DrawerSubheader = styled.div`
  ${poppinsRegularFont};
  ${bodySmall};
  margin: 4px 0 24px;
  color: ${COLORS.grey[600]};
`;

export const DrawerContent = styled.div`
  padding: 16px 36px;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  ${customVerticalScroll};
`;

export const CloseButton = styled.button`
  ${flexCenter};
  width: 40px;
  height: 40px;
  background: transparent;
  transition: stroke 0.2s ease;
  border-radius: 8px;
  border: 1px solid ${COLORS.grey[400]};
  cursor: pointer;

  &:hover {
    path {
      stroke: ${COLORS.green[500]};
    }
  }
`;
