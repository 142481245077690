import { parseISO } from 'date-fns';
import { WireType } from '@/types/wireTypes';
import { DEFAULT_DATE_FORMAT, formatPayloadDate } from '@/helpers/dateHelpers/dateHelpers';
import { OptionType } from '@/components/form/fields/Select/Select';
import { getCurrencyOption } from '@/helpers/formatHelpers';
import { WIRE_TYPE_SINGULAR_LABEL } from '@/const/wire';

export const formatInboundWire = (inboundWire = {} as WireType, currencyOptions: OptionType[] = []) => {
  const { name, ref_id, type, date_created, expected_payment_date, amount, notes, currency } = inboundWire;
  const currencyOption = getCurrencyOption(currency, currencyOptions);

  return {
    name,
    ref_id,
    type: WIRE_TYPE_SINGULAR_LABEL[type],
    date_created: formatPayloadDate(date_created, DEFAULT_DATE_FORMAT),
    expected_payment_date: expected_payment_date ? parseISO(expected_payment_date) : '',
    amount: amount ?? '',
    currency: currencyOption,
    notes,
  };
};
