import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { getChangedFormValues } from '@/helpers/formHelpers/formHelpers';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { ButtonsWrapper } from './styles';
import { Button } from '@/components/Button/Button';
import { OrganizationModifyType } from '../../types/types';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import {
  CREATE_ORGANIZATION_INITIAL_VALUE,
  CREATE_ORGANIZATION_VALIDATION_SCHEMA,
  EDIT_ORGANIZATION_VALIDATION_SCHEMA,
  FormValuesType,
  getEditOrganizationInitialValues,
  pricingTierOptionsFormatter,
  STATUS_OPTIONS,
} from './const';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { Notification } from '@/components/Notification/Notification';
import { createOrganization, loadOrganization, updateOrganization } from '../../api';
import { PricingTierType } from '@/api/v1/opco/organizations/getOrganizationPricingTier';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { FormikSelect } from '@/components/form/fields/FormikSelect/FormikSelect';
import { FormikSwitchToggle } from '@/components/form/fields/FormikSwitchToggle/FormikSwitchToggle';

type ModifyOrganizationFormType = {
  organizationId?: string;
  isEditMode: boolean;
  onSuccess: () => void;
  onCancel: () => void;
  pricingTierList: PricingTierType[];
};

export const ModifyOrganizationForm = ({
  organizationId,
  onSuccess,
  onCancel,
  isEditMode,
  pricingTierList,
}: ModifyOrganizationFormType) => {
  const { data: organizationData, isPending: isPendingOrganization } = useQuery({
    queryKey: ['load_organization', organizationId],
    queryFn: () => loadOrganization(organizationId),
    enabled: isEditMode,
  });

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['modify_organization'],
    mutationFn: (payload: OrganizationModifyType) => {
      return isEditMode ? updateOrganization(organizationId, payload) : createOrganization(payload);
    },
    onSuccess(response) {
      if (response.error) return;

      const currentEvent = isEditMode
        ? AMPLITUDE_EVENTS.EditOrganizationSuccess
        : AMPLITUDE_EVENTS.CreateOrganizationSuccess;

      amplitudeService.logEvent(currentEvent);

      onSuccess();
    },
  });

  const pricingTierOptions = pricingTierOptionsFormatter(pricingTierList);
  const validationSchema = isEditMode ? EDIT_ORGANIZATION_VALIDATION_SCHEMA : CREATE_ORGANIZATION_VALIDATION_SCHEMA;

  const initialValues = isEditMode
    ? getEditOrganizationInitialValues(pricingTierOptions, organizationData?.body)
    : CREATE_ORGANIZATION_INITIAL_VALUE;

  const formik = useFormik<FormValuesType>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      if (!isEditMode) {
        const { name, ein, pricing_tier, is_kyc_allowed } = values;

        const payload = {
          name,
          ein,
          pricing_tier: pricing_tier?.value,
          is_kyc_allowed,
        };

        return mutate(payload);
      }

      const payload = getChangedFormValues<FormValuesType>(values, initialValues);

      if (!payload) {
        onCancel();

        return;
      }

      const formattedPayload = {
        ...payload,
        ...(payload.status && { status: values.status.value }),
        ...(payload.pricing_tier && { pricing_tier: values.pricing_tier.value }),
      };

      await mutate(formattedPayload);
    },
  });

  const isLoading = isPending || (isEditMode && isPendingOrganization);
  const apiError = data?.error;
  const formError = getResponseError(apiError);

  return (
    <>
      {formError && (
        <Notification variant='error' mb='24px'>
          {formError}
        </Notification>
      )}
      <FormikForm value={formik}>
        <FormikInput name='name' label='Name*' placeholder='Enter Name' apiError={apiError} />
        <FormikInput name='ein' label='Business Tax ID*' placeholder='E.g. EIN or VAT' apiError={apiError} />
        <FormikSelect
          name='pricing_tier'
          label='Pricing Tier*'
          placeholder='Select Pricing Tier'
          options={pricingTierOptions}
          apiError={apiError}
        />
        {isEditMode && (
          <FormikSelect name='status' label='Select Status' options={STATUS_OPTIONS} apiError={apiError} />
        )}

        <FormikSwitchToggle name='is_kyc_allowed' label='Enable ID Verification' apiError={apiError} />

        <ButtonsWrapper>
          <Button type='button' variant='secondary' onClick={onCancel}>
            Cancel
          </Button>
          <Button type='submit' isLoading={isLoading}>
            Save
          </Button>
        </ButtonsWrapper>
      </FormikForm>
    </>
  );
};
