import { COLORS } from '@/styles/colors';
import { flexCenter } from '@/styles/layout';
import styled from 'styled-components';
import { INPUT_SIZE_PADDING } from '../Input/styles';

export const PriceIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  margin-left: ${-INPUT_SIZE_PADDING.medium}px;
  ${flexCenter};
  background: ${COLORS.grey[200]};
`;
