import {
  AMOUNT_VALIDATOR,
  REQUIRED_OBJECT_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  STRING_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';
import * as Yup from 'yup';

export const EDIT_INBOUND_WIRE_VALIDATION_SCHEMA = Yup.object().shape({
  name: REQUIRED_TEXT_FIELD_VALIDATOR,
  expected_payment_date: STRING_VALIDATOR,
  amount: AMOUNT_VALIDATOR,
  currency: REQUIRED_OBJECT_VALIDATOR,
  notes: TEXT_FIELD_VALIDATOR,
});
