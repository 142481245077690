import { useNavigate } from 'react-router-dom';
import { formatAmount } from '@/helpers/formatHelpers';
import { Card, CardContent } from '@/components/Card/Card';
import { WireStatus } from '../../shared/components/WireStatus/WireStatus';
import { getExecutorWireDetailsUrl } from '../_Details/urls/getRecipientWireDetailsUrl';
import { WireType } from '@/types/wireTypes';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';

type ExecutorWiresListType = {
  list: WireType[];
};

export const ExecutorWiresList = ({ list }: ExecutorWiresListType) => {
  const navigate = useNavigate();

  const navigateToDetails = (wire) => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.ExecutorViewsDetails, { status: wire.status });

    navigate(getExecutorWireDetailsUrl(wire.id));
  };

  return (
    <>
      {list.map((wire) => {
        const transactionName = wire?.contract?.name;
        const { name, status, date_created, expected_payment_date, amount, id } = wire;
        const formattedCreationDate = formatDate(date_created);
        const formattedExpectedPaymentDate = formatDate(expected_payment_date);

        return (
          <Card
            key={id}
            title={name}
            header={name}
            meta={<WireStatus status={status} />}
            onClick={() => navigateToDetails(wire)}
          >
            <CardContent name='Creation Date'>{formattedCreationDate}</CardContent>
            <CardContent name='Expected Payment Date' ratio={2}>
              {formattedExpectedPaymentDate}
            </CardContent>
            <CardContent name='Amount'>{formatAmount(amount)}</CardContent>
            <CardContent name='Transaction Name' ratio={2}>
              {transactionName}
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};
