import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { Button } from '@/components/Button/Button';
import { Drawer } from '@/components/Drawer/Drawer';
import { ReactComponent as PlusIcon } from '@/images/PlusIcon.svg';
import { useState } from 'react';
import { BillingPlansTable } from './ui/BillingPlansTable/BillingPlansTable';
import { CreateBillingPlanForm } from './ui/CreateBillingPlanForm/CreateBillingPlanForm';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useGetSearchParams } from '@/hooks/useGetSearchParams';
import { loadBillingPlans } from './api';

export const BillingPlansPage = () => {
  const queryClient = useQueryClient();
  const searchParams = useGetSearchParams();
  const [isOpenCreatePlan, setIsOpenCreatePlan] = useState(false);

  const { data, isPending } = useQuery({
    queryKey: ['load_billing_plans', searchParams],
    queryFn: () => loadBillingPlans(searchParams),
  });

  const openCreatePlan = () => {
    setIsOpenCreatePlan(true);
  };

  const refetchBillingPlans = () => {
    queryClient.invalidateQueries({ queryKey: ['load_billing_plans', searchParams] });
  };

  const billingPlans = data?.body?.results;

  return (
    <ContentLayout
      title='Plans'
      headerAction={
        <Button width={160} mobileStretch icon={<PlusIcon />} iconPosition='left' onClick={openCreatePlan}>
          Create Plan
        </Button>
      }
      subtitle='View the plans created in the System'
      offsetSize='small'
    >
      <BillingPlansTable plans={billingPlans} isLoading={isPending} />

      <Drawer
        isOpen={isOpenCreatePlan}
        header='Create Plan'
        subHeader='Create a plan to bill your clients.'
        onClose={() => setIsOpenCreatePlan(false)}
      >
        <CreateBillingPlanForm
          billingPlans={billingPlans}
          onClose={() => setIsOpenCreatePlan(false)}
          onSuccess={refetchBillingPlans}
        />
      </Drawer>
    </ContentLayout>
  );
};
