import { AuthLayout } from '@/bundle/Auth/ui/AuthLayout/AuthLayout';
import { AuthStep } from '@/bundle/Auth/ui/AuthStep/AuthStep';
import { AuthTitle } from '@/bundle/Auth/ui/AuthTitle/AuthTitle';
import { PaymentColumn, CommonLayoutWrapper } from './styles';
import {
  BillingAddressFormType,
  EditBillingAddressForm,
} from '../SettingsPage/ui/EditBillingAddressForm/EditBillingAddressForm';
import { useEffect } from 'react';
import { PaymentCard } from '../SettingsPage/ui/PaymentCard/PaymentCard';
import { Typography } from '@/components/Typography/Typography';
import { BillingAddressCard } from '../SettingsPage/ui/BillingAddressCard/BillingAddressCard';
import { ReactComponent as SecureLockIcon } from './images/SecureLockIcon.svg';
import { COLORS } from '@/styles/colors';
import {
  getPlanPrice,
  BillingPlanSummary,
} from '@/bundle/pages/BillingCheckoutPage/ui/BillingPlanSummary/BillingPlanSummary';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  addPaymentMethod,
  createCustomerVault,
  createSubscription,
  getBillingPlanById,
  loadCustomerVault,
  setDefaultPaymentMethod,
} from '../SettingsPage/api';
import {
  CreateSubscriptionPayloadType,
  DefaultBillingAddressType,
  DefaultPaymentMethodType,
} from '@/types/paymentTypes';
import { PAYMENT_METHOD_TYPE } from '@/const/shared';
import capitalize from 'lodash/capitalize';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getBillingSuccessUrl } from '@/bundle/pages/BillingSuccessPage/urls/getBillingSuccessUrl';
import { PaymentCardForm } from '../SettingsPage/ui/PaymentCardForm/PaymentCardForm';
import { getSelectBillingPlanUrl } from '@/bundle/pages/SelectBillingPlanPage/urls/getSelectBillingPlanUrl';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { convertCentsToDollars } from '@/helpers/formatHelpers';
import { getPlanName } from '@/helpers/billingHelpers';
import { formatDateTimeUTC } from '@/helpers/dateHelpers/dateHelpers';

export const BillingCheckoutPage = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const planId = searchParams.get('plan_id');
  const addonId = searchParams.get('addon_id');

  const { data: customerVaultData, isPending: isPendingCustomerVault } = useQuery({
    queryKey: ['load_customer_vault'],
    queryFn: () => loadCustomerVault(),
  });

  const customerVault = customerVaultData?.body?.data?.customer;

  const { data: selectedPlanData, isPending: isPendingSelectedPlan } = useQuery({
    queryKey: ['get_selected_plan', planId],
    queryFn: () => getBillingPlanById(planId),
    enabled: !!planId,
  });

  const {
    mutate: createCustomerVaultMutate,
    data: createCustomerVaultData,
    isPending: isPendingCreateCustomerVault,
  } = useMutation({
    mutationKey: ['create_customer_vault'],
    mutationFn: (billingAddress: DefaultBillingAddressType) => {
      return createCustomerVault(billingAddress);
    },
    onSuccess(createCustomerVaultResponse) {
      if (createCustomerVaultResponse?.error) return;

      queryClient.invalidateQueries({ queryKey: ['load_customer_vault'] });
    },
  });

  const { mutate: setDefaultPaymentMethodMutate } = useMutation({
    mutationKey: ['set_default_payment_method'],
    mutationFn: (paymentMethodId: string) => {
      const payload: DefaultPaymentMethodType = {
        payment_method_type: PAYMENT_METHOD_TYPE,
        payment_method_id: paymentMethodId,
      };

      return setDefaultPaymentMethod(payload);
    },
    onSuccess(setDefaultPaymentMethodResponse) {
      if (setDefaultPaymentMethodResponse?.error) {
        return;
      }

      queryClient.invalidateQueries({ queryKey: ['load_customer_vault'] });
    },
  });

  const { mutate: createSubscriptionMutate, isPending: isPendingCreateSubscription } = useMutation({
    mutationKey: ['create_subscription'],
    mutationFn: () => {
      const payload: CreateSubscriptionPayloadType = {
        plan_id: planId,
        charge_on_day: true,
        add_ons: addonId ? [{ id: addonId }] : [],
      };

      return createSubscription(payload);
    },
    onSuccess(createSubscriptionResponse) {
      if (createSubscriptionResponse?.error) {
        return;
      }

      const subscription = createSubscriptionResponse?.body;

      const { totalPrice } = getPlanPrice(
        !!addonId,
        subscription?.billing_frequency,
        subscription?.amount,
        subscription?.total_adds
      );

      navigate(getBillingSuccessUrl(), {
        state: {
          transactionId: subscription?.id,
          billingDate: formatDateTimeUTC(subscription?.created_at),
          billingFrequency: subscription?.billing_frequency,
          customerId: customerVaultData?.body?.owner_id,
          cardType: customerVault?.payments?.cards[0]?.card_type,
          cardNumber: customerVault?.payments?.cards[0]?.masked_number,
          totalPrice: convertCentsToDollars(totalPrice),
        },
      });
    },
  });

  const { mutate: addPaymentMethodMutate, isPending: isPendingAddPaymentMethod } = useMutation({
    mutationKey: ['add_payment_method'],
    mutationFn: (token: string) => {
      return addPaymentMethod(token);
    },
    onSuccess(addPaymentMethodResponse) {
      if (addPaymentMethodResponse?.error) {
        return;
      }

      queryClient.invalidateQueries({ queryKey: ['load_customer_vault'] });
    },
  });

  const savePaymentCard = (token: string) => {
    addPaymentMethodMutate(token);
  };

  const saveBillingAddress = (values: BillingAddressFormType) => {
    const { country, city, state, zip, address } = values;

    const payload: DefaultBillingAddressType = {
      city,
      country: country.value as string,
      state: state.value as string,
      postal_code: zip,
      line_1: address,
    };

    createCustomerVaultMutate(payload);
  };

  const defaultBillingAddressId = customerVault?.defaults?.billing_address_id;
  const billingAddress = customerVault?.addresses.find((item) => item.id === defaultBillingAddressId);

  const defaultPaymentCardId = customerVault?.defaults.payment_method_id;
  const paymentCard = customerVault?.payments.cards.find((item) => item.id === defaultPaymentCardId);
  const hasPaymentDetails = defaultPaymentCardId && defaultBillingAddressId;

  //TODO: change when BE set default
  useEffect(() => {
    if (defaultPaymentCardId) {
      return;
    }

    const card = customerVault?.payments.cards[0];

    if (card) {
      setDefaultPaymentMethodMutate(card.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerVault, defaultPaymentCardId]);

  const getFormattedPlan = () => {
    const planData = selectedPlanData?.body?.results[0];
    const planName = getPlanName(planData?.name);

    if (!planName) return;

    const { amount, billing_frequency, add_ons } = planData.payment_service_plan_data;

    return {
      planPrice: amount,
      billingFrequency: billing_frequency,
      addonPrice: add_ons[0]?.amount,
      planName,
    };
  };

  const selectedPlan = getFormattedPlan();

  useEffect(() => {
    if (!planId) {
      navigate(getSelectBillingPlanUrl());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId]);

  useEffect(() => {
    const error = getResponseError(selectedPlanData?.error);

    if (error || selectedPlanData?.body?.results.length === 0) {
      navigate(getSelectBillingPlanUrl());
      queryClient.removeQueries({ queryKey: ['get_selected_plan'] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlanData]);

  return (
    <AuthLayout position='top'>
      <AuthStep width='1036px'>
        <AuthTitle>Add billing info for {capitalize(selectedPlan?.planName)}</AuthTitle>
        {!isPendingCustomerVault && !isPendingSelectedPlan && (
          <Box display='flex' columnGap='42px'>
            <PaymentColumn>
              {!billingAddress && (
                <CommonLayoutWrapper>
                  <Typography variant='subTitleSmall' fontWeight='semibold'>
                    Billing address
                  </Typography>
                  <EditBillingAddressForm
                    isLoading={isPendingCreateCustomerVault}
                    onSave={saveBillingAddress}
                    apiError={createCustomerVaultData?.error}
                  />
                </CommonLayoutWrapper>
              )}

              {billingAddress && <BillingAddressCard billingAddress={billingAddress} readonly />}

              <CommonLayoutWrapper>
                <Typography variant='subTitleSmall' fontWeight='semibold'>
                  Credit card info
                </Typography>
                {billingAddress && (
                  <>
                    <Typography display='flex' columnGap={8} color={COLORS.grey[650]} variant='bodySmall' mb='16px'>
                      <SecureLockIcon /> Secured by Pepper Pay
                    </Typography>
                    {paymentCard ? (
                      <PaymentCard card={paymentCard} readonly />
                    ) : (
                      <PaymentCardForm onSave={savePaymentCard} isLoading={isPendingAddPaymentMethod} />
                    )}
                  </>
                )}
              </CommonLayoutWrapper>
            </PaymentColumn>

            <Box display='flex' flexDirection='column' rowGap='20px'>
              <BillingPlanSummary selectedPlan={selectedPlan} hasAddon={!!addonId} />
              <Button
                disabled={!hasPaymentDetails}
                onClick={createSubscriptionMutate}
                isLoading={isPendingCreateSubscription}
              >
                Create subscription
              </Button>
            </Box>
          </Box>
        )}{' '}
      </AuthStep>
    </AuthLayout>
  );
};
