import { ValueOf } from './../../types/sharedTypes';
export type StatusType = {
  [key: string]: {
    variant: StatusVariantType;
    title?: string;
    statusIcon?: JSX.Element;
  };
};

export const STATUS_VARIANT_HIGHLIGHT = {
  BLUE: 'blue',
  PURPLE: 'purple',
  LIGHT_PURPLE: 'light_purple',
  YELLOW: 'yellow',
  RED: 'red',
  GREEN: 'green',
  ROSE: 'rose',
  DARK_BLUE: 'dark_blue',
  LIGHT_BLUE: 'light_blue',
  DARK_ROSE: 'dark_rose',
  GREY: 'grey',
} as const;

export type StatusVariantType = ValueOf<typeof STATUS_VARIANT_HIGHLIGHT>;
