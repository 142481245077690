import capitalize from 'lodash/capitalize';
import { ReactComponent as EnabledVerificationIcon } from '@/images/CheckSmallIcon.svg';
import { ReactComponent as DisabledVerificationIcon } from '@/images/MinusSmallIcon.svg';
import { TableConfigItemsListType } from '@/components/Table/types/types';
import { BilledEventsListType } from '../helpers/helpers';
import { formatDateTimeUTC } from '@/helpers/dateHelpers/dateHelpers';

export const BILLED_EVENTS_TABLE_CONFIG: TableConfigItemsListType<BilledEventsListType> = [
  {
    key: 1,
    name: 'organization__name,-date_created',
    header: 'ORGANIZATION NAME',
    width: '15%',
    hasSort: true,
    getValue(item) {
      return item.org_name;
    },
  },
  {
    key: 2,
    name: 'date_created',
    header: 'DATE OF VIEW',
    width: '15%',
    hasSort: true,
    hasTooltip: true,
    getValue({ date_viewed }) {
      return formatDateTimeUTC(date_viewed);
    },
  },
  {
    key: 3,
    name: 'wire__name,-date_created',
    header: 'WIRE NAME',
    width: '10%',
    hasSort: true,
    getValue({ wire_name }) {
      return wire_name;
    },
  },
  {
    key: 4,
    name: 'wire_ref_id',
    header: 'WIRE ID',
    width: '10%',
    getValue({ wire_ref_id }) {
      return wire_ref_id;
    },
  },
  {
    key: 5,
    name: 'id_verification,-date_created',
    header: 'ID VERIFICATION',
    width: '10%',
    hasSort: true,
    getValue({ id_verification }) {
      return id_verification ? <EnabledVerificationIcon /> : <DisabledVerificationIcon />;
    },
  },
  {
    key: 6,
    name: 'wire__payment_details_collection_type,-date_created',
    header: 'COLLECTION TYPE',
    width: '10%',
    hasSort: true,
    getValue({ payment_details_collection_type }) {
      const paymentDetailsCollectionType = capitalize(payment_details_collection_type);

      return paymentDetailsCollectionType;
    },
  },
  {
    key: 7,
    name: 'user__email,-date_created',
    header: 'USER VIEWED',
    width: '15%',
    hasSort: true,
    getValue({ user_viewed }) {
      return user_viewed;
    },
  },
  {
    key: 8,
    name: 'wire__contract__name,-date_created',
    header: 'TRANSACTION NAME',
    width: '15%',
    hasSort: true,
    getValue({ transaction_name }) {
      return transaction_name;
    },
  },
];
