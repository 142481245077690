import { OptionType } from '@/components/form/fields/Select/Select';
import { REQUIRED_OBJECT_VALIDATOR, REQUIRED_TEXT_FIELD_VALIDATOR } from '@/helpers/formHelpers/validators';
import countryRegionData from 'country-region-data/dist/data-umd';
import * as Yup from 'yup';

type RegionType = {
  name: string;
  shortCode: string;
};

type CountryType = {
  countryName: string;
  countryShortCode: string;
  regions: RegionType[];
};

const countriesData = countryRegionData as CountryType[];

export const countriesOptions = countriesData.map((item) => ({
  value: item.countryShortCode,
  label: item.countryName,
}));

export const getStatesOptions = (regions: RegionType[]) => {
  return regions.map((item) => ({
    value: item.shortCode,
    label: item.name,
  }));
};

export const getCountryByCode = (code: string) => {
  return countriesData.find((item) => item.countryShortCode === code);
};

export type BillingAddressFormType = {
  country: OptionType | null;
  address: string;
  state: OptionType | null;
  city: string;
  zip: string;
};

export const BILLING_ADDRESS_INITIAL_VALUE: BillingAddressFormType = {
  country: null,
  address: '',
  state: null,
  city: '',
  zip: '',
};

export const BILLING_ADDRESS_SCHEMA = Yup.object().shape({
  country: REQUIRED_OBJECT_VALIDATOR,
  address: REQUIRED_TEXT_FIELD_VALIDATOR,
  city: REQUIRED_TEXT_FIELD_VALIDATOR,
  zip: REQUIRED_TEXT_FIELD_VALIDATOR,
});
