import { BILLING_PLAN_TYPE } from '@/bundle/pages/SelectBillingPlanPage/ui/PaymentPlanCard/const';

export const getPlanName = (name: string) => {
  if (name?.toLocaleLowerCase().includes(BILLING_PLAN_TYPE.FREE)) return BILLING_PLAN_TYPE.FREE;

  if (name?.toLocaleLowerCase().includes(BILLING_PLAN_TYPE.STARTER)) return BILLING_PLAN_TYPE.STARTER;

  if (name?.toLocaleLowerCase().includes(BILLING_PLAN_TYPE.PRO)) return BILLING_PLAN_TYPE.PRO;

  return null;
};
