import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import {
  BILLING_ADDRESS_INITIAL_VALUE,
  BILLING_ADDRESS_SCHEMA,
  countriesOptions,
  getCountryByCode,
  getStatesOptions,
} from './const';
import { Notification } from '@/components/Notification/Notification';
import { ResponseErrorType } from '@/types/sharedTypes';
import { OptionType } from '@/components/form/fields/Select/Select';
import { DefaultBillingAddressType } from '@/types/paymentTypes';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { FormikSelect } from '@/components/form/fields/FormikSelect/FormikSelect';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';

export type BillingAddressFormType = {
  address: string;
  city: string;
  country?: OptionType;
  state?: OptionType;
  zip: string;
};

type EditBillingAddressFormType = {
  billingAddress?: DefaultBillingAddressType;
  onSave: (billingAddress: BillingAddressFormType) => void;
  onClose?: () => void;
  isLoading: boolean;
  apiError?: ResponseErrorType;
};

export const EditBillingAddressForm = ({
  billingAddress,
  apiError,
  onSave,
  onClose,
  isLoading,
}: EditBillingAddressFormType) => {
  const [isFirstLoaded, setIsFirstLoaded] = useState(false);
  const [regionsOptions, setRegionsOptions] = useState<OptionType[]>([]);

  const formik = useFormik<BillingAddressFormType>({
    initialValues: BILLING_ADDRESS_INITIAL_VALUE,
    validationSchema: BILLING_ADDRESS_SCHEMA,
    onSubmit: onSave,
  });

  useEffect(() => {
    setIsFirstLoaded(true);

    if (!billingAddress) {
      return;
    }

    const country = getCountryByCode(billingAddress.country);
    const state = country?.regions.find((item) => item.shortCode === billingAddress.state);

    formik.setValues({
      city: billingAddress.city,
      zip: billingAddress.postal_code,
      address: billingAddress.line_1,
      state: {
        label: state?.name,
        value: state?.shortCode,
      },
      country: {
        label: country?.countryName,
        value: country?.countryShortCode,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingAddress]);

  useEffect(() => {
    if (isFirstLoaded) {
      formik.resetForm({
        values: {
          ...formik.initialValues,
          country: formik.values.country,
        },
      });
    }

    const countryValue = formik.values.country?.value;
    const country = getCountryByCode(countryValue as string);
    const regions = country?.regions || [];

    setRegionsOptions(getStatesOptions(regions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.country]);

  const error = getResponseError(apiError);

  return (
    <FormikForm value={formik}>
      {error && (
        <Notification mt='20px' variant='error' mb='24px'>
          {error}
        </Notification>
      )}
      <Box mt='20px'>
        <FormikSelect
          name='country'
          label='Country*'
          placeholder='Select Country'
          options={countriesOptions}
          apiError={apiError}
          isSearchable={true}
        />
        <FormikInput name='address' label='Address*' placeholder='Enter Address' apiError={apiError} />
        <FormikSelect
          name='state'
          label='State/Region'
          placeholder='Enter State/Region'
          options={regionsOptions}
          apiError={apiError}
          isSearchable={true}
        />
        <Box display='flex' columnGap='20px'>
          <Box flexGrow={1}>
            <FormikInput name='city' label='City*' placeholder='Enter City' apiError={apiError} />
          </Box>
          <Box>
            <FormikInput name='zip' label='Zip Code*' placeholder='Enter Zip Code' apiError={apiError} />
          </Box>
        </Box>
      </Box>
      <Box mt='20px' display='flex' columnGap='20px'>
        {onClose && (
          <Button variant='secondary' onClick={() => onClose()}>
            Cancel
          </Button>
        )}
        <Button isLoading={isLoading} type='submit' onClick={formik.handleSubmit}>
          Save
        </Button>
      </Box>
    </FormikForm>
  );
};
