import { authApiService } from '@/api/authApiService';
import { ApiParams } from '@/api/helpers/urlApiHelpers';
import { BillingPlanStatusType } from '@/const/billing';
import { ApiResponseType, ListResponseType } from '@/types/sharedTypes';

export type BillingPlanType = {
  id: string;
  payment_service_id: string;
  name: string;
  description: string;
  users_limit: number;
  wires_limit: number;
  overage_price: number;
  status: BillingPlanStatusType;
  created_by: string;
  date_created: string;
  date_modified: string;
  last_modified_by: string;
  payment_service_plan_data: BillingServicePlanData;
};

export type BillingServicePlanDataAddonOrDiscount = {
  id: string;
  name: string;
  description?: string;
  amount: number | null;
  percentage: number | null;
  duration: number;
  date_created: string | null;
  date_modified?: string | null;
};

export type BillingServicePlanData = {
  id: string;
  name: string;
  description: string;
  amount: number;
  total_add_ons_amount: number;
  total_discounts_amount: number;
  currency: 'usd' | 'eur' | 'gbp';
  billing_cycle_interval: number;
  billing_frequency: 'annual' | 'monthly';
  billing_days: string;
  duration: number;
  add_ons: BillingServicePlanDataAddonOrDiscount[] | null;
  discounts: BillingServicePlanDataAddonOrDiscount[] | null;
  subscription_count: number;
  charge_on_day: boolean;
  date_created: string | null;
  date_modified: string | null;
};

export const getBillingPlansApi = async (
  params: ApiParams
): Promise<ApiResponseType<ListResponseType<BillingPlanType>>> => {
  const uri = '/opco/payments/plans/';

  return authApiService.GET(uri, params);
};
