import { OptionType } from '@/components/form/fields/Select/Select';

export const sortDisabledOptions = (options: OptionType[]) => {
  return options.sort((option) => {
    if (option.isDisabled) {
      return 1;
    } else {
      return -1;
    }
  });
};
