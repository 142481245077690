import { COLORS } from '@/styles/colors';
import { resetButton } from '@/styles/common';
import { body, poppinsMediumFont, poppinsRegularFont } from '@/styles/typography';
import styled from 'styled-components';

export const TermsLink = styled.a`
  color: ${COLORS.blue[500]};
  ${body};
  ${poppinsRegularFont};
  text-decoration: underline;
  cursor: pointer;
`;

export const CancelButton = styled.button`
  ${resetButton};
  ${poppinsMediumFont};
  ${body};
  color: ${COLORS.red[500]};
  cursor: pointer;
`;
