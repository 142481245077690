import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  assignRecipientByProxy,
  createRecipientByProxy,
  loadUserDetailsByProxy,
  recipientAssignmentStatusByProxy,
} from './api';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { AssignRecipientFormHeader } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/styles';
import { FormMessage } from '@/bundle/_OrgAdmin/shared/ui/styles';
import {
  ASSIGN_USER_INITIAL_VALUES,
  ASSIGN_USER_SCHEMA,
  AssignUserFormType,
} from '@/bundle/_OrgAdmin/shared/const/const';
import { useFormik } from 'formik';
import { Maybe } from '@/types/sharedTypes';
import { AutocompleteOptionType } from '@/components/Autocomplete/types';
import { useAssignUser } from '@/bundle/shared/hooks/useAssignUser';
import { ProxyAssignRecipientAutocomplete } from '../ProxyAssignRecipientAutocomplete/ProxyAssignRecipientAutocomplete';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { ChangeButton } from './styles';
import { ReactComponent as RecipientChainProxyIcon } from '@/images/RecipientChainProxyIcon.svg';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import {
  PROXY_ASSIGN_RECIPIENT_ERROR_AMPLITUDE_EVENTS_MAP,
  PROXY_ASSIGN_RECIPIENT_SUCCESS_AMPLITUDE_EVENTS_MAP,
} from './const';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { PaymentDetailsHeaderSlot } from '@/bundle/shared/components/PaymentDetailsHeaderSlot/PaymentDetailsHeaderSlot';
import { FormikPhoneInput } from '@/components/form/fields/FormikPhoneInput/FormikPhoneInput';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { getIsAvailableToAssign } from '@/helpers/userHelpers';

type ProxyAssignRecipientFormType = {
  onCreateRecipient: () => void;
  onAssignRecipient: () => void;
  onChangeProvidePaymentDetailsMode: () => void;
  isDocumentVerificationRequired: boolean;
};

export const ProxyAssignRecipientForm = ({
  onCreateRecipient,
  onAssignRecipient,
  onChangeProvidePaymentDetailsMode,
  isDocumentVerificationRequired,
}: ProxyAssignRecipientFormType) => {
  const { id } = useParams<{ id: string }>();
  const [selectedRecipientOption, setSelectedRecipientOption] = useState<Maybe<AutocompleteOptionType>>(null);
  const recipientId = selectedRecipientOption?.value;

  const { data: assignmentStatusData } = useQuery({
    queryKey: ['load_assignment_status_by_proxy', recipientId],
    queryFn: () => recipientAssignmentStatusByProxy(id, recipientId),
    enabled: !!selectedRecipientOption?.isSelectedOption,
  });

  const { data: loadUserData } = useQuery({
    queryKey: ['load_user_by_proxy', recipientId],
    queryFn: () => loadUserDetailsByProxy(recipientId),
    enabled: !!selectedRecipientOption?.isSelectedOption && !!assignmentStatusData?.body?.is_available_to_assign,
  });

  const {
    mutate: createRecipientMutate,
    isPending: isPendingCreateRecipient,
    data: createRecipientData,
    reset: resetCreateRecipient,
  } = useMutation({
    mutationKey: ['create_recipient_by_proxy'],
    mutationFn: (values: AssignUserFormType) => {
      //TODO: is proxy ???
      return createRecipientByProxy(id, { ...values, is_proxy: false });
    },
    onSuccess: (createdRecipientResponse) => {
      if (createdRecipientResponse?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.ProxyAssignNewRecipient);
      onCreateRecipient();
    },
  });

  const {
    mutate: assignRecipientMutate,
    isPending: isPendingAssignRecipient,
    data: assignRecipientData,
    reset: resetAssignRecipient,
  } = useMutation({
    mutationKey: ['assign_recipient_by_proxy'],
    mutationFn: () => {
      const userData = loadUserData?.body;

      //TODO: is proxy ???
      const payload = {
        user_id: userData?.id,
      };

      return assignRecipientByProxy(id, payload);
    },
    onSuccess: (assignedRecipientResponse) => {
      if (assignedRecipientResponse?.error) return;

      const eventType = PROXY_ASSIGN_RECIPIENT_SUCCESS_AMPLITUDE_EVENTS_MAP[assignmentStatusData?.body?.code];

      if (eventType) {
        amplitudeService.logEvent(eventType);
      }

      onAssignRecipient();
    },
  });

  useEffect(() => {
    const assignmentStatusBody = assignmentStatusData?.body;

    if (assignmentStatusBody && !assignmentStatusBody?.is_available_to_assign) {
      const eventType = PROXY_ASSIGN_RECIPIENT_ERROR_AMPLITUDE_EVENTS_MAP[assignmentStatusData?.body?.code];

      if (eventType) {
        amplitudeService.logEvent(eventType);
      }
    }
  }, [assignmentStatusData?.body]);

  const isAvailableToAssign = getIsAvailableToAssign(assignmentStatusData?.body?.is_available_to_assign);

  const user = loadUserData?.body;
  const isDisabledForm = !!user || (selectedRecipientOption?.isSelectedOption && !isAvailableToAssign);

  const formik = useFormik<AssignUserFormType>({
    initialValues: ASSIGN_USER_INITIAL_VALUES,
    validationSchema: isDisabledForm ? null : ASSIGN_USER_SCHEMA,
    onSubmit: (values) => {
      if (selectedRecipientOption?.isSelectedOption) {
        assignRecipientMutate();

        return;
      }

      createRecipientMutate(values);
    },
  });

  const onResetData = () => {
    resetCreateRecipient();
    resetAssignRecipient();
  };

  useAssignUser(
    loadUserData?.body,
    formik,
    onResetData,
    selectedRecipientOption,
    assignmentStatusData?.body?.is_available_to_assign
  );

  const assignmentStatus = assignmentStatusData?.body?.code;
  const createRecipientError = createRecipientData?.error;
  const assignRecipientError = assignRecipientData?.error;
  const apiError = createRecipientError || assignRecipientError;
  const isLoading = isPendingCreateRecipient || isPendingAssignRecipient;

  return (
    <DetailsWidget
      hasHighlight
      headlineSlot={
        <PaymentDetailsHeaderSlot
          icon={<RecipientChainProxyIcon />}
          header='Invite the Recipient'
          meta={<ChangeButton onClick={onChangeProvidePaymentDetailsMode}>Change</ChangeButton>}
        />
      }
    >
      <AssignRecipientFormHeader>Assign Recipient</AssignRecipientFormHeader>
      <FormMessage>Please indicate who will be receiving this wire payment.</FormMessage>
      <FormikForm value={formik}>
        <ProxyAssignRecipientAutocomplete
          selectedOption={selectedRecipientOption}
          wireId={id}
          assignmentStatus={assignmentStatus}
          onSelect={setSelectedRecipientOption}
          error={formik.errors?.email}
          apiError={apiError}
          labelSlot={isDocumentVerificationRequired && 'ID VERIFICATION REQUIRED'}
        />

        <FormikPhoneInput
          name='mobile_phone'
          label='Mobile Phone Number*'
          disabled={isDisabledForm}
          apiError={apiError}
        />
        <FormikInput
          name='first_name'
          label='First Name*'
          placeholder='Enter First Name'
          disabled={isDisabledForm}
          apiError={apiError}
        />
        <FormikInput
          name='middle_name'
          label='Middle Name'
          placeholder='Enter Middle Name'
          disabled={isDisabledForm}
          apiError={apiError}
        />
        <FormikInput
          name='last_name'
          label='Last Name*'
          placeholder='Enter Last Name'
          disabled={isDisabledForm}
          apiError={apiError}
        />

        <Box alignItems='center' justifyContent='flex-end' mt='14px'>
          <Button
            width={172}
            size='medium'
            type='submit'
            disabled={!isAvailableToAssign}
            mobileStretch
            isLoading={isLoading}
          >
            Assign
          </Button>
        </Box>
      </FormikForm>
    </DetailsWidget>
  );
};
